.field_wrapper_sell_image_group {
  .image_field_base_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    .image_field_text {
      font-size: 14px;
      text-align: center;
    }
  }
}
