.app_header.flex {
  .wrapper.items {
    .link_button {
      span {
        &:hover {
          @include mq_down('md') {
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }
  }
}

.app_contact.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;

  .wrapper {
    width: 600px;
  }

  .app_titlebar.flex {
    height: auto;
    padding: 56px 0;
  }

  .ui.header {
    margin: 0;
    font-size: 26px;
    line-height: $app-line-height-main;
    color: $app-color-black;
  }

  .contact_form_title {
    display: flex;
    width: 600px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  .word_count {
    margin: {
      left: 10px;
    }
  }

  .content {
    width: 600px;
    height: 118px;
    padding: 11px 16px;
    resize: none;
    border: solid 1px #ccc;
    border-radius: 5px;
  }

  .app_titlebar {
    width: 100%;
    background-color: white;
  }

  form.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-bottom: 64px;
    background-color: white;

    input,
    textarea {
      -webkit-appearance: none;
    }
  }

  .amplify-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 600px;

    @include mq_down('sm') {
      padding: 0 16px;
    }

    .contact_form_title {
      color: $app-color-black;
    }

    .w__field {
      width: 100%;
    }

    .ui.input {
      width: 100%;
      height: 36px;
      margin-bottom: 8px;
    }

    .ui.button.submit_login {
      width: 100%;
      height: 48px;
      margin-top: 30px;
      line-height: $app-line-height-main;
      color: white;
      background-color: #ffc107;

      @include hover_button_opacity;

      &:hover {
        @include mq_down('md') {
          opacity: 1;
        }
      }
    }
  }

  footer.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 600px;
    padding: 20px 0 30px;
    background-color: #fff;

    .gotop {
      width: 600px;
      color: $app-color-black;
      text-align: center;
    }

    a {
      &:hover {
        text-decoration: underline;
        opacity: 0.8;

        @include mq_down('md') {
          text-decoration: none;
          opacity: 1;
        }
      }
    }
  }

  .app_form_label_icon {
    margin: 2px 0 0 6px;
  }

  .field-group {
    width: 100%;
    margin-bottom: 8px;

    .content {
      width: 100%;
    }
  }

  .report_container {
    padding: #{$contact-report}px;
    margin-bottom: #{$body-bottom}px;
    font-size: #{$app-font-size-main}px;
    line-height: $app-line-height-main;
    background-color: $app-color-report;

    .report_item {
      padding-bottom: #{$report-item-bottom}px;
      padding-left: 1em;
      text-indent: -1em;

      a {
        &:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down('md') {
            color: $hover-blue-sm;
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }

    .report_item_bottom {
      padding-left: 1em;
      text-indent: -1em;
    }
  }

  @include mq_down('sm') {
    /**
     * Under iPad
     */
    background-color: #fff;

    .wrapper {
      width: 100%;
    }

    form.flex {
      width: 100%;
    }

    .app_titlebar.flex {
      width: 100%;
      height: auto;
      padding: 32px 0;

      .ui.header {
        font-size: 16px;
      }
    }

    .content {
      height: 118px;
    }

    .amplify-container {
      justify-content: left;
      width: 100%;

      footer.flex {
        width: 100%;
      }

      .w__field {
        display: block;
        width: 100%;

        .ui.input {
          width: 100%;

          input {
            width: 100%;
          }
        }

        textarea {
          width: 100%;
        }
      }

      .ui.button.submit_login {
        width: 100%;
      }
    }

    .report_container {
      margin: 0 16px #{$body-bottom}px;
    }
  }
}
