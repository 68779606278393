.app_sell_button {
  display: block;
  width: 120px;
  height: 120px;
  background-color: #ffc400;
  border-radius: 50%;
  &:hover {
    background-color: #fad65f;
  }
  transition: {
    duration: 0.4s;
  }
  @include mq_down("sm") {
    width: 60px;
    height: 60px;
  }

  .w__btn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .label {
      height: 42px;
      padding-top: 18px;
      font-size: 24px;
      line-height: 1;
      color: white;

      @include mq_down("sm") {
        height: 30px;
        padding-top: 10px;
        font-size: 14px;
      }
    }
  }

  .icon.camera {
    height: 90%;
    font-size: 50px;
    color: white;
    @include mq_down("sm") {
      height: 90%;
      font-size: 24px;
    }
  }
  .icon.camera::before {
    position: absolute;
    top: #{$camera-icon-top}px;
    left: #{$camera-icon-left}px;
    @include mq_down("sm") {
      top: #{$camera-icon-top-sm}px;
      left: #{$camera-icon-left-sm}px;
    }
  }
}
