.a__confirm-email {
  min-height: calc(100vh - 721px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 56px;
  .ui.button.btn_submit {
    width: 320px;
    height: 48px;
    margin-top: 32px;
    color: white;
    background-color: #ffc107;
    @include mq_down('sm') {
      width: 100%;
    }

    &:hover {
      background-color: lighten(#ffc107, 15%);

      @include mq_down('sm') {
        opacity: 1;
      }
    }
  }
  .w__confirm-button {
    display: flex;
    justify-content: center;
  }
}
