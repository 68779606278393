.app_feature_circle.grid {
  position: relative;
  width: 320px;
  height: 300px;

  .wrapper.flex {
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .no {
      width: 100%;
      font-size: 48px;
      line-height: 1;
      text-align: center;
    }

    .decoration.wrapperr {
      width: 100px;

      .ui.image {
        width: 100%;
        height: 6px;
      }
    }

    .subtitle {
      width: 100%;
      font-size: 24px;
      line-height: $app-line-height-main;
      text-align: center;
      padding: {
        top: 20px;
      }
      margin: {
        bottom: 9px;
      }
    }

    .description {
      width: 100%;
      font-size: 15px;
      font-weight: 600;
      line-height: $app-line-height-main;
      text-align: center;
    }

    .linkText {
      font-size: 15px;
      font-weight: 600;
      line-height: $app-line-height-main;
      color: #3477cb;
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .feature_image {
    position: absolute;
    top: -24px;
    z-index: -1;
  }
}
