.b__search-btn {
  position: fixed;
  right: 24px;
  bottom: 2rem;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #2d2d2d !important;

  i {
    position: relative;
    font-size: 18px;
    color: #f9f9f9;
  }

  &.ui.button {
    display: none;

    @media screen and (max-width: 767px) {
      display: inline-block;
    }
  }
}
