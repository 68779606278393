.app__banner {
  @include mq_down("sm") {
    padding: 0 16px;
  }

  .w__header_label {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      display: none;
    }

    .w__header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: {
        top: 56px;
        bottom: 56px;
      }

      @media screen and (max-width: 767px) {
        padding: {
          top: 32px;
          bottom: 32px;
        }
      }

      img {
        width: 52px;
        height: 11px;
        padding-right: 8px;
        margin-bottom: 0;
      }

      h3 {
        padding-right: 8px;
        margin: 0;
        font-family: HiraKakuProN-W6;
        font-size: 26px;
        line-height: $app-line-height-main;

        @include mq_down("sm") {
          font-size: 22px;
        }
      }
    }

    p {
      width: 620px;
      font-family: HiraKakuProN-W3;
      font-size: 16px;
      line-height: $app-line-height-main;
      word-wrap: break-word;
    }
  }

  .w__banner_lists_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: #{$body-bottom}px;
  }

  .w__banner_lists {
    width: 594px;
    padding-top: 80px;

    h4 {
      margin-bottom: 24px;
      font-family: HiraKakuProN-W3;
      font-size: 18px;
    }

    .w__banner_wrapper {
      display: flex;
    }

    .w__square_banner:last-child {
      margin-left: 24px;
    }

    .w__square_banner {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 140px;
        height: 140px;
      }
    }

    .w__rectangle_banner:last-child {
      margin-left: 24px;
    }

    .w__rectangle_banner {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 275px;
        height: 60px;
      }
    }
  }

  .w__return_top {
    padding-top: 64px;
    padding-bottom: 32px;
    text-align: center;

    a:hover {
      text-decoration: underline;
      opacity: 0.7;

      @include mq_down("sm") {
        text-decoration: none;
        opacity: 1;
      }
    }
  }

  .ui.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 48px;
    padding: 0 16px;
    line-height: $app-line-height-main;
    color: #fff;
    background-color: #ffc107;

    img {
      width: 14px;
      height: 14px;
      margin: {
        right: 8px;
        bottom: 0;
      }
    }
  }

  a {
    color: $app-color-black;
  }

  a:hover {
    text-decoration: none;
    opacity: 0.8;
  }

  img {
    margin: {
      bottom: 6px;
    }
  }

  .ui.button:hover {
    @include hover_button_opacity();
  }

  @include mq_down("sm") {
    .w__header_label {
      padding: {
        top: 0;
        bottom: 0;
      }

      span {
        display: block;
      }

      p {
        width: 100%;
      }
    }

    .w__header {
      padding: {
        top: 32px;
        bottom: 32px;
      }

      h3 {
        font-size: 22px;
      }
    }

    .w__banner_lists {
      width: 100%;
      padding-top: 40px;

      h4 {
        text-align: center;
      }

      .w__banner_wrapper {
        flex-direction: column;
      }

      .w__square_banner {
        &:last-child {
          padding-top: 24px;
          margin-left: 0;
        }
      }

      .w__rectangle_banner {
        &:last-child {
          padding-top: 24px;
          margin-left: 0;
        }
      }
    }

    .w__return_top {
      padding-top: 56px;
    }

    .ui.button {
      display: none !important;
    }
  }
}
