.app_titlebar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: #{$app_title_bar_top}px 0 #{$app_title_bar_bottom}px;
  line-height: $app-line-height-main;

  .ui.image {
    width: 52px;
    height: 11px;
    padding-right: 8px;
  }

  .ui.header {
    padding-right: 8px;
    font-size: 32px;

    @include mq_down("sm") {
      font-size: 22px !important;
      line-height: $app-line-height-main;
    }
  }
}
