.app_reset_password {
  @include grid-column-span(1, 16);
  @include grid-row-span(1, 16);

  padding: {
    bottom: 150px;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;

  .app_titlebar {
    width: 60%;
    height: auto;
    padding: 56px 0;
    background-color: white;

    .ui.header {
      font-size: 26px;
    }
  }

  .turn_landing.flex {
    display: flex;
    justify-content: center;
    width: 1200px;
    padding: 32px 0;
    margin-bottom: 8px;

    a {
      color: black;

      &:visited {
        color: black;
      }
    }

    a {
      &:hover {
        text-decoration: underline;
        opacity: 0.8;

        @include mq_down("sm") {
          text-decoration: none;
          opacity: 1;
        }
      }
    }
  }

  form.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 60%;
    margin-bottom: 8px;
    background-color: white;
    padding: {
      bottom: 36px;
    }

    input {
      -webkit-appearance: none;
    }
  }

  .amplify-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    > * {
      width: 100%;
    }

    .login_error_message {
      display: none;
      width: 320px;
      margin-bottom: 10px;
      font-size: 12px;
      color: #d62d2d;

      &.display_error {
        display: block !important;
      }
    }

    .ui.input.inp_code {
      width: 320px;
      height: 48px;
    }

    .field-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      font-size: 13.8px;
      > .ui.input.input-field {
        width: 320px;

        @include mq_down("sm") {
          width: 100%;
        }
      }

      .input-field {
        width: 100%;
        font-size: 14px;
      }

      .redux_form.error {
        width: 320px;
        text-align: left;

        @include mq_down("sm") {
          width: 100%;
        }
      }
    }

    .ui.button.btn_submit {
      width: 320px;
      height: 48px;
      margin-top: 20px;
      color: white;
      background-color: #ffc107;

      &:hover {
        background-color: lighten(#ffc107, 15%);

        @include mq_down("sm") {
          background-color: #ffc107;
        }
      }
    }
  }

  .confirm_message {
    width: 320px;
    margin-bottom: 20px;
    font-size: 13.8px;
  }

  @media screen and (max-width: 767px) {
    /**
       * Under iPad
       */
    background-color: #fff;
    background-image: none !important;

    .wrapper {
      width: 320px;
    }

    .confirm_message {
      margin: {
        right: 30px;
        left: 30px;
      }
    }

    .app_titlebar {
      width: 100%;
      height: auto;
      padding: 32px 0;

      .ui.header {
        font-size: 16px;
      }
    }

    form.flex {
      width: 100%;
      padding: 0 16px;

      .sns_buttons.flex {
        width: 256px;
      }
    }

    .amplify-container {
      display: block;

      .field-group {
        width: 100%;
      }

      .ui.button.btn_submit {
        width: 100%;
      }
    }

    .liner.flex {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding-bottom: 24px;

      .div_liner.flex {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        max-width: 103px;
        stroke: black;
      }

      span {
        display: flex;
        align-items: center;
        width: 50px;
        height: 12px;
        padding: 0 7px;
        font-size: 12px;
      }
    }

    footer.flex {
      width: 320px;

      a {
        width: 256px;

        .ui.button.basic.btn_signup {
          width: 256px;
        }
      }
    }
  }
}
