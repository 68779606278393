.app__modal.not_reviewed_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 420px !important;
  padding: 0 24px !important;
  margin: 0 12px;
  overflow: hidden;

  .w__close {
    left: -8px !important;
    width: 100%;
    height: 14px;
    margin-top: 2px;
  }

  .b__body {
    padding: 18px 0 36px;
    .not_reviewed_modal_body {
      .w__caption {
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: bold;
        color: black;
        text-align: center;
      }
      .w__button {
        button {
          width: 160px;
          height: 40px;
        }

        .cancel {
          margin-right: 10px;
          font-size: 14px;
          color: #ffc107;
          background: #fff;
          border: 2px solid #ffc107;
        }

        .item-page {
          color: $app-search_form_background;
          background-color: $app-yellow-button;
        }

        @include mq_down("sm") {
          flex-direction: column-reverse;

          button {
            width: 100%;
          }

          .cancel {
            margin-top: 10px;
            margin-right: 0;
            opacity: 1;
          }

          .item-page {
            color: $app-search_form_background;
            background-color: $app-yellow-button;
            opacity: 1;
          }
        }
      }
    }
    .w__button {
      padding-top: 0;
    }
  }
}
