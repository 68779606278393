.a__fishes__tradar__card-wrapper {
  .b__tradar-card__name {
    display: -webkit-box !important;
    width: 100%;
    overflow: hidden;
    line-height: 1.2 !important;
    color: $app-color-black;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @media screen and (max-width: 990px) and (min-width: 360px) {
      height: 30px;
    }

    @media screen and (max-width: 1280px) and (min-width: 991px) {
      height: 28px;
    }
  }
  .table {
    @media screen and (max-width: 390px) and (min-width: 360px) {
      font-size: 12px !important;
    }

    @media screen and (max-width: 768px) and (min-width: 391px) {
      font-size: 12px !important;
    }

    @media screen and (max-width: 990px) {
      font-size: 14px !important;
    }

    width: 100%;

    .w__item-name {
      font-size: 10px;
    }

    .w__item-detail {
      font-size: 12px;
    }

    .w__item-is-nature {
      width: 50%;
      font-size: 12px;
      font-weight: 600;
      color: #3a3a3a;
    }

    .w__item-is-farmer {
      width: 50%;
      font-size: 12px;
      font-weight: 600;
      color: #3a3a3a;
    }

    .w__item-shipping-free {
      width: 50%;
      font-size: 12px;
      font-weight: 600;
      color: #3a3a3a;
    }

    .w__item-shipping-boughter-pay {
      width: 50%;
      font-size: 12px;
      font-weight: 600;
      color: #3a3a3a;
    }

    .w__item-shipping-seller-pay {
      width: 50%;
      font-size: 12px;
      font-weight: 600;
      color: #3a3a3a;
    }

    .w__price_detail {
      font-size: 14px !important;
      font-weight: bold;

      @media screen and (max-width: 390px) and (min-width: 360px) {
        font-size: 12px !important;
      }

      @media screen and (max-width: 768px) and (min-width: 391px) {
        font-size: 12px !important;
      }

      @media screen and (max-width: 990px) {
        font-size: 14px !important;
      }
    }

    .red {
      color: $emphasis-red;
    }
  }
}
