.my_review_loader {
  display: flex;
  justify-content: center;
}
.my_review {
  .reviewList {
    .ui.divided.items {
      .item {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
@include mq_down('sm') {
  .my_review {
    padding: 0 16px;
    font-size: 16px;

    .ui.divided.items {
      .item {
        flex-direction: row;
        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 0 0 0 14px;
          .meta {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
