.mypage_notification {
  background-color: white;
  &.zero-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    @include mq_down('md') {
      height: 190px;
    }
  }
  .info_label {
    padding: 3px 5px;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    vertical-align: text-top;
    background-color: #ef6266;
    border-radius: 3px;
    margin: {
      left: 13px;
    }
  }

  .notifications {
    padding: 0 16px;

    .info {
      height: auto;
      border-bottom: solid 1px #ccc;
      padding: {
        top: 16px;
        left: 4px;
      }
      &.read {
        background: rgba(209, 209, 209, 0.1);
        .info_datetime {
          opacity: 0.5;
        }
        .info_link {
          opacity: 0.5;
        }
      }

      .info_datetime {
        margin-bottom: 8px;
        font-size: 13px;
        line-height: $app-line-height-main;
        color: $app-color-black;
        letter-spacing: 1px;
      }

      .info_title {
        margin-bottom: 8px;
        margin-left: 24px;
        font-size: 14px;

        .info_link {
          &:hover {
            text-decoration: underline;
            opacity: 0.7;

            @include mq_down('sm') {
              text-decoration: none;
              opacity: 1;
            }
          }
        }
        .info {
          margin-bottom: 8px;
          border: none;
        }
      }
    }

    .ui.button {
      width: 100%;
    }

    .app_pagination.flex {
      display: flex;
      justify-content: center;
      width: 100% !important;
      padding: 0;
      margin-top: 3rem;
      margin-bottom: 52px;
    }

    li.item {
      display: none;
    }

    .first {
      display: none;
    }

    .last {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    .app_pagination.flex {
      .ui.button {
        padding: 0.78571429em 1.5em;
        font-size: 14px;
      }

      .previous {
        margin-right: 8px;
      }

      .next {
        margin-left: 40px;
      }
    }
  }
  .w__pagination {
    display: flex;
    justify-content: center;
    .gc__pagination {
      width: auto;
    }
    &.disabled {
      .gc__pagination {
        &:hover {
          cursor: not-allowed;
        }
        .rc-pagination {
          pointer-events: none;
          opacity: 0.7;
        }
      }
    }
  }
}

.b__email-config {
  margin-top: 24px;
  text-align: right;

  button {
    padding: 0.85rem;
    font-size: 700;
    font-weight: bold;
    color: #fff;
    background: #ffc107;
    border: #dedce0;
    border-radius: 4px;
    outline: none;
  }
}

.b__email-isread-button {
  text-align: right;
}

.b__email-bar {
  padding-top: 0px !important;
}
