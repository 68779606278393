.b__sidenav-layout {
  position: relative;
  display: flex;
}

// overlayのスタイル
.b__sidenav__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 767px) {
    background: rgba(1, 1, 1, 0.3);
  }
}

.b__sidenav {
  min-width: 280px;
  max-width: 280px;
  background: #fff;
  border-right: 1px solid lighten(#dadce0, 10%);

  @media screen and (max-width: 767px) {
    display: none;

    &.is-open {
      position: fixed;
      top: 0;
      z-index: 15;
      display: block;
      height: 100vh;
      overflow-y: scroll;

      .b__sidenav__listitem:last-child {
        margin-bottom: 25vh;
      }
    }
  }

  // 閉じるボタンのスタイル
  &__closed {
    display: none;

    @media screen and (max-width: 767px) {
      &.is-open {
        display: flex;
        justify-content: flex-end;

        .ui.button {
          margin: 8px;
          font-size: 18px;
          background: transparent;

          &:focus,
          &:active,
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }

  &__inner {
    padding: 1.5rem 1rem;
  }

  &__search-input {
    .ui.input {
      width: 100%;
    }
  }

  &__company {
    display: flex;
    justify-content: flex-end;
  }

  &__list {
    padding: 0;
    list-style: none;
  }

  &__listitem {
    margin: 1rem 0;
  }

  &__listitem-title {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: normal;
  }

  &__column {
    &-fields {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__field {
    max-width: 40%;

    .input-field {
      width: 100%;
    }
  }

  // アコーディオンのスタイル
  &__accordion {
    &-content {
      display: none;
      overflow: hidden;
      opacity: 0;

      .ui.checkbox {
        display: block;
        margin-bottom: 16px;

        :last-child {
          margin-bottom: 0;
        }
      }
    }

    &-hidden {
      display: none;
    }

    &-title {
      display: block;
      margin: 1rem 0;
      font-size: 14px;
      cursor: pointer;

      &::after {
        font-family: 'FontAwesome';
        font-size: 14px;
        content: '\f054';
      }
    }

    &-hidden:checked + &-title::after {
      content: '\f078';
    }

    &-hidden:checked + &-title + &-content {
      display: block;
      padding: 0.5rem 0.25rem 1.5rem;
      opacity: 1;
    }
  }
}
