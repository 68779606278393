.reviewList {
  .reviewHeader {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    .reputationAv {
      font-size: 22px;
    }
    .reviewNum {
      font-size: 16px;
    }
  }
}
