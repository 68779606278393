.app_stripe_complete.flex {
  @include grid-column-span(1, 16);
  @include grid-row-span(1, 16);

  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 81vh;
  min-height: 100%;
  overflow: hidden;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;

  @include mq_down("xs") {
    height: 75vh;
  }

  .wrapper {
    width: 700px;
    padding: 32px 0;

    .w__contents {
      position: absolute;
      top: 30%;
      left: 0;

      @include mq_down("sm") {
        top: 20%;
      }

      @include mq_down("xs") {
        top: 18%;
      }

      .ui.header.title_text {
        @include mq_down("sm") {
          margin: 80px 0 0;
        }
      }
    }

    &.app_hidden {
      display: none;
    }
  }

  .state_loggined {
    margin-bottom: 40px;

    @include mq_down("sm") {
      margin-bottom: 20px;
    }

    &:hover {
      .app_signup_complete.flex div.flex .ui.button.submit_login {
        background-color: lighten(#ffc107, 10%);
      }

      @include mq_down("sm") {
        opacity: 1;
      }
    }
  }

  div.flex {
    @include grid-column-span(1, 16);
    @include grid-row-span(1, 16);

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    background-color: white;

    .title_text {
      margin: 25px 0 0;
      font-size: 22px;
      text-align: center;
    }

    .container_text {
      margin: 24px 61px 57px;
      font-size: 18px;
      text-align: center;
    }

    .ui.button.submit_login {
      width: 320px;
      height: 48px;
      padding: 0;
      color: white;
      background-color: #ffc107;

      &:hover {
        background-color: lighten(#ffc107, 10%);

        @include mq_down("sm") {
          opacity: 1;
        }
      }
    }

    .grid {
      @include display-grid;
      @include grid-columns-16fr;
      @include grid-rows(48px);

      .ui.image {
        @include grid-column-span(1, 16);
        @include grid-row-span(1, 1);

        z-index: 1;
        width: 20px;
        height: 20px;
        margin: 14px 13px;
      }

      .content.flex {
        @include grid-column-span(1, 16);
        @include grid-row-span(1, 1);

        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  footer.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 456px;
    padding: 32px 0;
    margin-bottom: 8px;
    background-color: white;

    .attention_signup {
      width: 100%;
      padding-bottom: 16px;
      text-align: center;
    }

    .ui.button.basic.btn_signup {
      width: 320px;
      height: 48px;
      margin-bottom: 44px;
      font-weight: 600 !important;
      color: #3f98e2 !important;
      box-shadow: 0 0 0 1px #3f98e2 inset;
    }

    .gotop {
      width: 100%;
      color: black;
      text-align: center;

      &:visited,
      &:active {
        color: black;
      }
    }
  }

  @include mq_down("sm") {
    /**
       * Under iPad
       */
    background-color: #fff;
    background-image: none !important;

    .wrapper {
      width: 100%;
      padding: 0;
    }

    div.flex {
      .title_text {
        margin: 112px 0 0;
      }

      .container_text {
        margin: 11px 61px 49px;
      }

      .ui.button.submit_login {
        width: 256px;
        margin: 0 0 162px;
      }
    }

    footer.flex {
      width: 320px;

      a {
        width: 256px;

        .ui.button.basic.btn_signup {
          width: 256px;
        }
      }
    }
  }
}
