.app__introduction_account {
  justify-content: center;
  width: 100%;

  .b__how_to {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 640px;
    margin: 0 auto;

    @include mq_down("sm") {
      width: 100%;
    }

    .w__header_label {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: {
        top: 56px;
        bottom: 56px;
      }

      @media screen and (max-width: 767px) {
        padding: {
          top: 32px;
          bottom: 56px;
        }
      }

      @media screen and (max-width: 659px) {
        padding: {
          top: 32px;
          bottom: 32px;
        }
      }

      img {
        width: 52px;
        height: 11px;
        padding-right: 8px;
      }

      h3 {
        padding-right: 8px;
        margin: 0;
        font-size: 26px;
        line-height: $app-line-height-main;
        color: $app-color-black;

        @include mq_down("sm") {
          font-size: 22px;
        }
      }
    }

    .w__row.bottom {
      padding-bottom: #{$body-bottom}px;
    }

    .w__row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      padding: 29px 0;

      &.w__first_row {
        padding: {
          top: 0;
          bottom: 24px;
        }
      }

      .w__image {
        width: 300px;
        margin-right: 20px;

        img {
          border: solid 1px #f0f0f0;
        }

        @include mq_down("xs") {
          margin: 0;
        }
      }

      .w__contents {
        width: 300px;
        padding-bottom: 8px;
        margin-left: 20px;

        .w__title_wrapper {
          display: flex;
          padding-bottom: 8px;

          .w__title_number {
            margin-right: 8px;
            font-size: 16px;
            line-height: $app-line-height-main;
            color: $app-color-black;
          }

          .w__title {
            margin: 0;
            font-size: 16px;
            line-height: $app-line-height-main;
            color: $app-color-black;
          }
        }

        span {
          font-size: 16px !important;
        }

        span {
          font-size: 18px;
          color: $app-color-black;
        }

        a {
          color: #3f98e2;
        }

        @include mq_down("xs") {
          margin: 0;
        }
      }
    }
  }

  .turn_landing {
    padding: 35px 0 32px;

    @media screen and (max-width: 767px) {
      padding: 8px 0 32px !important;
    }

    a {
      color: $app-color-black;
    }

    a:hover {
      text-decoration: underline;
      opacity: 0.8;

      @include mq_down("sm") {
        text-decoration: none;
        opacity: 1;
      }
    }
  }

  // 画面サイズ767px未満の時、横並びになっている要素を縦並びにしたい為メディアクエリを書いています。樋高
  @media screen and (max-width: 767px) {
    .w__header_label {
      padding: {
        top: 32px;
        bottom: 32px;
      }
    }

    .b__how_to {
      .w__row {
        padding: 0 16px;

        .w__image {
          width: auto;
          margin: 0;
        }

        .w__contents {
          width: 100%;
          margin: 0;
        }
      }
    }

    .w__row {
      padding-top: 48px !important;

      &.w__first_row {
        padding: {
          top: 0 !important;
        }
      }

      .w__image {
        order: 2;
        padding-top: 40px;
        margin-left: 20px;
      }

      .w__contents {
        order: 1;
        margin-right: 20px;
      }
    }
  }
}
