@include mq_down("sm") {
  .user-follow-list {
    padding: 0 16px;
    font-size: 16px;

    .ui.divided.items {
      .item {
        flex-direction: row;
        .content.self-introduction {
          padding-top: 0;
          padding-left: 14px;
        }
      }
    }

    .ui.items:not(.unstackable) {
      .item {
        .image {
          img {
            width: 80px !important;
            height: 80px !important;
          }
        }
      }
    }
  }
}
