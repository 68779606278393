.a__introduction_fee {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: {
    right: 16px;
    left: 16px;
  }

  @include mq_down("sm") {
    min-height: 400px;
  }

  .w__introduction_fee {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 368px;

    header {
      width: 100%;
      font-size: 26px;
      font-weight: bold;
      line-height: 26px;
      color: #222222;
      text-align: center;
      padding: {
        top: 56px;
        bottom: 56px;
      }
      @media screen and (max-width: 767px) {
        padding: {
          top: 32px;
          bottom: 32px;
        }
      }
      @include mq_down("sm") {
        font-size: 22px;
      }
    }

    img {
      width: 80%;
      margin: 0 auto 56px auto;
      @include mq_down("sm") {
        margin-bottom: 32px;
      }
    }
  }
}
