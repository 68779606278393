.a__comments {
  .fix-chat-text {
    .seller_name:hover {
      text-decoration: underline;
      opacity: 0.7;

      @include mq_down("md") {
        color: $hover-blue-sm;
        text-decoration: none;
        opacity: 1;
      }
    }
    .chat_fish_name {
      color: $hover-blue-sm;
      text-decoration: none;
    }
    .chat_notes {
      font-size: 12px;
    }
  }
}
