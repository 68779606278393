.app_form_label_icon.flex {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 16px;
  font-weight: bold !important;
  background-color: #ef6266;
  border-radius: 3px;
  margin: {
    bottom: 1px;
    left: 10px !important;
  }

  span {
    font-size: 10px !important;
    line-height: 10px;
    color: white;
  }
}

.app_form_label_icon.flex.optional {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  min-width: 28px;
  height: 16px;
  background-color: #d8d8d8;
  border-radius: 3px;

  span {
    font-size: 10px;
    line-height: 10px;
    color: black;
  }
}
