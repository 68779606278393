.a__leave {
  margin-bottom: 80px;

  .w__main {
    display: flex;
    justify-content: center;
  }

  .w__form {
    width: 700px;

    @include mq_down() {
      width: 90%;
    }
  }

  .w__field-title {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    color: #222;
  }

  .w__field {
    margin: {
      bottom: 26px;
    }

    .ui.dropdown {
      padding: {
        top: 10px;
        right: 29.4px;
        bottom: 10px;
        left: 14px;
      }

      .icon {
        padding: {
          top: 3px;
          right: 16px;
          bottom: 3px;
        }
        font-size: 24px;
        color: #000;
      }

      .text {
        font-size: 14px;
      }
    }

    textarea {
      width: 100%;
      height: 118px;
      padding: 11px 16px;
      font-size: 14px;
      color: #222;
      resize: none;
      border: 1px solid #ccc;
      border-radius: 5px;
      outline: none;
    }
  }

  .w__action-btn {
    .ui.button {
      height: 48px;
      font-size: 14px;
      color: #fff;
      background: #ffc107;

      &:hover {
        opacity: 0.8;
        transition-duration: 0.4s;
      }
    }
  }
}

.app__modal.w__leave-modal {
  width: 310px !important;
  padding: {
    right: 32px !important;
    left: 32px !important;
  }

  .b__body {
    padding: {
      bottom: 0 !important;
    }
  }

  .w__modal-inner {
    display: flex;
    justify-content: center;
  }

  .w__caption {
    margin: 0;
    font-size: 18px;
    color: #000;
    text-align: center;
  }

  .w__button {
    .cancel {
      margin-right: 10px;
      font-size: 14px;
      color: #ffc107;
      background: #fff;
      border: 2px solid #ffc107;
    }

    .leave {
      font-size: 14px;
      color: #fff;
      background: #ffc107;
    }
  }
}
