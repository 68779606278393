.description {
  .important {
    display: block;
    background-color: #fff4e6;
    border: 1px solid #f76707;
    font-size: 14px;
    margin: 20px 0;
    padding: 8px;
    text-align: left;
  }
}
