.cell {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  min-height: 300px;
  padding: 0 40px;
  -ms-flex-pack: center;
}

.ElementsApp,
.ElementsApp .InputElement {
  color: black !important;
}

.InputElement.is-complete {
  color: black !important;
}

.cell + .cell {
  margin-top: 70px;
}

.cell.intro {
  padding: 0;
}

@media (min-width: 670px) {
  .cell.intro {
    align-items: center;
    -ms-flex-align: center;
    text-align: center;
  }

  .optionList {
    margin-left: 13px;
  }
}

.cell.intro > * {
  width: 100%;
  max-width: 700px;
}

.cell.intro .common-IntroText {
  margin-top: 10px;
}

.cell.intro .common-BodyText {
  margin-top: 15px;
}

.cell.intro .common-ButtonGroup {
  width: auto;
  margin-top: 20px;
}

.stripe-elements.elements {
  padding: 35px;
  background-color: #fff;
}

.stripe-elements.elements * {
  padding-bottom: 5px;
  font-family: Quicksand, Open Sans, Segoe UI, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.stripe-elements.elements .fieldset {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0 15px 15px;
  color: $app-color-black;
  border-style: none;
  -ms-flex-pack: justify;
}

.stripe-elements.elements .field {
  width: 100%;
  padding: 13px 20px;
  margin-bottom: 16px;
  color: black;
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 0;
}

.stripe-elements.elements .field.half-width {
  width: calc(50% - (5px / 2));
}

.stripe-elements.elements .field.third-width {
  width: 100%;
  margin-bottom: 16px;
  color: black;
}

.stripe-elements.elements .field + .field {
  margin-top: 6px;
}

.stripe-elements.elements .field.focus,
.stripe-elements.elements .field:focus {
  color: #424770;
  background-color: #f6f9fc;
}

.stripe-elements.elements .field.invalid {
  background-color: #fa755a;
}

.stripe-elements.elements .field.invalid.focus {
  background-color: #f6f9fc;
}

.stripe-elements.elements .field.focus::-webkit-input-placeholder,
.stripe-elements.elements .field:focus::-webkit-input-placeholder {
  color: #cfd7df;
}

.stripe-elements.elements .field.focus::-moz-placeholder,
.stripe-elements.elements .field:focus::-moz-placeholder {
  color: #cfd7df;
}

.stripe-elements.elements .field.focus:-ms-input-placeholder,
.stripe-elements.elements .field:focus:-ms-input-placeholder {
  color: #cfd7df;
}

.stripe-elements.elements input,
.stripe-elements.elements button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-style: none;
  outline: none;
}

.InputElement is-complete {
  color: black;
}

.stripe-elements.elements input {
  color: #fff;
}

.stripe-elements.elements input::-webkit-input-placeholder {
  color: #9bacc8;
}

.stripe-elements.elements input::-moz-placeholder {
  color: #9bacc8;
}

.stripe-elements.elements input:-ms-input-placeholder {
  color: #9bacc8;
}

.stripe-elements.elements button {
  display: block;
  width: calc(100% - 30px);
  height: 45px;
  margin: 0 15px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #ffc107;
  border-radius: 0;
}

.stripe-elements.elements button:active {
  background-color: #f5be58;
}

.stripe-elements.elements .error svg .base {
  fill: #fa755a;
}

.stripe-elements.elements .error svg .glyph {
  fill: #fff;
}

.stripe-elements.elements .error .message {
  color: #fa755a;
}

.stripe-elements.elements .success .icon .border {
  stroke: #fcd669;
}

.stripe-elements.elements .success .icon .checkmark {
  stroke: #ffc107;
}

.stripe-elements.elements .success .title {
  color: black;
}

.stripe-elements.elements .success .message {
  color: #9cabc8;
}

.stripe-elements.elements .success .reset path {
  fill: #fff;
}

/* Success Icon */
.elements .success {
  position: absolute;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: hidden;
  text-align: center;
  pointer-events: none;
  -ms-flex-align: center;
  -ms-flex-pack: center;
}

@media (min-width: 670px) {
  .elements .success {
    padding: 40px;
  }
}

.elements .success > * {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-duration: 0.35s;
  transition-property: opacity, transform;
  transform: translateY(50px);
}

.elements.submitting .success .icon {
  margin: 0 0 75px;
  transform: translateY(70px) scale(0.75);
}

.elements .success .icon svg {
  will-change: transform;
}

.elements .success .icon .border {
  stroke-dasharray: 251;
  stroke-dashoffset: 62.75;
  transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform-origin: 50% 50%;
  animation: spin 1s linear infinite;
}

.elements .success .icon .checkmark {
  stroke-dasharray: 60;
  stroke-dashoffset: 60;
  transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0.35s;
}

.elements .success .title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
}

.elements .success .message {
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6em;
}

.elements .success .message span {
  font-size: inherit;
}

.elements .success .reset:active {
  opacity: 0.65;
  transition-delay: 0s;
  transition-duration: 0.15s;
}

.elements .success .reset svg {
  will-change: transform;
}

/* Error Icon */

.elements .error {
  position: absolute;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 15px;
  font-size: 13px !important;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-duration: 0.35s;
  transition-property: opacity, transform;
  transform: translateY(10px);
  -ms-flex-pack: center;
}

.elements .error.visible {
  opacity: 1;
  transform: none;
}

.elements .error .message {
  font-size: inherit;
}

.elements .error svg {
  flex-shrink: 0;
  margin-top: -1px;
  margin-right: 10px;
  -ms-flex-negative: 0;
}

/* Submitting Animation */
.elements.submitted form,
.elements.submitting form {
  pointer-events: none;
  opacity: 0;
  transform: scale(0.9);
}

.elements.submitted .success,
.elements.submitting .success {
  pointer-events: all;
}

.elements.submitting .success .icon {
  opacity: 1;
}

.elements.submitted .success > * {
  opacity: 1;
  transform: none !important;
}

.elements.submitted .success > :nth-child(2) {
  transition-delay: 0.1s;
}

.elements.submitted .success > :nth-child(3) {
  transition-delay: 0.2s;
}

.elements.submitted .success > :nth-child(4) {
  transition-delay: 0.3s;
}

.elements.submitted .success .icon .border,
.elements.submitted .success .icon .checkmark {
  opacity: 1;
  stroke-dashoffset: 0 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.app_titlebar {
  h3 {
    font-size: 26px !important;
    @media screen and (max-width: 767px) {
      font-size: 16px !important;
    }
  }
}
