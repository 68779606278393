.user-follow-loader {
  margin-bottom: 100px;
  font-size: 16px;
  text-align: center;
}

.user-follow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 650px;
  margin: 0 auto 76px;
}
