.ui.items {
  .item {
    .ui.tiny.image {
      img {
        border-radius: 50%;
      }
    }
    .content.self-introduction {
      .header {
        display: flex;
        flex-direction: row;
        font-size: 16px;

        a:hover {
          color: $hover-blue-sm;
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down("sm") {
            text-decoration: none;
            opacity: 1;
          }
        }
      }
      .description {
        width: 100%;
        overflow: hidden;
        font-size: 12px;

        p {
          display: -webkit-box;
          /*! autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }
    .follow-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .ui.button {
        width: 120px;
        height: 32px;
        padding: 9px 17.5px;
        margin-left: 8px;
        color: $app-color-white;
        background-color: $app-yellow-button;

        &:hover {
          opacity: 0.8;
          transition-duration: 0.4s;

          @include mq_down("sm") {
            opacity: 1;
          }
        }
      }

      @include mq_down("sm") {
        display: none;
      }
    }
    .follow-button.sm {
      display: none;
      @include mq_down("sm") {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        margin-top: 8px;

        .ui.button {
          margin-left: 0;
        }
      }
    }

    .unfollow-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .ui.button {
        width: 120px;
        height: 32px;
        padding: 9px 17.5px;
        margin-left: 8px;
        color: $app-color-black;
        background-color: $app-white-button;
        border: solid 1px $app-color-black;

        &:hover {
          opacity: 0.8;
          transition-duration: 0.4s;

          @include mq_down("sm") {
            opacity: 1;
          }
        }
      }

      @include mq_down("sm") {
        display: none;
      }
    }

    .unfollow-button.sm {
      display: none;
      @include mq_down("sm") {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        margin-top: 8px;

        .ui.button {
          margin-left: 0;
        }
      }
    }
  }
}
