.sales_tab-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 0 15px;

  .sales_tab-wrapper-inner {
    display: grid;
    grid-template: 40px / repeat(3, 320px);
  }

  .sales_tab {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .sales_tab_current {
    color: #fff;
    background: #ffc107;
  }
}
