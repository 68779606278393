.w__shipping_info {
  margin: 40px 0;
  font-size: 16px;
  text-align: center;
  @media only screen and (max-width: 992px) {
    font-size: 14px;
  }

  h3 {
    margin-bottom: 16px;
    font-size: 26px;
    color: $app-color-black;

    @media only screen and (max-width: 767px) {
      font-size: 22px;
    }
  }

  .shipping_message {
    margin-bottom: 16px;
  }

  .bought_message {
    font-size: 14px;
  }

  .buyer_shipping_info {
    width: 540px;
    padding: 16px 24px 8px;
    color: #888888;
    text-align: start;
    background-color: $app-gray-input;
    border: solid 1px $app-gray-border;
    border-radius: 5px;

    @media only screen and (max-width: 1130px) {
      width: 540px;
    }

    @media only screen and (max-width: 992px) {
      width: 648px;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    table {
      margin-top: 8px;
      border-spacing: 0 8px;
    }

    tr {
      vertical-align: top;
    }

    th {
      min-width: 120px;
      font-weight: normal;
      text-align: left;
    }

    td {
      max-width: 468px;
      color: #000000;
      overflow-wrap: break-word;
    }
  }
}
