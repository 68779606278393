.a__RedCircleBadge {
  position: absolute;
  top: -8px;
  left: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1;
  color: white;
  white-space: nowrap;
  background-color: #ef6266;
  border-radius: 9px;

  &:hover {
    text-decoration: none;
    background-color: #ef6266;
  }

  padding: {
    top: 2px;
    right: 4.5px;
    bottom: 2px;
    left: 4.5px;
  }
}
