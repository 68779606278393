.a__sns_icon_row {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  img {
    width: 32px;
    height: 32px;
  }

  .view_icon_facebook {
    background-color: #fff;
  }
}
