.a__image-slider {
  .slick-arrow {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: rgba(34, 34, 34, 0.3);
    border-radius: 50%;

    .ui.image {
      width: auto !important;
      height: auto;
    }

    &::before {
      content: none;
    }
  }

  .slick-next {
    position: absolute;
    right: 10px;
  }

  .slick-prev {
    position: absolute;
    left: 10px;
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;

    li {
      width: 16px;
      height: 16px;

      button {
        &::before {
          // font-size: 54px;
        }
      }
    }
  }
}
