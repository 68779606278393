.a__comments {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  p {
    text-align: center;
  }

  .chat_input_area {
    width: 100%;

    .word_count_under {
      display: block;
      text-align: right;
    }
  }

  .fiex_chat_header {
    .app_titlebar {
      padding-bottom: 24px;
    }
  }

  .fix-chat-divliner {
    width: 720px;

    @media only screen and (max-width: 1130px) {
      width: 540px;
    }

    @media only screen and (max-width: 767px) {
      width: 415px;
    }

    @media only screen and (max-width: 414px) {
      width: 280px;
    }
  }

  .fix-chat-text {
    padding: {
      bottom: 24px;
    }

    a:hover {
      text-decoration: underline;
      opacity: 0.7;
    }

    @include mq_up('lg') {
      font-size: 16px;
    }
  }
  .form_item.flex_container {
    display: flex;
    margin: {
      bottom: 20px;
    }
    span {
      margin-left: 9px;
    }
    .checkbox {
      padding-top: 4px;
    }
    .chat_count_under {
      text-align: right;
      margin: 2px 0 0 auto;
    }
  }
}
