.a__saleinfo_list {
  box-sizing: border-box;
  margin-bottom: 0 0 10px;
  color: $app-color-black;
  text-align: left;
  background: #f0f0f0;
  border: {
    right: 1px solid #ccc;
    bottom: 1px solid #ccc;
    left: 1px solid #ccc;
  }

  @include mq_down() {
    font-size: 12px;
  }

  dt,
  dd {
    font-size: 14px;
    line-height: $app-line-height-main;
    padding: {
      top: 5px;
      right: 0;
      left: 5px;
    }

    border: {
      top: 1px solid #ccc;
    }
  }

  dt {
    float: left;
    width: 128px;
    padding: {
      bottom: 0;
    }
  }

  dd {
    padding: {
      bottom: 5px;
    }

    border: {
      left: 1px solid #ccc;
    }

    margin: {
      left: 128px;
    }

    background: $background-color;

    &::after {
      display: block;
      clear: both;
      content: "";
    }
  }

  .w_delivery-burden {
    padding: {
      top: 16px;
      right: 0;
      bottom: 9px;
      left: 5px;
    }
  }

  .seller_name {
    a {
      font-size: 14px;
      line-height: $app-line-height-main;

      &:hover {
        text-decoration: underline;
        opacity: 0.7;

        @include mq_down("md") {
          color: $hover-blue-sm;
          text-decoration: none;
          opacity: 1;
        }
      }
    }
  }
  p {
    word-wrap: break-word;
  }
}
