.app_pagination {
  &.flex {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  li {
    list-style: none;
  }

  .ui.button {
    background-color: #ffc107 !important;

    @include hover_button_opacity();
  }

  .active.item {
    .ui.yellow.button {
      // background-color: lighten(#fbbd08, 30%);
      cursor: default;

      @include hover_button_opacity();
    }
  }

  .first {
    margin-right: 40px;
  }

  .previous {
    margin-right: 8px;
  }

  .item {
    margin: 0 4px;
  }

  .next {
    margin-left: 40px;
  }

  .last {
    margin-left: 40px;
  }
}

@media screen and (max-width: 767px) {
  .app_pagination.flex {
    padding: 0 1%;

    .ui.button {
      padding: 0.78571429em 0.835714em;
      font-size: 12px;
    }

    .item .ui.button {
      width: 39px;
    }

    .first {
      width: 57px;
      margin-right: 6px;
    }

    .previous {
      margin-right: 2px;
    }

    .item {
      margin: 0 4px;
    }

    .next {
      margin-left: 2px;
    }

    .last {
      width: 57px;
      margin-left: 6px;
    }
  }
}
