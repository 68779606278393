.a__introduction-prepayment {
  display: flex;
  justify-content: center;
  padding: 0 20px;

  .b__main {
    width: 640px;
    padding-bottom: #{$body-bottom}px;

    .info_link {
      &:hover {
        text-decoration: underline;
        opacity: 0.7;

        @include mq_down('sm') {
          text-decoration: none;
          opacity: 1;
        }
      }
    }
  }

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 56px 0;
  }

  h2,
  h3 {
    padding: 8px 0;
    margin: 16px 0;
    border-bottom: 1px solid #d8d8d8;
  }

  h3 {
    font-size: 16px;
    line-height: 1.5;
  }

  table {
    display: inline-block;
    padding: 10px;
    margin: 5px 0px;
    text-align: center;
    vertical-align: top;
    border-collapse: collapse;
  }

  td,
  th {
    padding: 6px 12px;
    border: 1px solid #d8d8d8;
  }

  td {
    width: 95px;
  }

  ul {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  table {
    padding: 0px !important;
    margin: 0px !important;
  }
}
