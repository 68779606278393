.b__fishes__pagination {
  .b__jump-button {
    font-size: 14px;
    color: black;
    background: none;
    border: none;
    &:hover {
      color: #ffc107;
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
    &.disabled {
      color: black;
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px 0;

  .ui.dropdown {
    min-width: 64px;
    padding: 7px;

    .dropdown.icon {
      display: flex;
      align-items: center;
      height: 100%;
      padding-right: 7px;
    }
  }
}
