.app__bank-account {
  background: {
    color: white;
  }
  padding: {
    bottom: 32px;
  }

  .ui.header {
    margin-top: 52px !important;
  }

  .payment_wrapper {
    width: 400px;
    margin: 0 auto;

    .description {
      font-size: 16px;

      @media (max-width: 350px) {
        font-size: 14px;
      }
    }

    .bank_account_explain_link {
      display: inline-block;
      font-size: 20px;
      text-align: center;

      span {
        color: #ef6266;
      }

      a:hover {
        text-decoration: underline;
        opacity: 0.7;

        @include mq_down() {
          color: $hover-blue-sm;
          text-decoration: none;
          opacity: 1;
        }
      }
    }
  }

  .already_bank {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    p {
      width: 500px;
      margin: 0;
      font-size: 16px;
      text-align: center;

      @include mq_down('sm') {
        width: 100%;
      }
    }

    .beta_infomation {
      p {
        padding-bottom: 10px;
        font-size: 13px;
      }
    }

    @media (max-width: 350px) {
      margin: 0 16px;
    }

    .already_bank_link {
      width: 100%;
      margin: 32px 0;
      text-align: center;

      a {
        &:hover {
          text-decoration: underline;
          opacity: 0.8;
          transition-duration: 0.4s;

          @include mq_down('sm') {
            text-decoration: none;
            opacity: 1;
          }
        }
        .ui.button.payment_button {
          width: 320px;
          padding: 0 !important;
          margin-bottom: 16px;

          span {
            &:first-child {
              font-size: 14px;
            }
            &:last-child {
              font-size: 12px;
            }
          }

          @include mq_down('sm') {
            width: 100%;
          }
        }
      }
    }

    .b__bank-info {
      display: flex;
      width: 100%;
      margin-bottom: 40px;
      border: solid 1px #dddddd;

      .w__left-column {
        background-color: #f2f2f2;
        border-right: solid 1px #dddddd;

        div {
          display: flex;
          align-items: center;
          width: 90px;
          height: 30px;
          padding-left: 16px;
          font-size: 14px;
          border-bottom: solid 1px #dddddd;

          &:last-child {
            border: none;
          }
        }
      }
      .w__right-column {
        width: calc(100% - 90px);

        div {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 30px;
          padding-right: 16px;
          font-size: 14px;
          border-bottom: solid 1px #dddddd;

          @media (max-width: 350px) {
          }

          &:last-child {
            border: none;
          }
        }
      }
    }
  }

  .already_card {
    p {
      &:first-child {
        font-size: 16px;
        @media (max-width: 350px) {
          font-size: 14px;
        }
      }
    }

    @media (max-width: 350px) {
      margin: 0 16px;
    }

    .a__card_tile {
      margin-bottom: 40px;
      border: solid 1px #dddddd;
      .b__tile-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        padding: 0 43px 0 16px;
        background-color: #f2f2f2;
        border-bottom: solid 1px #dddddd;
        span {
          font-size: 14px;
        }
      }
      .b__tile-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        padding: 0 49px 0 16px;
        span {
          font-size: 16px;
        }
      }
    }

    a {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        opacity: 0.7;

        @include mq_down('sm') {
          text-decoration: none;
          opacity: 1;
        }
      }
    }
  }

  .b__unregistered {
    text-align: center;

    @media (max-width: 350px) {
      margin: 0 16px;
    }

    p {
      margin-bottom: 40px;
      font-size: 16px;
    }

    .ui.button.payment_button {
      width: 320px;
      padding: 0 !important;
      margin-bottom: 16px;

      span {
        &:first-child {
          font-size: 14px;
        }
        &:last-child {
          font-size: 12px;
        }
      }
      @include mq_down('sm') {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .app_titlebar h3 {
    font-size: 16px !important;
  }
  .app__bank-account {
    .payment_wrapper {
      width: 100%;
      padding: 0 16px;
    }
  }
  .mobile__header {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    top: 28px;
  }
  .pc__only {
    display: none !important;
  }
}

@media screen and (min-width: 767px) {
  .mobile__only {
    display: none !important;
  }
}
