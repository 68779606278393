.a__cognito_callback {
  .ui.loader {
    &::after {
      border-color: #00b7c2 transparent transparent !important;
    }
  }
}

.app_hidden {
  display: none !important;
}

.social {
  @include grid-column-span(1, 16);
  @include grid-row-span(1, 16);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;

  .wrapper {
    width: 456px;
  }

  .flex_required {
    display: flex;
  }

  .app_titlebar {
    width: 456px;
    height: 86px;
    margin: 8px 0;
    background-color: white;
  }

  .app_disabled {
    pointer-events: none;
    opacity: 0.45;
  }

  form.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    background-color: white;

    .sns_buttons.flex {
      display: flex;
      flex-wrap: wrap;
      width: 320px;

      .ui.button {
        width: 100%;
        height: 48px;
        margin-bottom: 16px;
        color: white;
      }

      .btn_fb {
        $fbcolor: #3c5281;

        background-color: $fbcolor !important;

        &:hover {
          background-color: darken($fbcolor, 10%) !important;
        }

        .grid {
          @include display-grid;
          @include grid-columns-16fr;
          @include grid-rows(48px);

          .ui.image {
            @include grid-column-span(1, 16);
            @include grid-row-span(1, 1);

            z-index: 1;
            width: 20px;
            height: 20px;
            margin: 14px 13px;
          }
        }
      }

      .btn_google {
        $google-color: #cc5849;

        background-color: $google-color !important;

        &:hover {
          background-color: darken($google-color, 10%) !important;
        }

        .grid {
          width: 100%;
          height: 48px;

          @include display-grid;
          @include grid-columns-16fr;
          @include grid-rows(48px);

          .ui.image {
            @include grid-column-span(1, 16);
            @include grid-row-span(1, 1);

            z-index: 1;
            width: 26px;
            height: 15px;
            margin: 17px 13px 16px;
          }
        }
      }

      .content.flex {
        @include grid-column-span(1, 16);
        @include grid-row-span(1, 1);

        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ui.button {
        padding: 0;
      }
    }
  }

  .liner.flex {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-bottom: 24px;

    .div_liner.flex {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      stroke: black;
    }
  }

  .amplify-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .login_error_message {
      display: none;
      width: 320px;
      margin-bottom: 10px;
      font-size: 12px;
      color: #d62d2d;

      &.display_error {
        display: block !important;
      }
    }

    .ui.input {
      width: 320px;
      height: 48px;
      margin-bottom: 16px;
    }

    .link_forgotpassword {
      width: 320px;
      margin-bottom: 24px;
      text-align: left;
    }

    .ui.button.submit_login {
      width: 320px;
      height: 48px;
      padding: 0;
      color: white;
      background-color: #ffc107;

      &:hover {
        background-color: lighten(#ffc107, 10%);

        @include mq_down("sm") {
          opacity: 1;
        }
      }
    }

    .grid {
      @include display-grid;
      @include grid-columns-16fr;
      @include grid-rows(48px);

      .ui.image {
        @include grid-column-span(1, 16);
        @include grid-row-span(1, 1);

        z-index: 1;
        width: 20px;
        height: 20px;
        margin: 14px 13px;
      }
    }

    .content.flex {
      @include grid-column-span(1, 16);
      @include grid-row-span(1, 1);

      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .input-field {
      width: 256px;
    }

    .field-group.agreement {
      width: 320px;
      margin: 20px 0;

      .ui.button {
        border: none;
      }

      .is_agreement {
        position: relative;
        top: 3px;
        margin-right: 7px;
      }
    }

    .field-group.recaptcha {
      margin-top: 15px;
    }
  }

  footer.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 456px;
    padding: 32px 0;
    margin-bottom: 8px;
    background-color: white;

    .attention_signup {
      width: 100%;
      padding-bottom: 16px;
      text-align: center;
    }

    .ui.button.basic.btn_signup {
      width: 320px;
      height: 48px;
      margin-bottom: 44px;
      font-weight: 600 !important;
      color: #3f98e2 !important;
      box-shadow: 0 0 0 1px #3f98e2 inset;
    }

    .gotop {
      width: 100%;
      color: black;

      &:visited,
      &:active {
        color: black;
      }
    }
  }

  .return_top {
    margin-top: 44px;
    text-align: center;
  }

  .ui.button.disabled_button {
    padding: 0;
    font-weight: normal;
    color: #000;
    text-align: center;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  }

  .ui.button.agreement_disabled_button {
    padding: 0;
    font-weight: normal;
    color: #000;
    text-align: center;
    cursor: pointer;
    background-color: transparent;

    a:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 767px) {
    /**
       * Under iPad
       */
    background-image: none !important;

    .wrapper {
      width: 100%;
      padding: 0 16px;
    }

    .app_titlebar {
      width: 100%;
      height: 82px;

      .ui.header {
        font-size: 16px;
      }
    }

    form.flex {
      .sns_buttons.flex {
        width: 256px;
      }
    }

    .liner.flex {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding-bottom: 24px;

      .div_liner.flex {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        max-width: 103px;
        stroke: black;
      }

      span {
        display: flex;
        align-items: center;
        width: 50px;
        height: 12px;
        padding: 0 7px;
        font-size: 12px;
      }
    }

    .amplify-container {
      display: block;
      // width: 256px;
      width: 100%;

      .ui.input {
        width: 100%;
      }

      .ui.button.submit_login {
        width: 100%;
      }

      .field-group.recaptcha {
        display: flex;
        justify-content: center;
      }
    }

    footer.flex {
      width: 320px;

      a {
        width: 256px;

        .ui.button.basic.btn_signup {
          width: 256px;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .recaptcha {
      transform: translateX(-6.75px);
    }
  }
}
