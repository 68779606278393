.a__signup_thank {
  @include grid-column-span(1, 16);
  @include grid-row-span(1, 16);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;

  .wrapper {
    width: 700px;
    padding: {
      top: 24px;
      bottom: 130px;
    }

    @include mq_down("xs") {
      padding: {
        bottom: 30px;
      }
    }
  }

  .state_loggined {
    &:hover {
      .app_signup_complete.flex div.flex .ui.button.submit_login {
        background-color: lighten(#ffc107, 10%);
      }

      @include mq_down("sm") {
        opacity: 1;
      }
    }
  }

  div.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    background-color: white;

    .title_text {
      margin: 80px 0 0;
      font-size: 22px;
      text-align: center;
    }

    .container_text {
      margin: 24px 16px 57px;
      font-size: 18px;
      text-align: center;
    }

    .ui.button.submit_login {
      width: 320px;
      height: 48px;
      padding: 0;
      color: white;
      background-color: #ffc107;

      &:hover {
        background-color: lighten(#ffc107, 10%);

        @include mq_down("sm") {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    /**
     * Under iPad
     */
    background-color: #fff;
    background-image: none !important;

    div.content {
      .title_text {
        margin: 112px 0 0;
      }

      .ui.button.submit_login {
        width: 256px;
        margin: 0 0 162px;
      }
    }
  }
}
