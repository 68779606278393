.w__date {
  display: flex;
  width: 100%;
  @include mq_down("sm") {
    display: block;
  }

  .w__field {
    display: flex;
    align-items: center;
    padding: {
      right: 8px;
    }
    @include mq_down("sm") {
      padding: 0;
      margin: {
        bottom: 16px;
      }
    }

    &.year {
      display: flex;
      align-items: center;
      .ui.dropdown {
        width: 180px !important;
        @include mq_down("sm") {
          width: 100% !important;
        }
      }
    }

    &.month {
      display: flex;
      align-items: center;
      .ui.dropdown {
        width: 145px !important;
        @include mq_down("sm") {
          width: 100% !important;
        }
      }
    }

    &.day {
      display: flex;
      align-items: center;
      .ui.dropdown {
        width: 145px !important;
        @include mq_down("sm") {
          width: 100% !important;
        }
      }
    }

    span {
      padding: {
        left: 3px;
      }
    }
  }
}
