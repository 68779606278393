.app_mypage {
  max-width: 1200px;
  margin: 0 auto;
  background: {
    color: #fff;
  }

  .app_profile_header {
    @include mq_down('sm') {
      margin-top: 16px;
    }
  }
  padding: {
    right: 60px;
    bottom: #{$body-bottom}px;
    left: 60px;
  }

  @include mq_down() {
    padding: {
      right: 20px;
      bottom: #{$body-bottom}px;
      left: 20px;
    }
  }

  @include mq_down('sm') {
    padding: 0;
  }

  .f__mypage {
    display: flex;

    @include mq_down('sm') {
      flex: {
        wrap: wrap;
      }

      justify-content: space-between;
    }

    .b__navbar {
      width: 280px;
      @include mq_down('sm') {
        order: 1;
        width: 100%;
        margin: 0 auto;
        padding: {
          bottom: #{$body-bottom}px;
        }
      }
    }

    .b__main {
      padding: {
        left: 2%;
      }
      .w__mypage-notification {
        padding: {
          left: 40px;
        }
        .annotation {
          margin-bottom: 10px;
          margin-left: 10px;
        }
        .ui.bottom.attached.segment {
          padding: 0;
        }
        .ui.attached.tabular.menu {
          display: flex;
          justify-content: center;
          a {
            display: flex;
            justify-content: center;
            width: 50%;
            font-weight: bold;
            border-radius: 0 !important;
          }
          .item {
            background: white;
            padding: {
              top: 16px;
              bottom: 16px;
            }
          }
          .active.item {
            pointer-events: none;
            background: white;
            border-top: 4px solid $app-yellow-button;
          }
        }
        @include mq_down('sm') {
          padding: 0;
        }
        .w__list {
          &.loading {
            cursor: not-allowed;

            .ui.attached.tabular.menu {
              a {
                pointer-events: none;
              }
            }
            .ui.bottom.attached.segment {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 500px;
              @include mq_down('md') {
                height: 190px;
              }
            }
          }

          .ui.attached.tabular.menu {
            a.active {
              color: #fff;
              background: #ffc107;
            }
          }

          .ui.bottom.attached.segment {
            border: 2px solid #ffc107;
          }
        }
      }

      @include mq_down('sm') {
        padding: 0;
      }

      width: calc(100% - 280px);

      .tabpage {
        box: {
          shadow: 0 !important;
        }

        border: none !important;
        background: {
          color: #f0f0f0;
        }
      }

      .ui.tabular.menu {
        border: none;

        .active.item {
          border: none;
        }
      }

      .ui.segment {
        border: none;
        border-radius: none;
      }

      .app_titlebar {
        padding: #{$titlebar_top}px 0 #{$titlebar_bottom}px 0;
        font-size: 26px;
        background: {
          color: white;
        }

        @include mq_down('sm') {
          padding: 32px 0;
        }

        h3 {
          font: {
            size: 26px;
          }

          color: $app-color-black;
        }
      }

      @include mq_down('sm') {
        order: 0;
        width: 100%;
      }

      .common_sales_list {
        .image_content {
          min-height: 0;
        }

        .app_sales_list {
          .app_fish_card {
            width: 220px;
            height: 330px;

            @include mq_down() {
              width: 212px;
            }
          }

          .fish_thumbnail {
            width: 100%;
            height: 100%;
          }
        }
      }
      .tiny-loader {
        margin-bottom: 20px;
      }
    }

    .b__gotop {
      text-align: center;
      margin: {
        top: 20px;
      }

      .gotop {
        color: $app-color-black;

        &:hover {
          text-decoration: underline;
          opacity: 0.8;
        }
      }

      @include mq_down() {
        margin-top: 0;
      }

      @include mq_down('sm') {
        display: none;
        order: 2;
        width: 100%;
      }
    }
  }
}
