@mixin grid-column-span($start, $span) {
  -ms-grid-column: $start;
  grid-column: $start / span $span;
  -ms-grid-column-span: $span;
}

@mixin grid-row-span($start, $span) {
  -ms-grid-row: $start;
  grid-row: $start / span $span;
  -ms-grid-row-span: $span;
}

@mixin grid-row-position($value) {
  -ms-grid-row: $value;
  grid-row: $value;
}

@mixin grid-columns-16fr {
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
    1fr;
  grid-template-columns: repeat(16, 1fr);
}

@mixin grid-rows-16fr {
  -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(16, 1fr);
}

@mixin grid-columns-minmax($a, $b) {
  -ms-grid-columns: minmax($a, $b);
  grid-template-columns: minmax($a, $b);
}

@mixin grid-rows-minmax($a, $b) {
  -ms-grid-rows: minmax($a, $b);
  grid-template-rows: minmax($a, $b);
}

@mixin grid-rows($values...) {
  -ms-grid-rows: $values;
  grid-template-rows: $values;
}

@mixin display-grid {
  display: grid;
  display: -ms-grid;
}

@mixin grid-column-align($value: center) {
  -ms-grid-column-align: $value;
  justify-self: $value;
}

@mixin grid-row-align($value: center) {
  align-self: $value;
  -ms-grid-row-align: $value;
}

// メディアクエリ呼び出し用のmixin
@mixin mq_down($breakpoint: md) {
  @media #{map-get($bp_down, $breakpoint)} {
    @content;
  }
}

// メディアクエリ呼び出し用のmixin
@mixin mq_up($breakpoint: md) {
  @media #{map-get($bp_up, $breakpoint)} {
    @content;
  }
}

// レスポンシブ用のクラスを生成するmixin
@mixin responsive($class, $bp: $bp_down) {
  #{$class} {
    @content;
  }

  @each $suffix, $value in $bp {
    @media #{$value} {
      #{$class}_#{$suffix} {
        @content;
      }
    }
  }
}

@mixin center2center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin hover_button_opacity() {
  &:hover {
    opacity: 0.8;
    transition: {
      duration: 0.4s;
    }
  }
}
