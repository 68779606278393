.mobile_mypage_grid {
  display: none;
}

@media only screen and (max-width: 720px) {
  .mobile_mypage_grid {
    display: grid;
    grid-template-rows: auto 280px 540px 540px 540px 40px 370px 200px;

    .notification {
      display: flex;
      grid-template-rows: 90px auto;
      justify-content: center;

      .notification_title {
        justify-self: center;
        padding: 30px 0;

        span {
          padding: 0 14px;
        }
      }

      .notification_content {
        display: grid;
        grid-template-rows: auto auto;

        img {
          justify-self: center;
        }

        span {
          font-family: HiraKakuPro-W6;
          color: #ccc;
        }
      }
    }

    .selled_fish {
      display: flex;
      grid-template-rows: 90px auto;
      justify-content: center;
    }

    .bid_fish {
      display: flex;
      grid-template-rows: 90px auto;
      justify-content: center;
    }

    .redirect_top {
      align-self: center;
      justify-self: center;
      font-family: HiraKakuPro-W3;
      color: #000;
    }

    .top_side {
      padding: 20px;
      background-color: #fff;
    }

    .bottom_side {
      padding: 20px;
      background-color: #fff;

      .setting {
        font-family: HiraKakuPro-W6;
      }
    }
  }
}
