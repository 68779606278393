.a__introduction_styrofoam {
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 610px;
  padding: {
    right: 16px;
    left: 16px;
  }

  @include mq_down("sm") {
    min-height: 400px;
  }

  .w__introduction_styrofoam {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 368px;

    header {
      width: 100%;
      font-size: 26px;
      font-weight: bold;
      line-height: 26px;
      color: #222222;
      text-align: center;
      padding: {
        top: 56px;
        bottom: 56px;
      }
      @media screen and (max-width: 767px) {
        padding: {
          top: 32px;
          bottom: 32px;
        }
      }
      @include mq_down("sm") {
        font-size: 22px;
      }
    }

    ul {
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        font-size: 16px;
        line-height: 16px;
        padding: {
          bottom: 32px;
        }

        .b__label {
          width: 100%;
          font-weight: bold;
          padding: {
            bottom: 10px;
          }
        }

        a {
          color: #1e70bf;

          &:hover {
            text-decoration: underline;
            opacity: 0.8;

            @include mq_down("sm") {
              text-decoration: none;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
