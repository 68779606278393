.a__reply-modal {
  textarea {
    width: 100%;
    height: 200px;
    padding: 17px 8px;
    resize: none;
    border-radius: 5px;
    outline: none;

    &:focus,
    &:active {
      border: 1px solid #85b7d9;
      box-shadow: none;
    }
  }

  &__field {
    .comment {
      margin-bottom: 8px;

      label {
        font-size: 16px;
        color: #2b2b2b;
      }
    }

    .comment-length {
      display: flex;
      justify-content: flex-end;
    }
  }

  .ui.button {
    height: 40px;
    margin-top: 20px;
  }
}
