.app__credit {
  background: {
    color: white;
  }
  padding: {
    bottom: 32px;
  }

  .ui.header {
    margin-top: 52px !important;
  }

  .payment_wrapper {
    width: 320px;
    margin: 0 auto;

    .description {
      font-size: 16px;

      @media (max-width: 350px) {
        font-size: 14px;
      }
    }
  }

  .address_register_link {
    margin-bottom: 32px;
    font-size: 16px;
    text-align: center;

    a:hover {
      text-decoration: underline;
      opacity: 0.7;
    }
    @media (max-width: 350px) {
      margin-right: 16px;
      margin-left: 16px;
    }

    span {
      color: #ef6266;
    }
  }

  .already_card {
    @media (max-width: 350px) {
      margin: 0 16px;
    }

    .a__card_tile {
      margin-bottom: 40px;
      border: solid 1px #dddddd;
      .b__tile-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        padding: 0 43px 0 16px;
        background-color: #f2f2f2;
        border-bottom: solid 1px #dddddd;
        span {
          font-size: 14px;
        }
      }
      .b__tile-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        padding: 0 49px 0 16px;
        span {
          font-size: 16px;
        }
      }
    }

    a {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        opacity: 0.8;
        transition-duration: 0.4s;

        @include mq_down('sm') {
          text-decoration: none;
          opacity: 1;
        }
      }
    }

    .w__delete_button {
      display: flex;
      justify-content: center;

      .ui.button.delete_button {
        width: initial;
        height: 48px;
        margin-top: 8px;
        line-height: $app-line-height-main;
        color: #ef6266;
        background-color: #fff;
        border-radius: 5px;
        font: {
          size: 15px;
        }

        &:hover {
          opacity: 0.8;
          transition-duration: 0.4s;

          @include mq_down('sm') {
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }
  }

  .b__unregistered {
    text-align: center;

    @media (max-width: 350px) {
      margin: 0 16px;
    }

    p {
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 767px) {
  .app_titlebar h3 {
    font-size: 16px !important;
  }
  .app__credit {
    .payment_wrapper {
      width: 100%;
      padding: 0 16px;
    }
  }
}
