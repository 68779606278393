.reviewList {
  .ui.divided.items {
    .item {
      .ui.tiny.image {
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          object-fit: contain;
        }
      }
      .ui.tiny.image.auto-review {
        img {
          border-radius: 0%;
          object-fit: contain;
        }
      }
      .content {
        display: flex;
        align-items: center;
        .meta {
          margin: {
            right: 8px;
          }
          a {
            color: $hover-blue-sm;
          }
          .w__link {
            :hover {
              text-decoration: underline;
              opacity: 0.7;

              @include mq_down('md') {
                text-decoration: none;
                opacity: 1;
              }
            }
          }
        }
        .disabled-link {
          color: #222222;
          :hover {
            text-decoration: none;
            opacity: 1;
          }
        }
      }

      .w__review,
      .w__auto-review {
        display: flex;
        flex-direction: column;
        margin: {
          right: 8px;
        }
      }

      .w__review {
        .links {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .w__link {
            @media screen and (max-width: 414px) {
              width: 100%;
            }
          }
        }

        .w__reply {
          display: inline-block;
          margin-left: 10px;
          font-weight: 600;
          color: #fff;

          cursor: pointer;
          background: #ffc107;
          border: 1px solid #dedce0;
          border-radius: 4px;
          outline: none;

          &:hover {
            text-decoration: underline;
          }

          @media screen and (max-width: 414px) {
            display: block;
            margin: 12px 0 4px;
          }
        }
      }

      .reply__message {
        padding-left: 16px;
        margin-top: 8px;
        color: #777;
      }

      .w__rating {
        margin: {
          bottom: 8px;
        }
        @include mq_down() {
          margin: {
            top: 8px;
          }
        }
      }

      .fish_kind {
        margin-bottom: 8px;
        @include mq_down('sm') {
          margin-top: 8px;
        }
      }
    }
  }
}
