$app-color-main: #fa6681;
$app-color-white: #fbfbfb;
$app-color-black: #222222;
$app-color-disabled: #808080;
$app-color-header: #909090;
$app-color-page: #f7f8f9;
$app-yellow-button: #ffc107;
$app-white-button: #ffffff;
$app-black-border: #555555;
$app-gray-border: #cccccc;
$app-gray-input: #f0f0f0;
$app-color-report: #fafafa;
$app-search_form_background: #ffffff;
$app-lp-color: #4e4e4e;
$error-red: #bd081c;
$hover-blue-sm: #4183c4;
$dark-sky-blue: #3f98e2;
$dropdown-focus: #99cadb;
$point-color: #8186d5;
$cropper-line-color: #e3e7f1;
$emphasis-red: #d90233;
$header-blue: #0358bd;
$lp-background-color: #f2f2f2;
$explain-sub-title-color: #09318f;
$password-reset-button: #efefef;
$password-reset-input: #f1f3f4;
$email-reset-button: #efefef;
$email-reset-input: #f1f3f4;

// font-size
$app-font-size-main: 14; //px
$app-font-size-error: 12; //px
$app-font-size-profileheader: 16; //px
$app-explain-size-main-title-sm: 28; //px
$app-explain-size-sub-title: 28; //px
$app-explain-size-sub-title-sm: 22; //px
$dropdown-input-size: 16; //px
$feature-list-heading-size: 22; //px
$feature-list-heading-size-sm: 18; //px
$ui-heding-size: 22; //px
$userprofile_introduction_size: 16; //px

// line-height
$app-line-height-main: 1.5;
$feature-list-line-height: 20; //px

// margin
$app_profile_header_margin_bottom: 21; //px
$app-explain-mTop: 56; //px
$app-explain-mBottom: 100; //px
$app-explain-sub-title-mBottom: 24; //px
$body-bottom: 100; //px
$button_mTop: 16; //px
$card_margin_sm: 2; //px
$contact-report-mypage: 32; //px
$feature-list-heading-bottom-lg: 16; //px
$feature-list-heading-bottom-md: 16; //px
$feature-list-heading-bottom-sm: 16; //px
$feature-list-item-bottom: 8; //px
$feature-wrap-vertical-lg: 40; //px
$feature-wrap-vertical-md: 60; //px
$feature-wrap-vertical-sm: 110; //px
$feature-wrap-vertical-xs: 140; //px
$nav_option_top: 32; //px
$nav_option_bottom: 8; //px
$profile_header_bottom: 24; //px
$report-item-bottom: 20; //px
$sale-view: 16; //px
$thumbnail_margin_bottom: 10; //px
$ui-button-left: 16; //px
$ui-button-md-top: 21; //px
$w__circle_right: 16; //px

// height
$feature-list-image-lg: 255; //px
$feature-list-image-md: 216; //px
$feature-list-image-sm: 344; //px
$profile_header_height: 80; //px
$ui-button-height-md: 48; //px
$w__circle_height: 80; //px
$explain-button-height: 32; //px
$explain-button-height-md: 48; //px
$app-explain-main-title-height: 34; //px
$app-explain-main-title-height-sm: 56; //px
$app-explain-sub-title-height: 64; //px
$app-explain-sub-title-height-sm: 44; //px

// width
$app-explain-max-width: 990; //px
$profile_header_width: 80; //px
$w__circle_width: 80; //px
$feature-wrap-max-width: 350; //px

// font-family
$app-font-family-main: 'Noto Sans JP';
$app-font-family-header: 'Mplus 1p';

// Components Styles

//padding
$app_title_bar_top: 56; //px
$app_title_bar_bottom: 32; //px
$app-explain-wrapper-pSide: 16; //px
$b__featureList_side: 16; //px
$contact-report: 16; //px
$explain-button-pVertical: 9; //px
$explain-button-pSide: 98; //px
$explain-button-p-md: 8; //px
$nav_bar_top: 32; //px
$titlebar_top: 30; //px
$titlebar_bottom: 40; //px
$ui-header-top: 56; //px
$ui-header-left: 16; //px
$ui-header-sm: 32; //px
$ui-dropdown: 7; //px
$userprofile_introduction_area_side: 8; //px
$userprofile_introduction_area_side_sm: 16; //px

//icon
$camera-icon-top: 70; //px
$camera-icon-left: 34; //px
$camera-icon-top-sm: 32; //px
$camera-icon-left-sm: 17; //px

// table
$table-font-size-main: 13; // px
$table-font-size-header: 16; // px
$table-filter-input-size: 350; // px
$table-font-color-hover: $app-color-main;
$table-color-main: $app-color-main;
$table-bg-color-main: #fff;
$table-font-color: #7d7d7d;
$table-color-main-striped: #fbfbfb;
$table-color-main-striped-hover: #c7c7c7;

// sidebar
$sidebar-width-main: 200;
$sidebar-font-size-main: $app-font-size-main;
$sidebar-font-size-name: 12;
$sidebar-font-size-header: 16;
$sidebar-bg-color-main: $app-color-main;
$sidebar-bg-color-user: darken($app-color-main, 5%);
$sidebar-bg-color-hover: lighten($app-color-main, 10%);
$sidebar-color-main: $app-color-white;

//icon
$icon-width: 20; //px
$icon-height: 20; //px
$icon-height-email: 14.4; //px

// min-width
$bp_up: (
  'xs': 'screen and (min-width: 0px)',
  'sm': 'screen and (min-width: 360px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 992px)',
  'xl': 'screen and (min-width: 1366px)',
);

// max-width
$bp_down: (
  'xs': 'screen and (max-width: 359px)',
  'sm': 'screen and (max-width: 767px)',
  'md': 'screen and (max-width: 991px)',
  'lg': 'screen and (max-width: 1365px)',
  'xl': 'screen and (max-width: 10000px)',
);
