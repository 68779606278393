@import "../migration//styles/index.scss";
@import '~react-image-crop/dist/ReactCrop.css';
@import '~cropperjs/dist/cropper.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import './values';
@import './override/index';
@import './mixin';
@import './application';
@import './components/index.scss';
@import './modal.scss';
