.app_fish_card {
  height: auto;

  @include mq_up('lg') {
    height: 400px !important;
  }
  @include mq_down('md') {
    height: 45vw !important;
  }
  @include mq_down('sm') {
    height: 65vw !important;
  }
  @media screen and (max-width: 550px) {
    height: 115vw !important;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1);

    @include mq_down() {
      box-shadow: none;
    }
  }

  &:hover {
    transform: scale3d(1, 1, 1);

    &::after {
      opacity: 1;
    }
  }

  .fish_card {
    // height: 378px;
    border: 1px #dedede solid;
    border-radius: 0;
    box-shadow: none;

    @include mq_down() {
      // min-height: 455px;
    }
  }

  .fish_card {
    display: flex;

    .blueline {
      height: 4px;
      background-color: #3477cb;
      border-radius: 0 !important;
    }

    .redline {
      height: 4px;
      background-color: #f00;
      border-radius: 0 !important;
    }

    .image_content {
      position: relative;
      flex: 0.8;

      @include mq_up('lg') {
        min-height: 175px;
      }

      @include mq_down() {
        min-height: 135px;
      }

      .w__image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }

    .content {
      flex: 0.2;
      border-top: none;

      .description {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 8px 16px 0px;

        .sell_name {
          display: -webkit-box !important;
          width: 100%;
          height: 40px;
          overflow: hidden;
          line-height: 1.2 !important;

          color: $app-color-black;
          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-break: break-all;
        }

        .exhibition_time {
          color: $app-color-black;
        }
      }
    }
  }

  .sold_label,
  .onsale_label,
  .expired_label,
  .faild_label {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px !important;
    height: 24px !important;
    padding: 1px 0;
    font-size: 0.7rem;
    color: #fff;

    @include mq_down('sm') {
      width: 26vw;
      height: 8vw;
    }

    @media screen and (max-width: 450px) {
      font-size: 0.7rem;
    }

    @media screen and (max-width: 659px) and (min-width: 451px) {
      font-size: 0.7rem;
    }

    @media screen and (max-width: 767px) and (min-width: 630px) {
      font-size: 0.7rem;
    }
  }

  .addition {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px !important;
    height: 24px !important;
    padding: 1px 0;
    font-size: 0.7rem;
    color: #fff;

    @include mq_down('sm') {
      width: 26vw;
      height: 8vw;
    }

    @media screen and (max-width: 450px) {
      font-size: 0.7rem;
    }

    @media screen and (max-width: 659px) and (min-width: 451px) {
      font-size: 0.7rem;
    }

    @media screen and (max-width: 767px) and (min-width: 630px) {
      font-size: 0.7rem;
    }
  }

  .fish_thumbnail {
    object-fit: contain;
    width: auto;
    height: 100%;
    font-family: 'object-fit: contain;';
  }

  .sold_label {
    background-color: #ef6266;
  }

  .faild_label {
    background-color: #ffc107;
  }

  .expired_label {
    background-color: #aaa;
  }

  .onsale_label {
    background-color: #3477cb;
  }

  .addition {
    background-color: #59b9c6;
  }

  .sell_name {
    padding-bottom: 8px;
    font-size: 16px;
  }

  table {
    width: 100%;
    table-layout: fixed;
  }

  .w__item {
    @media screen and (max-width: 390px) and (min-width: 360px) {
      font-size: 12px !important;
    }

    @media screen and (max-width: 768px) and (min-width: 391px) {
      font-size: 12px !important;
    }

    @media screen and (max-width: 990px) {
      font-size: 0.5rem !important;
    }
  }

  .w__item-name {
    font-size: 10px;
  }

  .w__item-detail {
    font-size: 12px;
  }

  .w__item-seller-name {
    width: 50%;
    overflow: hidden;
    font-size: 12px;
    font-weight: 600;
    color: #3a3a3a;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .w__item-is-nature {
    width: 50%;
    font-size: 12px;
    font-weight: 600;
    color: #3a3a3a;
  }

  .w__item-is-farmer {
    width: 50%;
    font-size: 12px;
    font-weight: 600;
    color: #3a3a3a;
  }

  .w__item-shipping-free {
    width: 50%;
    font-size: 12px;
    font-weight: 600;
    color: #3a3a3a;
  }

  .w__item-shipping-boughter-pay {
    width: 50%;
    font-size: 12px;
    font-weight: 600;
    color: #3a3a3a;
  }

  .w__item-shipping-seller-pay {
    width: 50%;
    font-size: 12px;
    font-weight: 600;
    color: #3a3a3a;
  }

  .w__price_detail {
    font-size: 14px !important;
    font-weight: bold;

    @media screen and (max-width: 390px) and (min-width: 360px) {
      font-size: 12px !important;
    }

    @media screen and (max-width: 768px) and (min-width: 391px) {
      font-size: 12px !important;
    }

    @media screen and (max-width: 990px) {
      font-size: 14px !important;
    }
  }

  .red {
    color: $emphasis-red;
  }

  .exhibition_time {
    padding-bottom: 8px;
    font-size: 14px;

    @media screen and (max-width: 390px) and (min-width: 360px) {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 990px) {
  .app_fish_card {
    width: 30vw !important;
  }
}

@media only screen and (max-width: 767px) {
  .app_fish_card {
    width: 48vw !important;
  }
}

@media only screen and (max-width: 414px) {
  .app_fish_card {
    .fish_card {
      .content {
        .description {
          padding: 8px;
          padding-right: 0px;
        }
      }
    }

    .sell_name {
      padding-bottom: 4px;
      font-size: 16px;
    }

    .exhibition_time {
      padding-bottom: 4px;
    }
  }
}
