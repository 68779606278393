.a__fishe-all {
  &__main {
    width: 100%;
  }

  .app_titlebar {
    .ui.header {
      margin: 0;
      font-size: 26px;
      line-height: $app-line-height-main;
      color: $app-color-black;
    }

    @include mq_down('sm') {
      padding: 32px 0;
    }
  }

  .sales_list.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    min-height: 290px;

    .fished_loading {
      min-height: 260px;
      margin-top: 25px;
      margin-bottom: 5%;
    }

    .hidden_loading_now {
      display: none;
    }

    .app_pagination {
      margin: 32px 0 12px;
    }
  }

  .b__fishes-content__loading {
    width: 100%;
    padding: 25vh 0;
  }

  .b__fishes-content__empty {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 15vh 0;
  }
}
