.app_userprofile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: #{$body-bottom}px;
  background-color: #fff;

  .app_profile_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: #{$app_profile_header_margin_bottom}px;

    @include mq_down('sm') {
      justify-content: center;
    }
  }

  .userprofile_introduction_area {
    width: 100%;
    padding: 0 #{$userprofile_introduction_area_side}px;
    background-color: #fff;
  }

  .userprofile_introduction {
    display: inline-block;
    width: 720px;

    font-size: #{$userprofile_introduction_size}px;
    line-height: $app-line-height-main;
    color: $app-color-black;
    text-align: left;
    word-wrap: break-word;
    white-space: pre-wrap;
    content: '\A';
    border-radius: 5px;

    .noIntroduction {
      text-align: center;
    }
  }

  .ui.bottom.attached.segment.active.tab.userprofile_fish_list {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 120px;
  }

  .ui.attached.segment {
    width: 720px;
    padding: 0;
    margin: 0;
    border: none;
  }

  .userprofile_fish_title {
    display: none;
    margin: 18px auto 24px;
    font-family: HiraKakuPro-W6;
    font-size: 18px;
    text-align: center;
  }

  .ui.segment[class*='bottom attached'] {
    margin-bottom: 0;
  }

  .ui.yellow.button,
  .ui.yellow.buttons .button {
    height: 32px;
    padding: 10px;
  }

  .no-fish-sale {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }

  .no-review {
    text-align: center;
  }

  section {
    width: 720px;

    .tabpage {
      overflow: hidden;

      .ui.attached.tabular.menu {
        border: none;

        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25%;
          font-weight: 600;
          background-color: #f0f0f0;
          border: none;
        }

        .item.active {
          background-color: white;
        }
      }

      .ui.tab {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 40px 0 20px;
        border: none;
      }
    }

    .gotop {
      display: flex;
      justify-content: center;
      order: 3;
      width: 100%;
      padding: 64px 0 32px;

      a {
        color: black;
      }

      a:hover {
        text-decoration: underline;
        opacity: 0.8;
      }
    }

    .app_sales_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .app_fish_card {
        width: 220px;
        height: 330px;
        margin: 8px;
      }
    }

    .app_pagination {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-left: 0;
      margin: 14px auto;
    }

    @include mq_down('sm') {
      .user_review {
        .reviewList {
          .ui.divided.items {
            .item {
              flex-direction: row;
              .content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 0 0 0 14px;
                .meta {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    section {
      width: 600px;

      .userprofile_introduction {
        width: 600px;
      }

      .app_sales_list {
        .app_fish_card {
          width: 220px;
          height: 330px;
        }
      }
    }
  }

  @media screen and (max-width: 940px) {
    section {
      order: 1;
    }

    .app_sales_list.flex .app_fish_card {
      margin: 8px;
    }

    .gotop.mobile {
      display: flex;
      justify-content: center;
      order: 3;
      width: 100%;
      padding: 64px 0 32px;

      a {
        color: $app-color-black;
      }

      a:hover {
        color: #454545;
      }
    }
  }

  @media screen and (max-width: 710px) {
    padding-top: 16px;
    background-color: #fff;

    .app_profile_header {
      background-position: 70% 30%;
    }

    .view_all {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 256px;
      height: 32px;
      margin-top: 20px;
      color: #fff;
      background-color: #3477cb;
      border-radius: 5px;
      margin: {
        top: 30px;
        right: auto;
        bottom: 0;
        left: auto;
      }

      &:hover,
      &:focus {
        color: #fff;
        background-color: lighten(#3477cb, 2%);
      }

      &.yellow {
        background-color: #ffc107;
      }
    }

    .gotop {
      display: flex;
      justify-content: center;
      order: 3;
      width: 100%;
      padding: 64px 0 32px;

      a {
        color: $app-color-black;
      }

      a:hover {
        color: #454545;
      }
    }

    .userprofile_introduction_area {
      padding: 0 #{$userprofile_introduction_area_side_sm}px;
    }

    .userprofile_introduction {
      width: 100%;
    }

    .app_titlebar.flex .ui.header {
      font-size: 16px;
    }

    .app_sales_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .contents {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .app_titlebar {
        width: 100%;
      }
    }

    .app_sales_list.flex .app_fish_card {
      display: flex;
      width: 47.5%;
      margin: 2px;
    }

    .user_review {
      width: 100%;
      padding: 0 16px;
      .ui.divided.items {
        .item {
          .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0 0 0 14px;
            .meta {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
