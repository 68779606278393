.media {
  .media-header {
    margin: 30px;
    font-size: 26px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    .less_than_sm {
      display: none;
    }
  }
  .media-content {
    width: 80%;
    margin: auto;
    text-align: center;
    ul {
      padding: 0;
      list-style: none;
      li {
        display: inline;
        padding: 20px;
        margin: 0px 10px !important;
        text-align: center;
        img {
          align-items: center;
          justify-content: center;
          width: 150px;
          height: 75px;
          object-fit: contain;
        }
      }
    }
  }
}

@include mq_down('md') {
  .media {
    .media-header {
      margin: 30px;
      font-size: 26px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      .less_than_sm {
        display: none;
      }
    }
    .media-content {
      width: 100%;
      margin: auto;
      text-align: center;
      ul {
        width: 100%;
        padding: 0;
        margin: 0px;
        list-style: none;
        li {
          display: inline;
          padding: 0px;
          margin: 10px;
        }
      }
    }
  }
}
