.app__header {
  width: 100%;
  padding: 16px 60px;
  margin: 0 auto;
  border-bottom: 1px solid #f6f6f7;

  @media screen and (max-width: 991px) {
    padding-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
  }

  @media screen and (max-width: 387px) {
    padding-right: 16px;
    padding-left: 16px;
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media screen and (max-width: 360px) {
      width: 97%;
    }

    &.items {
      justify-content: flex-end;
      height: 32px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media screen and (max-width: 360px) {
      width: 97%;
    }
  }

  .ui {
    &.header-width {
      width: 90%;
      line-height: $app-line-height-main;

      @include mq_down() {
        width: 90%;
      }

      @include mq_down('sm') {
        width: 100%;
      }
    }

    & input:focus {
      border-color: rgba(#ffc107, 0.8);
      box-shadow: 0 0 0 2px rgba(#ffc107, 0.8);
    }

    & input:active {
      border-color: rgba(#ffc107, 0.8);
      box-shadow: 0 0 0 2px rgba(#ffc107, 0.8);
    }

    &.search-mode input {
      border-color: rgba(#ffc107, 0.8);
      box-shadow: 0 0 0 2px rgba(#ffc107, 0.8);
    }
  }

  .ui.image.logo {
    width: 140px;
    margin-right: 24px;

    @media screen and (max-width: 767px) {
      width: 37px;
    }
  }

  .link_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    margin-left: 20px;
    font-weight: 600;
    color: $app-color-black;

    &.notification {
      position: relative;
      display: flex;
      margin: 0 0 0 12px;

      & > span {
        display: inline-flex;
        width: 100%;

        &:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down('md') {
            text-decoration: none;
            opacity: 1;
          }
        }
      }

      .a__RedCircleBadge {
        &:hover {
          background-color: #ef6266;
        }
      }
    }

    span:hover {
      text-decoration: underline;
      opacity: 0.7;

      @include mq_down('md') {
        text-decoration: none;
        opacity: 1;
      }
    }

    &.before {
      margin: {
        right: 20px;
        left: 0;
      }
    }

    @include mq_down('sm') {
      margin-right: 0;
      margin-left: 5px !important;
    }
  }

  .signin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 32px;
    margin-right: 8px;
    font-weight: 600;
    color: #fff;
    background-color: #ffc107;
    border-radius: 5px;

    &:hover {
      background-color: lighten(#ffc107, 10%);
      transition: {
        duration: 0.4s;
      }

      @include mq_down('md') {
        background-color: #ffc107;
      }
    }
  }

  .signup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 118px;
    height: 32px;
    font-weight: 600;
    color: $app-color-black;
    border-radius: 5px;
    box-shadow: inset 0 0 0 1px #000;

    &:hover {
      background-color: #f4f4f4;
      transition: {
        duration: 0.4s;
      }

      @include mq_down('md') {
        background-color: #fff;
      }
    }
  }

  .iconwrapper {
    .magnifier {
      width: 24px;
      height: 24px;
    }
  }

  .mobile_only {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 60%;
  }

  .mypage_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;

    .iconwrapper:first-child {
      padding-right: 16px;
    }
  }

  @media only screen and (max-width: 387px) {
    .link_button {
      margin: {
        left: 12px;
      }

      &.before {
        margin: {
          right: 6px;
          left: 0;
        }
      }

      span {
        font-size: 12px;
      }
    }

    .signin {
      font-size: 12px;
      margin: {
        right: 4px;
      }
    }

    .signup {
      font-size: 12px;
    }
  }
}

.app_header.flex-beta {
  justify-content: flex-start;
  margin: {
    top: 16px;
  }

  .ui.image.logo {
    width: 140px;
    height: 40px;
  }
}

.app_header.flex-beta.sns {
  max-width: 100vw;
}

.app_header.isloading {
  min-height: 104px;

  &.flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.app__navbar {
  display: flex;

  .is__pc {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .is__mobile {
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
      text-align: center;

      span {
        display: inline-block;
      }
    }
  }
}
