.app_signin {
  @include grid-column-span(1, 16);
  @include grid-row-span(1, 16);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;

  .wrapper {
    width: 456px;
  }

  .app_titlebar {
    // β版終了後、コメントアウトを外す
    // width: 456px;
    height: auto;
    padding: 56px 0;
    background-color: white;

    .ui.header {
      font-size: 26px;
      color: $app-color-black;
    }
  }

  .or {
    display: flex;
    justify-content: center;
    width: 456px;
    padding: 24px 0 8px;
    font-size: 12px;
  }

  form.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    background-color: white;

    .sns_buttons.flex {
      display: flex;
      flex-wrap: wrap;
      width: 320px;

      .ui.button {
        width: 100%;
        height: 48px;
        color: $app-color-black;

        .w__sns_signin_content {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;

          .signin_fb {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .content.fb {
              margin-left: 8px;
            }
          }

          .signin_g {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .content.g {
              margin-left: 8px;
            }
          }
        }
      }

      .btn_fb {
        margin-bottom: 16px;
        background-color: $app-white-button !important;
        border: solid 1px $app-black-border;

        @include hover_button_opacity();

        &:hover {
          @include mq_down("md") {
            opacity: 1;
          }
        }
      }

      .btn_google {
        background-color: $app-white-button !important;
        border: solid 1px $app-black-border;
        @include hover_button_opacity();

        &:hover {
          @include mq_down("md") {
            opacity: 1;
          }
        }
      }

      .ui.button {
        padding: 0;
      }
    }
  }

  .liner.flex.bottom {
    padding: 16px 0 0;
  }

  .liner.flex {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-bottom: 24px;
    line-height: $app-line-height-main;
    color: $app-gray-border;

    .div_liner.flex {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 320px;
    }
  }

  .amplify-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .login_error_message {
      display: none;
      width: 320px;
      margin-bottom: 10px;
      font-size: 12px;
      color: #d62d2d;

      &.display_error {
        display: block !important;
      }
    }

    .ui.input {
      width: 320px;
      height: 48px;
      margin-top: 16px;
      margin-bottom: 0;
    }

    .link_forgotpassword,
    .signup-beta,
    .signin-beta {
      width: 320px;
      margin-top: 10px;
      margin-bottom: 24px;
      color: #4183c4;

      .ui.button {
        line-height: $app-line-height-main;
        color: #4183c4;
      }
      .register-text {
        color: $app-color-black;
      }
    }
    .signup-beta {
      padding-top: 24px;
      margin: 0;
    }

    .ui.button.submit_login {
      width: 320px;
      height: 48px;
      margin-top: 24px;
      line-height: $app-line-height-main;
      color: white;
      background-color: #ffc107;

      @include hover_button_opacity();

      &:hover {
        opacity: 0.8;
        transition-duration: 0.4s;
      }
    }
    .link_forgotpassword {
      a:hover {
        text-decoration: underline;
        opacity: 0.7;
      }
    }
    .signup-beta {
      a {
        font-weight: bold;
      }
      a:hover {
        text-decoration: underline;
        opacity: 0.7;
      }
    }
  }

  footer.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 320px;
    padding: 24px 0;
    margin: 0 auto;
    margin-bottom: 80px;
    border-radius: 4px;

    .attention_signup {
      width: 100%;
      padding-bottom: 16px;
      color: $app-color-black;

      .signupLink {
        font-weight: bold;

        &:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down("md") {
            color: $hover-blue-sm;
            opacity: 1;
          }
        }
      }
    }

    .return_top {
      width: 320px;
      margin-top: 44px;
      text-align: center;
    }

    .gotop {
      color: black;
      text-align: center;

      &:visited,
      &:active {
        color: black;
      }

      .disabled_button {
        padding: 0;
        font-weight: normal;
        color: #000;
        cursor: pointer;
        background-color: transparent;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @include mq_down("sm") {
    min-height: 0;

    /**
     * Under iPad
     */
    background-image: none !important;

    .wrapper {
      width: 100%;
    }

    .app_titlebar {
      width: 100%;
      height: auto;
      padding: 32px 0;

      .ui.header {
        font-size: 16px;
      }
    }

    form.flex {
      flex-flow: column nowrap;
      align-items: center;
      padding: 0 16px;

      .sns_buttons.flex {
        width: 100%;

        .w__sns_signin_content {
          justify-content: center;

          .ui.image {
            margin-left: 0;
          }

          .content {
            width: auto;
            text-align: center;
          }
        }
      }
    }

    .input-field {
      width: 100% !important;
    }

    .liner.flex.bottom {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      .div_liner.flex {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
      }

      span {
        display: flex;
        align-items: center;
        width: 50px;
        height: 12px;
        padding: 0 7px;
        font-size: 12px;
      }
    }

    .amplify-container {
      display: block;
      width: 100%;

      .ui.button.submit_login {
        width: 100%;
      }

      .link_forgotpassword,
      .signup-beta {
        width: 100%;
        a:hover {
          opacity: 0.7;
        }
      }
      a:hover {
        opacity: 0.7;
      }
    }

    footer.flex {
      flex-flow: column nowrap;
      align-items: center;
      width: 100%;
      padding: 24px 16px;

      a {
        width: 100%;
      }
    }
  }
}

.app_signin {
  .w__sns_signin_content {
    display: flex;
    align-items: center;
    height: 48px;

    .ui.image {
      &.fb {
        width: #{$icon-width}px;
        height: #{$icon-height}px;
        border-radius: 4px;
      }

      &.g {
        width: #{$icon-width}px;
        height: #{$icon-height}px;
      }

      margin: {
        left: 14px;
      }
    }
  }

  .b__footer {
    ul {
      display: flex;
      justify-content: center;
      width: 100%;
      list-style: none;
      padding: {
        left: 0;
      }
      margin: {
        bottom: 24px;
      }

      @include mq_down("sm") {
        flex-direction: column;
        align-items: center;
      }

      li {
        padding: {
          right: 1rem;
        }
        &:last-of-type {
          padding: {
            right: 0;
          }
        }

        @include mq_down("sm") {
          padding: {
            right: 0;
            bottom: 8px;
            left: 0;
          }
        }

        a {
          color: #4183c4;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            opacity: 0.7;
          }
        }
      }
    }
  }
}
