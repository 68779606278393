html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 400;
}

body {
  position: relative;
  height: max-content;
  overflow-x: hidden;
  color: $app-color-black;
}

main {
  display: block;
}

.___main {
  height: max-content;
}

.app_disabled {
  pointer-events: none;
}

.app_root {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
}

.app_root > div {
  grid-row: 1;
  grid-column: 1;
}

.app-enter {
  z-index: 0;
  grid-row: 1;
  grid-column: 1;
  opacity: 0.01;
}

.app-enter.app-enter-active {
  opacity: 1;
  transition: opacity 650ms ease-in;
}

.app-exit {
  z-index: 1;
  grid-row: 1;
  grid-column: 1;
  opacity: 1;
}

.app-exit.app-exit-active {
  opacity: 0.01;
  transition: opacity 320ms ease-in;
}

.app_sticky.flex {
  position: fixed;
  right: 36px;
  bottom: 36px;
  z-index: 100;

  @include mq_down('sm') {
    right: 12px;
    bottom: 16px;
  }
}

.redux_form {
  &.error {
    width: 100%;
    color: #bd081c;
  }
}

.app_fish_search_form.custom.flex .btn_fish_search.flex .ui.button {
  color: white !important;
  background-color: #ffc107 !important;
}

.hidden {
  display: none;
}

.c__hidden {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .app_sticky.flex .app_sale_button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80px;
    height: 80px;
  }
}

.background-white {
  background-color: white !important;
}

.breadcrumbs {
  padding: 10px 0 0;
  padding-left: 6%;

  .decoration {
    padding: 0 8px;
  }

  .is_match_url {
    text-decoration-line: underline;
  }

  .link:hover {
    text-decoration: underline;
    opacity: 0.7;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.return_top {
  margin: 10px 0;

  .gotop {
    &:hover {
      text-decoration: underline;
      opacity: 0.7;

      @include mq_down('sm') {
        text-decoration: none;
        opacity: 1;
      }
    }
  }
}

@include responsive('.hidden_more', $bp_up) {
  display: none;
}

@include responsive('.hidden_under') {
  display: none;
}

.app_hidden {
  display: none;
}

.notify_info {
  font-size: 18px;
  font-weight: bold;
  color: #126ada;
  text-align: center;
}

.notify_error {
  font-size: 18px;
  font-weight: bold;
  color: #ea2a30;
  text-align: center;
}

.rc-notification-notice {
  .rc-notification-notice-close {
    top: 7px !important;
  }
}

.c__center2center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui.medium.image,
.ui.medium.images .image,
.ui.medium.images img,
.ui.medium.images svg {
  height: 300px;
  border-radius: 4px;

  &.sell {
    height: 300px;
    border-radius: 4px;
  }
}

textarea:focus {
  border: 1px rgba(133, 183, 217, 1) solid !important;
  outline: none;
}

.gc__pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: {
    top: 24px;
  }

  @include mq_down() {
    padding-bottom: 24px;
  }

  .rc-pagination {
    .rc-pagination-prev,
    .rc-pagination-next {
      outline: none;
    }

    .rc-pagination-jump-prev,
    .rc-pagination-jump-next {
      &:hover::after {
        color: #ffc107;

        @include mq_down('sm') {
          color: #d9d9d9;
        }
      }

      &:focus {
        outline: none;
      }
    }

    .rc-pagination-prev::after,
    .rc-pagination-next::after {
      color: #ffc107;
      outline: none;
    }

    .rc-pagination-item {
      outline: none;

      &:hover {
        border-color: #ffc107;
        a {
          color: #ffc107;
        }
      }
    }

    .rc-pagination-item-active {
      background-color: #ffc107;
      border-color: #ffc107;

      &:hover {
        a {
          color: #fff;
        }
      }
    }
  }
}
