.app_credit-register.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;

  .available_card_notice {
    text-align: center;
  }

  .wrapper {
    width: 440px;
  }

  .app_titlebar.flex .ui.header {
    font-size: 26px;
  }

  .stripe-elements.elements {
    button {
      padding-bottom: 0;
      font-size: 1rem;
      color: #fff;
      background-color: #ffc107;
      border-radius: 5px;

      @include hover_button_opacity();
    }
  }

  .contact_form_title {
    display: flex;
    width: 600px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }

  .content {
    width: 600px;
    height: 118px;
    resize: none;
    border: solid 1px #ccc;
    border-radius: 5px;
  }

  .app_titlebar {
    width: 600px;
    height: 86px;
    margin: 0;
    background-color: white;
  }

  form.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 440px;
    padding: 32px 0;
    margin-bottom: 8px;
    background-color: white;
  }

  .amplify-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 600px;

    .ui.input {
      width: 600px;
      height: 36px;
      margin-bottom: 8px;
    }

    .ui.button.submit_login {
      width: 360px;
      height: 48px;
      margin-top: 24px;
      color: white;
      background-color: #ffc107;
    }
  }

  footer.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 440px;
    padding: 32px 0;
    margin-bottom: 8px;
    background-color: #fff;

    .gotop {
      width: 600px;
      color: $app-color-black;
      text-align: center;
    }

    a {
      &:hover {
        text-decoration: underline;
        opacity: 0.8;
      }
    }
  }

  .app_form_label_icon {
    margin: 2px 0 0 6px;
  }

  .field-group {
    margin-bottom: 8px;
  }

  @media screen and (max-width: 1024px) {
    form.flex {
      width: 320px;
      padding: 0;
    }

    .app_titlebar {
      width: 100%;
    }

    .amplify-container {
      width: 320px;

      .ui.input {
        width: 320px;
      }
    }

    .content {
      width: 320px;
    }

    .contact_form_title {
      width: 100%;
    }

    footer.flex {
      width: 100%;
    }

    .submit_login {
    }
  }

  @media screen and (max-width: 767px) {
    /**
     * Under iPad
     */
    background-color: #fff;

    .wrapper {
      width: 100%;
      padding: 16px;
    }

    .stripe-elements.elements {
      padding: 35px 0;

      .fieldset {
        margin: 0 0 15px;
      }

      button {
        width: 100%;
        margin: 0;
      }
    }

    form.flex {
      width: 100%;
    }

    .app_titlebar {
      width: 100%;
      height: 82px;

      .ui.header {
        font-size: 16px;
      }
    }

    .content {
      width: 380px;
      height: 118px;
    }

    .amplify-container {
      width: 100%;

      footer.flex {
        width: 100%;
      }

      .ui.input {
        width: 380px;
      }
    }
  }

  @media screen and (max-width: 414px) {
    /**
     * Under iPad
     */
    background-color: #fff;

    img {
      width: 350px;
    }

    .wrapper {
      width: 100%;
    }

    form.flex {
      width: 100%;
    }

    .app_titlebar {
      width: 100%;
      height: 82px;

      .ui.header {
        font-size: 16px;
      }
    }

    .content {
      width: 300px;
      height: 118px;
    }

    .amplify-container {
      width: 100%;

      footer.flex {
        width: 100%;
      }

      .ui.input {
        width: 300px;
      }
    }
  }
}
