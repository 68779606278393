.app__page3 {
  .b__how_to {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 1240px) {
      flex-direction: column;
    }
  }
  .w__header_label {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 52px;
      height: 11px;
      padding-right: 8px;
    }

    h3 {
      margin: 0;
      font-size: 26px;
      line-height: $app-line-height-main;
      color: $app-color-black;
    }
    padding: {
      top: 56px;
      bottom: 56px;
    }
  }

  .w__card_lists_wrapper {
    display: flex;
    justify-content: center;
    padding: {
      top: 0;
      right: 16px;
      bottom: #{$body-bottom}px;
      left: 16px;
    }
  }
  .w__warning-poison {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 560px;
    @media only screen and (max-width: 630px) {
      max-width: 598px;
    }
  }
  .w__movie,
  .w__movie-food-poisoning {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: {
      right: 28px;
      bottom: 100px;
      left: 28px;
    }

    .w__iframe {
      width: 560px;
      height: 310px;

      @media only screen and (max-width: 630px) {
        width: 100%;
        height: 288px;
      }

      @media only screen and (max-width: 417px) {
        height: 215px;
      }
    }

    .w__iframe-movie-food-poisoning {
      width: 567px;
      height: 456px;
      @media only screen and (max-width: 1240px) {
        width: 560px;
        height: 310px;
      }
      @media only screen and (max-width: 630px) {
        width: 100%;
        height: 288px;
      }

      @media only screen and (max-width: 417px) {
        height: 215px;
      }
    }
    iframe {
      width: 100%;
      height: 100%;
    }
    @include mq_down('sm') {
        padding: 0 16px 100px 16px;
    }
  }

  .w__movie-food-poisoning {
    .w__header-food-poisoning {
      h3 {
        font-size: 26px;
        @include mq_down('sm') {
          font-size: 22px;
        }
      }
      @include mq_down('sm') {
        padding: 32px 0;
      }
      padding: 56px 0;
    }
    .w__warning-links {
      width: 100%;
      padding-bottom: 56px;
    }
    .w__avoiding-food-poisoning,
    .w__food-parasites {
      font-size: 16px;
      p {
        margin: 0;
        font-weight: 600;
      }
      a {
        color: #1e70bf;
        word-break: break-all;
        cursor: pointer;
      }
    }
    .w__food-parasites {
      padding-top: 32px;
    }
  }

  .w__card_lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 744px;
  }

  .w__card:nth-child(1) {
    margin-bottom: 32px;
  }

  .w__card:nth-child(2) {
    margin-bottom: 32px;
  }

  .w__card:nth-child(even) {
    margin-left: 32px;
  }

  .w__card {
    width: 336px;
    height: 162px;
    padding: 32px;
    background-color: #fafafa;
    border: solid 1px #aaa;
    border-radius: 3px;
  }

  .w__card_title {
    padding-bottom: 8px;
    font-family: HiraKakuProN-W6;
    font-size: 18px;
    color: $app-color-black;
  }

  .w__card_message {
    font-family: HiraKakuProN-W3;
    font-size: 18px;
    line-height: 1.33;
    line-height: $app-line-height-main;
    color: $app-color-black;
    word-wrap: break-word;
  }

  .w__return_top {
    display: flex;
    justify-content: center;
    padding-top: 64px;
    padding-bottom: 32px;
  }

  a {
    color: $app-color-black;
  }

  a:hover {
    text-decoration: underline;
    opacity: 0.8;

    @include mq_down("sm") {
      text-decoration: none;
      opacity: 1;
    }
  }

  @include mq_down("sm") {
    .w__header_label {
      h3 {
        font-size: 22px;
      }

      padding: {
        top: 32px;
        bottom: 32px;
      }
    }

    .w__card_lists {
      flex-direction: column;
      width: 100%;

      .w__card {
        width: 100%;
        margin: {
          left: 0 !important;
        }

        .w__card_title {
          font-size: 16px;
        }

        .w__card_message {
          font-size: 16px;
        }
      }

      .w__card:not(:last-child) {
        margin: {
          bottom: 32px !important;
        }
      }
    }

    .w__return_top {
      padding-top: 56px;
    }
  }
}
