.app__notfound {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: white;

  .w__content {
    display: flex;
    justify-content: center;

    @include mq_down("sm") {
      flex-direction: column-reverse;
      align-items: center;
      padding: {
        right: 10px;
        left: 10px;
      }
    }

    .b__message {
      display: inline-block;
      max-width: 360px;

      .w__message {
        font-size: 18px;
        line-height: 1.78;

        @include mq_down("sm") {
          font-size: 16px;
          line-height: 1.75;
        }
      }

      .turn_landing.flex {
        margin-top: 16px;

        @include mq_down("sm") {
          display: flex;
          justify-content: center;
        }

        a:hover {
          text-decoration: underline;
          opacity: 0.6;
        }

        .hidden_less_sm {
          @include mq_down("sm") {
            display: none;
          }
        }

        span {
          color: #aaa;
        }
      }
    }

    .ui.image {
      display: inline-block;
      width: 132px;
      height: 140px;
      margin-left: 40px;

      @include mq_down("sm") {
        margin-bottom: 16px;
        margin-left: 0;
      }
    }
  }
}
