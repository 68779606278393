.app__modal.xs {
  width: 496px !important;
  padding: 0 24px !important;
  margin: 0 12px;
  overflow: hidden;

  .w__close {
    left: -8px !important;
    width: 14px;
    height: 14px;
    margin-top: 3px;
  }

  .b__body {
    padding: 12px 0 20px;
  }

  .w__review-modal {
    .w__caption {
      margin-top: 5px;
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: bold;
      color: black;
      text-align: center;
    }

    .w__caption_closed {
      margin: 30px 0;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
    }

    .w__form {
      margin-bottom: 20px;
      text-align: right;
      .reputation_point {
        margin-bottom: 15px;
        font-size: 20px;
        text-align: start;
      }
      img {
        width: 190px;
        height: 50px;
        margin: 40px auto;
      }
    }

    .p-rating {
      height: 19px;
    }

    .review_input {
      .review_input_caption {
        margin-bottom: 5px;
        text-align: start;
      }
      textarea {
        width: 100%;
        height: 160px;
        padding: 17px 8px;
        resize: none;
        border: solid 1px #cccccc;
        border-radius: 5px;
      }
    }
    .review_count_under {
      margin-bottom: 20px;
    }

    .w__button {
      button {
        width: 320px;
        height: 40px;
        color: $app-search_form_background;
        background-color: $app-yellow-button;
      }
    }
    .w__button_closed {
      span {
        font-size: 16px;
        color: $hover-blue-sm;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down() {
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }
  }

  .w__review-modal-thanks {
    .w__main {
      height: 282px;
      padding: 20px 0;
    }
    .w__caption {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: bold;
      color: black;
      text-align: center;
    }

    .w__caption_closed {
      margin: 10px 0;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
    }

    .w__form {
      margin-bottom: 20px;
      text-align: center;
      img {
        width: 190px;
        height: 50px;
        margin: 40px auto;
      }
      .w__button_closed {
        margin-top: 48px;
      }
    }

    .p-rating {
      height: 19px;
    }

    .review_input {
      margin-bottom: 20px;

      textarea {
        width: 100%;
        height: 160px;
        padding: 17px 8px;
        resize: none;
        border: solid 1px #cccccc;
        border-radius: 5px;
      }
    }

    .w__button {
      button {
        width: 320px;
        height: 40px;
        color: $app-search_form_background;
        background-color: $app-yellow-button;
      }
    }
    .w__button_closed {
      span {
        font-size: 16px;
        color: $hover-blue-sm;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down() {
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }
  }

  .w__button {
    padding-top: 0;
  }
}
