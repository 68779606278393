.app_fish_search_form {
  width: 100%;
  background-color: $app-search_form_background;

  .ui.header {
    width: 100%;
    padding: #{$ui-header-top}px 0;
    font-size: 32px;
    color: $app-color-black;
    text-align: center;
  }

  .dropdowns.flex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: calc(100% - 288px);
    @include mq_down('md') {
      width: 100%;
    }

    .field-group {
      flex: 1;
      margin: {
        right: 8px;
      }

      .ui.dropdown.sorts_company {
        width: 280px;
        margin-left: auto !important;

        @include mq_down('md') {
          width: 100%;
          margin-left: initial;
        }
      }
    }

    .ui.dropdown {
      width: 100%;
      height: 32px;
      padding-left: 15px !important;

      .text {
        font-size: #{$dropdown-input-size}px;
        color: $app-color-black;
        cursor: pointer;

        @include mq_down('md') {
          font-size: #{$app-font-size-main}px;
        }
      }

      input {
        padding: 0;
        cursor: pointer;
      }

      &:first-child {
        margin-right: 16px;
      }
    }
    @include mq_down('md') {
      display: flex;
      flex-direction: column;
    }
  }

  .btn_fish_search.flex {
    display: flex;
    justify-content: center;

    .ui.button {
      width: 184px;
      height: 32px;
      margin-left: #{$ui-button-left}px;
      font-size: #{$app-font-size-main}px;
      color: $app-white-button;
      background-color: $app-yellow-button;

      @include hover_button_opacity;

      @include mq_down('md') {
        height: #{$ui-button-height-md}px;
        margin-top: #{$ui-button-md-top}px;
        margin-left: 0;
        font-size: #{$app-font-size-main}px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.app_fish_search_form.custom.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  background-color: white;

  .dropdowns.flex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    height: 32px;
    padding-bottom: 0;

    .ui.dropdown {
      flex: 1;
      height: 32px;
      padding: #{$ui-dropdown}px;
      margin-right: 16px;

      .icon {
        padding: 8px;
      }

      &.prefecture {
        .visible.menu.transition {
          max-height: 266px !important;
        }
      }
    }
  }

  .input-field {
    &.category {
      .visible.menu.transition {
        max-height: 266px !important;
      }
    }
  }

  .btn_fish_search.flex {
    display: flex;
    justify-content: center;
    padding-bottom: 0;

    .ui.button {
      width: 184px;
      height: 32px;
      padding: 4px;
      background-color: #ccc;
    }
  }

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
    height: auto;

    .dropdowns.flex {
      width: 800px;
      height: auto;
      margin-bottom: 12px;

      .ui.dropdown {
        width: 300px;
        margin-right: 0;
        margin-bottom: 8px;
      }
    }

    .field-group,
    .btn_fish_search {
      width: 100%;

      @include center2center();
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;

    .btn_fish_search.flex {
      .ui.button {
        height: 48px;
      }
    }

    .dropdowns.flex {
      .ui.dropdown {
        width: 100%;
      }
    }

    .btn_fish_search.flex {
      .ui.button {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 560px) {
    .btn_fish_search.flex {
      .ui.button {
        height: 48px;
      }
    }
  }
}
