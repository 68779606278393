.a__introduction_howto_sales_account {
  display: flex;
  justify-content: center;
  .w__introduction_howto_sales_account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 640px;
    margin-bottom: 20px;

    header {
      padding: 56px 0;
      font-size: 26px;
      font-weight: bold;

      text-align: center;
      span {
        display: none;
      }

      @include mq_down('sm') {
        padding: 32px 0;
        font-size: 22px;
        span {
          display: inline;
          line-height: 1.5;
        }
      }
    }

    .explain {
      text-align: justify;
      text-justify: inter-ideograph;
      .stripe_information,
      .stripe_support {
        margin-top: 36px;
        font-size: 18px;
        font-weight: bold;

        a {
          font-size: 14px;
          font-weight: normal;
        }
        a:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down() {
            color: $hover-blue-sm;
            text-decoration: none;
            opacity: 1;
          }
        }
      }
      .introduction_example {
        margin: 36px 0;
        img {
          width: 100%;
        }
      }
    }
    .answer_example {
      margin-top: 36px;

      img {
        width: 100%;
        margin-bottom: 80px;
      }
    }

    @include mq_down('sm') {
      width: 100%;
      padding: 0 16px;
    }
  }
}
