.b__fishes__tab {
  margin: 0 0.5rem 1.5rem;

  @media screen and (max-width: 468px) {
    margin: 0 0 1.5rem;
  }

  .ui.buttons {
    width: 100%;
    padding: 0 4px;
  }

  button {
    width: 30%;

    &.ui.button {
      border: 1px solid #ccc;
      border-right: none;

      &:first-child {
        border-left: 1px solid #ccc;
      }

      &:last-child {
        border-right: 1px solid #ccc;
      }
    }

    &:not(.positive) {
      background: #fff;
    }

    &:not(.positive):hover {
      background: #fff;
    }
  }
}
