.a__introduction-card {
  display: flex;
  justify-content: center;

  .b__main {
    width: 640px;
    padding-bottom: #{$body-bottom}px;
  }

  .w__title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 56px 0;

    h3 {
      padding: 0 8px;
      margin: 0;
      font-size: 26px;
      line-height: $app-line-height-main;
      color: $app-color-black;
    }

    img {
      width: 52px;
      height: 11px;
    }
  }

  .b__section {
    .w__caption {
      margin: {
        bottom: 16px;
      }

      h3 {
        font-size: 16px;
        line-height: $app-line-height-main;
        color: $app-color-black;
        margin: {
          top: 0;
          bottom: 8px;
        }
      }

      div {
        width: 100%;
        height: 1px;
        background: #d8d8d8;
      }
    }

    .w__description {
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
      color: $app-color-black;
    }

    .w__caution {
      font-size: 12px;
      line-height: $app-line-height-main;
      color: $app-color-black;
      margin: {
        bottom: 32.4px;
      }
    }

    .w__kind {
      p {
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
        color: $app-color-black;
      }
    }

    .w__card-img {
      margin: {
        top: 16px;
        right: auto;
        bottom: 34px;
        left: auto;
      }

      max-width: 417px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .w__go-top {
    text-align: center;
    padding: {
      top: 64px;
      bottom: 32px;
    }

    a {
      font-size: 14px;
      color: $app-color-black;

      &:hover {
        text-decoration: underline;
        opacity: 0.7;

        @include mq_down('sm') {
          text-decoration: none;
          opacity: 1;
        }
      }
    }
  }

  @include mq_down('sm') {
    padding: 0 16px;

    .b__main {
      width: 100%;
    }

    .w__title {
      padding: {
        top: 32px;
        bottom: 32px;
      }

      h3 {
        font-size: 22px !important;
      }
    }

    .b__section {
      .w__description {
        font-size: 14px;
        line-height: 1.37;
      }

      .w__kind {
        p {
          font-size: 14px;
        }
      }
    }
  }
}
