.common_sales_list {
  .app_sales_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  div {
    color: $app-color-black;
  }

  .app_sales_list.flex .app_fish_card {
    margin: 8px;
  }

  .fish_card {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: center;
  }

  .app_pagination {
    &.flex {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0;
      margin: 32px 0 52px;
    }

    li.item {
      display: none;
    }

    .first {
      display: none;
    }

    .last {
      display: none;
    }
  }

  @media only screen and (max-width: 871px) {
    .app_sales_list.flex .app_fish_card {
      display: flex;
      justify-content: space-around;
      width: 27vw;
      height: 100%;

      .fish_thumbnail {
        width: 100%;
        height: 27vw;
      }
    }

    .app_fish_card.grid .sell_name {
      font-size: 1em;
    }
  }

  @include mq_down("sm") {
    .app_sales_list.flex .app_fish_card {
      width: 48vw !important;
      margin: #{$card_margin_sm}px;

      .fish_thumbnail {
        width: 100%;
        height: 48vw;
      }
    }

    .app_pagination.flex {
      padding: 0 1%;

      .ui.button {
        padding: 0.78571429em 0.835714em;
        padding: 0.78571429em 1.5em;
        font-size: 14px;
      }

      .previous {
        margin-right: 8px;
      }

      .next {
        margin-left: 40px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .app_sales_list.flex .app_fish_card {
      width: 48%;
    }
  }
}
