.row {
  margin: 0;
}

.ui.grid {
  margin: 0;

  .row {
    padding: 0;
  }

  .row > .column {
    padding: 0;
  }
}

.ui.grid > * {
  padding: 0;
  margin: 0;
}

.ui.card > .content,
.ui.cards > .card > .content {
  padding: 0;
}

i.icon,
i.icons {
  margin: 0;
}

.ui.fluid.button {
  padding: 0;
  margin: 0;
}

.ui.grid + .grid {
  padding: 0;
  margin: 0;
}

.ui.header {
  padding: 0;
  margin: 0;
}

.ui.button {
  margin: 0;
}

.ui.list,
ol.ui.list,
ul.ui.list {
  margin: 0;
}

.ui.selection.dropdown {
  min-height: 0;
}

.ui.labeled.input > .label:not(.corner) {
  padding: 0;
}

@import './../values';

// positive
.positive {
  background: {
    color: $app-yellow-button !important;
  }

  &:hover {
    background: {
      color: darken($app-yellow-button, 5%) !important;
    }

    transition-duration: 0.5s;
  }

  &:active {
    background: {
      color: darken($app-yellow-button, 5%) !important;
    }

    transition-duration: 0.5s;
  }

  &:focus {
    background: {
      color: darken($app-yellow-button, 5%) !important;
    }

    transition-duration: 0.5s;
  }
}

.ui.modal > .close {
  top: 0.5rem !important;
  right: 0.5rem !important;
  color: rgba(0, 0, 0, 0.87);
}

.ui.basic.positive.button,
.ui.basic.positive.buttons .button {
  background: {
    color: white !important;
  }
  color: $app-yellow-button !important;

  box-shadow: 0 0 0 1px $app-yellow-button inset !important;

  &:hover {
    color: adjust-hue($app-yellow-button, 10deg) !important;
    box-shadow: 0 0 0 1px adjust-hue($app-yellow-button, 10deg) inset !important;
    transition-duration: 0.4s;

    background: {
      color: white !important;
    }
  }

  &:active {
    color: adjust-hue($app-yellow-button, 10deg) !important;
    box-shadow: 0 0 0 1px adjust-hue($app-yellow-button, 10deg) inset !important;
    transition-duration: 0.4s;

    background: {
      color: white !important;
    }
  }

  &:focus {
    color: adjust-hue($app-yellow-button, 10deg) !important;
    box-shadow: 0 0 0 1px adjust-hue($app-yellow-button, 10deg) inset !important;
    transition-duration: 0.4s;

    background: {
      color: white !important;
    }
  }

  &.dark {
    color: adjust-hue($app-yellow-button, 10deg) !important;
    box-shadow: 0 0 0 1px adjust-hue($app-yellow-button, 10deg) inset !important;
    transition-duration: 0.4s;

    background: {
      color: white !important;
    }

    &:hover {
      color: adjust-hue($app-yellow-button, 10deg) !important;
      box-shadow: 0 0 0 1px adjust-hue($app-yellow-button, 10deg) inset !important;
      transition-duration: 0.4s;

      background: {
        color: #f8f8f8 !important;
      }
    }

    &:active {
      color: adjust-hue($app-yellow-button, 10deg) !important;
      box-shadow: 0 0 0 1px adjust-hue($app-yellow-button, 10deg) inset !important;
      transition-duration: 0.4s;

      background: {
        color: #f8f8f8 !important;
      }
    }

    &:focus {
      color: adjust-hue($app-yellow-button, 10deg) !important;
      box-shadow: 0 0 0 1px adjust-hue($app-yellow-button, 10deg) inset !important;
      transition-duration: 0.4s;

      background: {
        color: #f8f8f8 !important;
      }
    }
  }

  &.reverse {
    &:hover {
      color: white !important;
      transition-duration: 0.5s;
      background: {
        color: $app-yellow-button !important;
      }
    }

    &:active {
      color: white !important;
      transition-duration: 0.5s;
      background: {
        color: $app-yellow-button !important;
      }
    }

    &:focus {
      color: white !important;
      transition-duration: 0.5s;
      background: {
        color: $app-yellow-button !important;
      }
    }
  }
}
