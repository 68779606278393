.b__fishes-company {
  &__wrapper {
    width: 100%;
    padding: 24px 0;
    margin-bottom: 36px;
  }

  &__body {
    margin: 0 auto;
  }

  &__body-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .b__tradar-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 160px;
    font-size: 16px;
    color: #202124;
  }

  &__footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 36px;

    @media screen and (max-width: 564px) {
      width: 90%;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 320px;
      height: 40px;
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      background: #ffc107;
      border: none;
      border-radius: 5px;

      @media screen and (max-width: 564px) {
        width: 100%;
      }
    }
  }

  &__loading {
    width: 100%;
    padding: 25vh 0;
  }

  &__empty {
    display: flex;
    justify-content: center;
    padding: 15vh 0;
  }
}

.b__fishes-company__header {
  padding: 0 16px;
  margin-bottom: 24px;
  text-align: left;

  .name {
    margin-bottom: 5px;
    font-size: 20px;
    color: #1e70bf;
  }

  .count {
    margin-right: 16px;
  }

  .eva-count {
    margin-left: 8px;
    font-size: 14px;
    color: #1e70bf;
  }
}
