.sale_modal_container {
  max-height: 512px !important;
  overflow-y: scroll !important;
}

/**
  * マイページ
  * メール設定モーダル
*/
.mypage__email-config-modal {
  width: 90% !important;
  max-width: 480px !important;
}

/** Reply モーダル */
.mypage__reply-modal {
  width: 90% !important;
  max-width: 480px !important;
}

/** 画像投稿モーダル */
.a__upload-modal {
  width: 90% !important;
  max-width: 480px !important;

  &__form {
    margin-top: 12px;
  }

  &__dropzone {
    position: relative;
    border: 2px dashed rgba(204, 204, 204, 0.8);
    border-radius: 5px;
  }

  &__dragzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    cursor: pointer;
  }

  &__paragraph {
    font-size: 14px;
    text-align: center;
  }

  &__btn {
    margin-top: 24px;

    .ui.button {
      height: 40px;
    }
  }
}

/**
  入札確認モーダル
*/

.sell-modal {
  position: fixed;
  width: 100% !important;
  min-width: initial !important;
  height: 100%;
  max-height: initial !important;
  padding: 0 !important;

  .w__close {
    position: sticky !important;
    top: 0 !important;
    left: 0 !important;
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 0.75rem;
    background-color: #ef6266;

    &::after {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0 8px;
      color: #fff;
      white-space: pre-wrap;
      content: "出品する商品の詳細プレビュー画面を表示中です。\A入力項目を確認し問題ない場合、ページ下部の「出品する」ボタンにて出品登録をお願い致します。\A\Aこのメッセージをタップでプレビュー画面を終了し、入力画面に戻ります。"
    }

    img {
      position: relative;
      display: inline-block;
    }
  }

  .b__body {
    padding: 32px 16px 0 !important;
  }
}
