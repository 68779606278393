.app_profile_header {
  width: 100%;
  height: #{$profile_header_height}px;
  margin-bottom: #{$profile_header_bottom}px;
  background-repeat: no-repeat;
  background-size: cover;
  @include mq_up('md') {
    display: none;
  }

  .main {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    @include mq_up('md') {
      padding-left: #{$ui-header-left}px;
    }
  }

  .follow-button {
    display: flex;
    align-items: center;
    .ui.button {
      width: 120px;
      height: 32px;
      padding: 9px 17.5px;
      color: $app-color-white;
      background-color: $app-yellow-button;

      &:hover {
        opacity: 0.8;
        transition-duration: 0.4s;

        @include mq_down('sm') {
          opacity: 1;
        }
      }
    }

    @include mq_down('sm') {
      display: none;
    }
  }

  .follow-button.sm {
    display: none;
    @include mq_down('sm') {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 8px;
    }
  }

  .unfollow-button {
    display: flex;
    align-items: center;
    .ui.button {
      width: 120px;
      height: 32px;
      padding: 9px 17.5px;
      color: $app-color-black;
      background-color: $app-white-button;
      border: solid 1px $app-color-black;

      &:hover {
        opacity: 0.8;
        transition-duration: 0.4s;

        @include mq_down('sm') {
          opacity: 1;
        }
      }
    }

    @include mq_down('sm') {
      display: none;
    }
  }

  .unfollow-button.sm {
    display: none;
    @include mq_down('sm') {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 8px;
    }
  }

  .f__userThumbnail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .w__circle {
    width: #{$w__circle_width}px;
    min-width: #{$w__circle_width}px;
    height: #{$w__circle_height}px;
    min-height: #{$w__circle_height}px;
    margin-right: 16px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 50%;

    .ui.image {
      z-index: 1;
      width: #{$w__circle_width}px;
      min-width: #{$w__circle_width}px;
      height: #{$w__circle_height}px;
      min-height: #{$w__circle_height}px;
      border-radius: 50%;
    }

    a {
      display: none;
    }

    a.edit {
      @include grid-column-span(1, 16);
      @include grid-row-span(12, 4);

      z-index: 2;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 9px;
      font-weight: 600;
      color: white;
      background-color: #888;
    }
  }

  .w__description {
    width: 100%;
    color: $app-color-black;
    text-align: left;
    word-wrap: break-word;

    @include mq_down('xs') {
      padding: 0 8px;
    }

    .ui.header {
      width: 100%;
      font-size: #{$app-font-size-profileheader}px;
      line-height: $app-line-height-main;
      color: $app-color-black;
    }

    .new-line {
      @include mq_up('md') {
        display: none;
      }
    }

    .profile_reputation {
      margin-left: 24px;
      a {
        color: $hover-blue-sm;
        &:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down() {
            text-decoration: none;
            opacity: 1;
          }
        }
      }
      @include mq_down('sm') {
        margin-left: 0;
      }
    }

    .profile-follow {
      white-space: nowrap;

      a {
        display: inline-block;
        height: 17px;
        padding: 0;
        color: $hover-blue-sm;

        &:hover {
          text-decoration: underline;
          background-color: #ffffff;
          opacity: 0.7;

          @include mq_down() {
            text-decoration: none;
            opacity: 1;
          }
        }
      }

      .follow-num {
        margin-right: 8px;
      }
    }

    .profile-follow.other {
      display: inline-block;
      margin-top: 8px;
    }
  }

  .ui.button.report {
    position: absolute;
    right: 8px;
    bottom: 14px;
    display: block;
    width: 80px;
    height: 20px;
    padding: 0;
    font-size: 12px;
    color: white;
    background-color: #888;
    border-radius: 5px;

    &:hover {
      background-color: #999;
      transition: {
        duration: 0.4s;
      }
    }
  }

  @include mq_down('sm') {
    height: auto;

    .main {
      flex-flow: column;
      justify-content: center;
    }

    .f__userThumbnail {
      justify-content: center;
      width: 100%;
      height: auto;
      margin-bottom: #{$thumbnail_margin_bottom}px;

      .w__circle {
        margin: 0;
      }
    }

    .wrapper.description {
      width: 100%;
      text-align: center;
    }

    .w__description {
      width: 100%;
      text-align: center;
    }
  }
}
