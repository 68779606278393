.app_landing {
  .mainvisual {
    margin-top: 16px;

    .landing-background {
      width: 100%;
      height: 600px;
      background-image: url('https://fishsale.jp/assets/mainvisual_bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;

      img {
        width: 100%;
      }

      .mainvisual-inner {
        display: flex;
        width: 100%;

        .content-inner {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 600px;
          padding: 50px 0;

          .balloon {
            width: 100%;
            max-width: 140px;
            background-image: url('https://fishsale.jp/assets/mainvisual_balloon.png');
            background-repeat: no-repeat;

            .bg-balloon {
              display: inline-block;
              height: 115px;
              padding: 45px 23px;
              font-size: 24px;
              font-weight: bold;
              color: $app-color-white;
            }
          }

          .section-inner {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-top: 8px;
            color: $app-color-white;

            .content-width {
              .sub-txt {
                font-size: 28px;
                font-weight: bold;

                .less_than_sm {
                  display: none;
                }

                .slanting-line {
                  font-size: 40px;
                  font-weight: normal;
                }
              }

              .catch-txt {
                font-size: 46px;

                .less_than_sm {
                  display: none;
                }
              }

              .mv-merit-list-floatlist {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin: 30px 0 0 0;

                .floatlist-pig,
                .floatlist-good,
                .floatlist-heart {
                  width: 150px;
                  height: 170px;
                  font-size: 28px;
                }

                .floatlist-pig {
                  background-image: url('https://fishsale.jp/assets/mainvisual_circle_01.png');
                  background-repeat: no-repeat;

                  .floatlist-text {
                    display: inline-block;
                    padding: 84px 47px;
                    font-weight: bold;
                  }
                }

                .floatlist-good {
                  margin-left: 60px;
                  background-image: url('https://fishsale.jp/assets/mainvisual_circle_02.png');
                  background-repeat: no-repeat;

                  .floatlist-text {
                    display: inline-block;
                    padding: 84px 47px;
                    font-weight: bold;
                  }
                }

                .floatlist-heart {
                  margin-left: 60px;
                  background-image: url('https://fishsale.jp/assets/mainvisual_circle_03.png');
                  background-repeat: no-repeat;

                  .floatlist-text {
                    display: inline-block;
                    padding: 84px 47px;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sec-trouble {
    padding: 50px 0 60px 0;

    .section-inner {
      display: flex;
      justify-content: center;

      .content-width {
        .first-content {
          display: flex;
          justify-content: center;

          .less_than_sm {
            display: none;
          }

          .sec-ttl {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            font-weight: bold;
            color: $app-lp-color;

            .bg-circle {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 122px;
              height: 122px;
              margin: 0 19px;
              border: 2px solid;
              border-radius: 50%;
            }
          }
        }

        .sec-content {
          padding-top: 35px;
          color: $app-lp-color;

          .content-ttl {
            text-align: center;
          }

          .trouble-list {
            display: flex;

            .trouble-card {
              .txt-box {
                width: 220px;
                height: 155px;
              }
            }
          }
        }
      }
    }
  }

  .section-inner {
    .ttl-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 308px;
      background: #020202 no-repeat center center;
      background-image: url('https://fishsale.jp/assets/about_ttl_bg.png');
      background-repeat: no-repeat;

      .content-width-title {
        width: 100%;
        max-width: 1000px;

        .ttl-box {
          color: $app-color-white;

          .ttl-subtxt {
            .content-titile {
              font-size: 24px;
              font-weight: bold;
            }
          }

          .ttl {
            margin-top: 36px;
            font-size: 36px;
            font-weight: bold;

            .emphasis-text {
              display: inline-block;
              margin-top: 30px;
              color: $emphasis-red;
            }
          }
        }
      }
    }
  }

  .content {
    .less_than_md {
      display: none;
    }

    .content-width-intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 0 70px;
      background-image: url('https://fishsale.jp/assets/dot_black.png'),
        url('https://fishsale.jp/assets/about_content_bg.jpg');
      background-repeat: repeat, no-repeat;
      background-size: auto, cover;

      .sec-ttl {
        font-size: 30px;
        font-weight: bold;
        line-height: $app-line-height-main;
        color: $app-color-white;
        text-align: center;

        .under-line {
          border-bottom: solid $app-color-white;
        }
      }

      .common-box-container {
        width: 1000px;
        margin-top: 40px;
      }

      .exhibitor-merit-box {
        .header-blue {
          padding: 13px 0;
          font-size: 24px;
          font-weight: bold;
          line-height: 1;
          color: $app-color-white;
          text-align: center;
          background: $header-blue;
          border-radius: 10px 10px 0 0;
        }

        .lower {
          .merit-list {
            display: flex;

            .txt-sm {
              display: none;
            }

            .merit-first,
            .merit-second {
              width: 50%;
              padding: 20px 0 40px 30px;
              background-image: url('https://fishsale.jp/assets/about_exhibitor_merit_bg_01.png');
              background-repeat: no-repeat;

              .catch-circle {
                height: 225px;
                background-image: url('https://fishsale.jp/assets/about_merit_bg_circle_01.png');
                background-repeat: no-repeat;

                .cell-intro {
                  display: inline-block;
                  padding: 65px 26px;
                  font-size: 24px;
                  font-weight: bold;
                  line-height: 1;
                  text-align: center;
                }
              }
            }

            .merit-second {
              background-image: url('https://fishsale.jp/assets/about_exhibitor_merit_bg_02.png');

              .catch-circle {
                background-image: url('https://fishsale.jp/assets/about_merit_bg_circle_02.png');

                .cell-intro {
                  padding: 65px 40px;
                }
              }
            }
          }
        }
      }

      .bidder-merit-common-box {
        margin-top: 40px;

        .header-red {
          padding: 13px 0;
          font-size: 24px;
          font-weight: bold;
          line-height: 1;
          color: $app-color-white;
          text-align: center;
          background: $emphasis-red;
          border-radius: 10px 10px 0 0;
        }

        .lower {
          .bidder-merit-list {
            display: flex;

            .txt-sm {
              display: none;
            }

            .merit-first,
            .merit-second {
              width: 50%;
              padding: 20px 0 40px 30px;
              background-image: url('https://fishsale.jp/assets/about_exhibitor_merit_bg_03.png');
              background-repeat: no-repeat;

              .catch-circle {
                height: 225px;
                background-image: url('https://fishsale.jp/assets/about_merit_bg_circle_03.png');
                background-repeat: no-repeat;

                .cell-intro {
                  display: inline-block;
                  padding: 65px 26px;
                  font-size: 24px;
                  font-weight: bold;
                  line-height: 1;
                  text-align: center;
                }
              }
            }

            .merit-first {
              .catch-circle {
                .cell-intro {
                  padding: 85px 15px;
                }
              }
            }

            .merit-second {
              background-image: url('https://fishsale.jp/assets/about_exhibitor_merit_bg_04.png');

              .catch-circle {
                background-image: url('https://fishsale.jp/assets/about_merit_bg_circle_04.png');

                .cell-intro {
                  padding: 65px 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .sec-steps {
    padding: 50px 0 70px 0;
    background-color: $lp-background-color;

    .section-inner {
      display: flex;
      justify-content: center;

      .content-width {
        .sec-ttl {
          font-size: 30px;
          font-weight: bold;
          color: $app-lp-color;
          text-align: center;

          .less_than_sm {
            display: none;
          }

          .number {
            font-size: 50px;
            color: $emphasis-red;
          }
        }
      }

      .common-box-container {
        width: 1000px;
        margin-top: 50px;

        .exhibitor-steps {
          .header-blue {
            padding: 13px 0;
            font-size: 24px;
            font-weight: bold;
            line-height: 1;
            color: $app-color-white;
            text-align: center;
            background: $header-blue;
            border-radius: 10px 10px 0 0;
          }

          .lower {
            .steps-card {
              display: flex;
              padding: 42px 0;
              background-color: $app-color-white;
              border-radius: 10px;

              .steps-list,
              .steps-list-first {
                width: 25%;

                .ttl {
                  font-size: 25px;
                  color: $header-blue;
                }
              }

              .steps-list-first {
                margin-left: 41px;
              }
            }
          }
        }
      }

      .common-box-bidder-steps {
        width: 1000px;
        margin-top: 50px;

        .header-red {
          padding: 13px 0;
          font-size: 24px;
          font-weight: bold;
          line-height: 1;
          color: $app-color-white;
          text-align: center;
          background: $emphasis-red;
          border-radius: 10px 10px 0 0;
        }

        .lower {
          .steps-card {
            display: flex;
            padding: 42px 0;
            background-color: $app-color-white;
            border-radius: 10px;

            .bidder-steps-list,
            .bidder-steps-list-first {
              width: 25%;

              .ttl {
                font-size: 25px;
                color: $emphasis-red;
              }
            }

            .bidder-steps-list-first {
              margin-left: 41px;
            }
          }
        }
      }
    }
  }

  //画面サイズ1365px~992pxのデザイン実装
  @include mq_down('lg') {
    .sec-trouble {
      .trouble-card {
        width: 240px;

        img {
          width: 100%;
        }
      }
    }

    .section-inner {
      .ttl-container {
        .content-width-title {
          width: 95%;
        }
      }

      .content {
        .content-width-intro {
          .common-box-container {
            width: 960px;
          }
        }
      }
    }

    .sec-steps {
      .section-inner {
        .content-width {
          .sec-ttl {
            text-align: center;
          }
        }

        .common-box-container {
          width: 960px;
        }

        .common-box-bidder-steps {
          width: 960px;
        }
      }
    }
  }

  //画面サイズ992px~768pxのデザイン実装
  @include mq_down('md') {
    .sec-trouble {
      .section-inner {
        .content-width {
          .sec-content {
            .trouble-list {
              .trouble-card {
                width: 184px;

                .txt-box {
                  width: 100%;
                  padding-right: 20px;
                }
              }
            }
          }
        }
      }
    }

    .section-inner {
      .content {
        .less_than_md {
          display: inline;
        }

        .content-width-intro {
          .common-box-container {
            width: 736px;
          }

          .exhibitor-merit-box {
            .lower {
              .merit-list {
                flex-direction: column;

                .merit-first,
                .merit-second {
                  width: 100%;
                  padding: 20px 30px 40px;
                  background-image: url('https://fishsale.jp/assets/about_exhibitor_merit_bg_01_md.png');

                  .catch-circle {
                    width: 50%;
                    height: 50%;
                    margin-left: 40px;
                  }

                  .txt {
                    width: 50%;
                    font-size: 20px;
                  }
                }

                .merit-second {
                  background-image: url('https://fishsale.jp/assets/about_exhibitor_merit_bg_02_md.png');
                  border-radius: 0 0 10px 10px;
                }
              }
            }
          }

          .bidder-merit-common-box {
            .lower {
              .bidder-merit-list {
                flex-direction: column;

                .merit-first,
                .merit-second {
                  width: 100%;
                  padding: 20px 30px 40px;
                  background-image: url('https://fishsale.jp/assets/about_bidder_merit_bg_01_md.png');

                  .catch-circle {
                    width: 50%;
                    height: 50%;
                    margin-left: 40px;
                  }

                  .txt {
                    width: 50%;
                    font-size: 20px;
                  }
                }

                .merit-second {
                  background-image: url('https://fishsale.jp/assets/about_bidder_merit_bg_02_md.png');
                  border-radius: 0 0 10px 10px;
                }
              }
            }
          }
        }
      }
    }

    .sec-steps {
      .section-inner {
        .common-box-container {
          width: 736px;
        }

        .common-box-bidder-steps {
          width: 736px;
        }
      }
    }
  }

  //画面サイズ767px以下のデザイン実装
  @include mq_down('sm') {
    .mainvisual {
      .landing-background {
        width: 100%;
        height: 950px;
        background-image: url('https://fishsale.jp/assets/mainvisual_bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;

        img {
          width: 100%;
        }

        .mainvisual-inner {
          display: flex;
          width: 100%;

          .content-inner {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 720px;
            padding: 48px 0 50px;

            .balloon {
              width: 100%;
              max-width: 140px;
              background-image: url('https://fishsale.jp/assets/mainvisual_balloon.png');
              background-repeat: no-repeat;

              .bg-balloon {
                display: inline-block;
                height: 115px;
                padding: 45px 23px;
                font-size: 24px;
                font-weight: bold;
                color: $app-color-white;
              }
            }

            .section-inner {
              display: flex;
              justify-content: center;
              width: 100%;
              padding-top: 8px;
              color: $app-color-white;

              .content-width {
                .sub-txt {
                  font-size: 20px;
                  font-weight: bold;
                  text-align: center;

                  .less_than_sm {
                    display: inline;
                  }

                  .more_than_md {
                    display: none;
                  }

                  .slanting-line {
                    font-size: 40px;
                    font-weight: normal;
                  }
                }

                .catch-txt {
                  font-size: 38px;
                  text-align: center;

                  .less_than_sm {
                    display: inline;
                  }
                }

                .mv-merit-list-floatlist {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin: 0;

                  .floatlist-pig,
                  .floatlist-good,
                  .floatlist-heart {
                    width: 150px;
                    height: 170px;
                    margin-top: 20px;
                    font-size: 28px;
                  }

                  .floatlist-pig {
                    background-image: url('https://fishsale.jp/assets/mainvisual_circle_01.png');
                    background-repeat: no-repeat;

                    .floatlist-text {
                      display: inline-block;
                      padding: 84px 47px;
                      font-weight: bold;
                    }
                  }

                  .floatlist-good {
                    margin-left: 0;
                    background-image: url('https://fishsale.jp/assets/mainvisual_circle_02.png');
                    background-repeat: no-repeat;

                    .floatlist-text {
                      display: inline-block;
                      padding: 84px 47px;
                      font-weight: bold;
                    }
                  }

                  .floatlist-heart {
                    margin-left: 0;
                    background-image: url('https://fishsale.jp/assets/mainvisual_circle_03.png');
                    background-repeat: no-repeat;

                    .floatlist-text {
                      display: inline-block;
                      padding: 84px 47px;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sec-trouble {
      padding: 20px 0 0;

      .section-inner {
        display: flex;
        align-items: center;
        justify-content: center;

        .content-width {
          .first-content {
            display: block;
            justify-content: center;

            .less_than_sm {
              display: block;
              font-size: 30px;
              font-weight: bold;
              color: $app-lp-color;
              text-align: center;
            }

            .sec-ttl {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 20px;
              font-size: 30px;
              font-weight: bold;
              color: $app-lp-color;

              .more_than_md {
                display: none;
              }

              .bg-circle {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 122px;
                height: 122px;
                margin: 0 19px;
                border: 2px solid;
                border-radius: 50%;
              }
            }
          }

          .sec-content {
            padding: 35px 16px 0;
            color: $app-lp-color;

            .content-ttl {
              font-size: 17px;
            }

            .trouble-list {
              display: flex;
              flex-direction: column;
              width: 328px;

              .trouble-card {
                width: 100%;
                margin-bottom: 32px;

                .txt-box {
                  width: 100%;
                  height: auto;
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }

    .section-inner {
      .ttl-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 308px;
        background: #020202 no-repeat center center;
        background-image: url('https://fishsale.jp/assets/about_ttl_bg.png');
        background-repeat: no-repeat;

        .content-width-title {
          width: 100%;
          max-width: 1000px;

          .ttl-box {
            color: $app-color-white;

            .ttl-subtxt {
              .content-titile {
                font-size: 20px;
                font-weight: bold;
                text-align: center;
              }
            }

            .ttl {
              margin-top: 36px;
              font-size: 30px;
              font-weight: bold;
              text-align: center;

              .emphasis-text {
                color: $emphasis-red;
              }
            }
          }
        }
      }

      .content {
        .content-width-intro {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 50px 16px 70px;
          background-image: url('https://fishsale.jp/assets/dot_black.png'),
            url('https://fishsale.jp/assets/about_content_bg.jpg');
          background-repeat: repeat, no-repeat;
          background-size: auto, cover;

          .sec-ttl {
            width: 328px;
            font-size: 28px;
            font-weight: bold;
            line-height: $app-line-height-main;
            color: $app-color-white;
            text-align: start;

            .under-line {
              border-bottom: solid $app-color-white;
            }

            .more_than_md {
              display: none;
            }
          }

          .common-box-container {
            width: 328px;
            margin-top: 40px;
          }

          .exhibitor-merit-box {
            .lower {
              .merit-list {
                display: flex;

                .txt-sm {
                  display: block;
                  padding-bottom: 14px;
                  padding-left: 14px;
                  margin: 0;
                  background: $app-search_form_background;
                }

                .txt-sm.second {
                  border-radius: 0 0 10px 10px;
                }

                .merit-first,
                .merit-second {
                  height: 232px;
                  padding: 20px 30px 0 0;
                  background-image: url('https://fishsale.jp/assets/about_exhibitor_merit_bg_01_sm.png');

                  .catch-circle {
                    margin-left: 0;
                    background-image: none;

                    .cell-intro {
                      padding: 78px 10px;
                      margin-left: 30px;
                      font-size: 18px;
                    }
                  }

                  .txt {
                    display: none;
                  }
                }

                .merit-second {
                  background-image: url('https://fishsale.jp/assets/about_exhibitor_merit_bg_02_sm.png');
                  border-radius: 0;
                }
              }
            }
          }

          .bidder-merit-common-box {
            margin-top: 40px;

            .lower {
              .bidder-merit-list {
                display: flex;

                .txt-sm {
                  display: block;
                  padding-bottom: 14px;
                  padding-left: 14px;
                  margin: 0;
                  background: $app-search_form_background;
                }

                .txt-sm.second {
                  border-radius: 0 0 10px 10px;
                }

                .merit-first,
                .merit-second {
                  height: 232px;
                  padding: 20px 30px 0 0;
                  background-image: url('https://fishsale.jp/assets/about_bidder_merit_bg_01_sm.png');
                }

                .merit-first {
                  .catch-circle {
                    margin-left: 0;
                    background-image: none;

                    .cell-intro {
                      padding: 85px 10px;
                      margin-left: 15px;
                      font-size: 18px;
                    }
                  }
                }

                .merit-second {
                  background-image: url('https://fishsale.jp/assets/about_bidder_merit_bg_02_sm.png');
                  border-radius: 0;

                  .catch-circle {
                    margin-left: 0;
                    background-image: none;

                    .cell-intro {
                      padding: 78px 5px;
                      margin-left: 10px;
                      font-size: 18px;
                    }
                  }
                }

                .txt {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .sec-steps {
      padding: 50px 0 70px 0;
      background-color: $lp-background-color;

      .section-inner {
        display: flex;
        justify-content: center;

        .content-width {
          width: 100%;

          .sec-ttl {
            font-size: 28px;
            font-weight: bold;
            line-height: 1.5em;
            color: $app-lp-color;

            .less_than_sm {
              display: inline;
            }

            .number {
              font-size: 50px;
              color: $emphasis-red;
            }
          }
        }

        .common-box-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          width: 100%;
          margin-top: 50px;

          .exhibitor-steps {
            width: 328px;

            .lower {
              .steps-card {
                display: flex;
                flex-direction: column;
                padding: 42px 0;
                background-color: $app-color-white;
                border-radius: 10px;

                .steps-list,
                .steps-list-first {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 100%;

                  .ttl {
                    font-size: 25px;
                    color: $header-blue;
                  }
                  margin-bottom: 24px;
                }

                .steps-list-first {
                  margin-left: 0;
                }

                .steps-list.last {
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        .common-box-bidder-steps {
          width: 328px;

          .lower {
            .steps-card {
              display: flex;
              flex-direction: column;
              padding: 42px 0;
              background-color: $app-color-white;
              border-radius: 10px;

              .bidder-steps-list,
              .bidder-steps-list-first {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                .ttl {
                  font-size: 25px;
                  color: $emphasis-red;
                }
                margin-bottom: 24px;
              }

              .bidder-steps-list-first {
                margin-left: 0;
              }

              .bidder-steps-list.last {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  //画面サイズ359px以下のデザイン実装
  @include mq_down('xs') {
    .sec-trouble {
      .section-inner {
        .content-width {
          .sec-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .trouble-list {
              width: 288px;
            }
          }
        }
      }
    }

    .section-inner {
      .ttl-container {
        .content-width-title {
          .ttl-box {
            .ttl-subtxt {
              .content-titile {
                font-size: 18px;
              }
            }

            .ttl {
              font-size: 28px;
            }
          }
        }
      }

      .content {
        .content-width-intro {
          .sec-ttl {
            width: 288px;
            font-size: 26px;
          }

          .common-box-container {
            width: 288px;
          }

          .exhibitor-merit-box {
            .lower {
              .merit-list {
                .merit-first,
                .merit-second {
                  height: 232px;
                  background-image: url('https://fishsale.jp/assets/about_exhibitor_merit_bg_01_sm.png');
                }

                .merit-first {
                  .catch-circle {
                    margin-left: 0;

                    .cell-intro {
                      display: inline-block;
                      width: 100%;
                      padding: 75px 0;
                      margin-left: 20px;
                    }
                  }
                }

                .merit-second {
                  background-image: url('https://fishsale.jp/assets/about_exhibitor_merit_bg_02_sm.png');
                }
              }
            }
          }

          .bidder-merit-common-box {
            .lower {
              .bidder-merit-list {
                .merit-first {
                  .catch-circle {
                    .cell-intro {
                      width: 100%;
                      padding: 85px 0;
                      margin-left: 10px;
                    }
                  }
                }

                .merit-second {
                  .catch-circle {
                    .cell-intro {
                      width: 100%;
                      padding: 78px 0;
                      margin-left: 13px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sec-steps {
      .section-inner {
        .content-width {
          .sec-ttl {
            font-size: 24px;

            .number {
              font-size: 44px;
            }
          }
        }

        .common-box-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          width: 100%;
          margin-top: 50px;

          .exhibitor-steps {
            width: 288px;

            .lower {
              .steps-card {
                display: flex;
                flex-direction: column;
                padding: 42px 0;
                background-color: $app-color-white;
                border-radius: 10px;

                .steps-list,
                .steps-list-first {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 100%;

                  .ttl {
                    font-size: 25px;
                    color: $header-blue;
                  }
                  margin-bottom: 24px;
                }

                .steps-list-first {
                  margin-left: 0;
                }
              }
            }
          }
        }

        .common-box-bidder-steps {
          width: 288px;

          .lower {
            .steps-card {
              display: flex;
              flex-direction: column;
              padding: 42px 0;
              background-color: $app-color-white;
              border-radius: 10px;

              .bidder-steps-list,
              .bidder-steps-list-first {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                .ttl {
                  font-size: 25px;
                  color: $emphasis-red;
                }
                margin-bottom: 24px;
              }

              .bidder-steps-list-first {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
