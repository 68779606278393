.sales_list {
  padding: 0 36px;
  padding-bottom: #{$body-bottom}px;
  line-height: $app-line-height-main;

  div {
    color: $app-color-black;
  }

  @include mq_down() {
    width: 100%;
    padding: 0 24px;
  }

  @include mq_down('sm') {
    padding: 0;
  }

  .sales_list-tradar-list {
    display: grid;
    list-style: none;
    gap: 30px;
  }

  .sales_list-tradar-item {
    width: 1200px;
    padding: 20px 30px 30px;
    border: 2px solid #ccc;
    border-radius: 5px;
  }

  .sales_list-tradar-item-title {
    padding: 0 0 5px;
    font-size: 20px;
    color: #1e70bf;
  }

  .sales_list-tradar-item-detail {
    display: flex;
    padding: 0 0 30px;
  }

  .sales_list-tradar-item-exhibition {
    padding: 0 10px 0 0;
  }

  .sales_list-tradar-item-star-wrapper {
    position: relative;
  }

  .sales_list-tradar-item-star-down {
    position: absolute;
    top: 0;
    left: 0;
    color: #ccc;
  }

  .sales_list-tradar-item-star-up {
    position: relative;
    overflow: hidden;
    color: #fcbc0b;
  }

  .sales_list-tradar-item-evaluation {
    color: #6d9ed1;
  }

  .sales_list-tradar-item-list {
    display: grid;
    grid-template: auto / repeat(5, 1fr);
    padding: 0;
    list-style: none;
    gap: 20px;
  }

  .sales_list-tradar-item-item {
    padding: 30px 0 0;
    overflow: hidden;
    border: 1px solid #ccc;
  }

  .sales_list-tradar-item-item-thumbnail-wrapper {
    position: relative;
    width: 100%;
  }

  .sales_list-tradar-item-item-thumbnail-wrapper::before {
    display: block;
    padding: 100% 0 0;
    content: '';
  }

  .sales_list-tradar-item-item-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .sales_list-tradar-item-item-detail {
    padding: 0 15px;
  }

  .sales_list-tradar-item-item-name {
    padding: 40px 0 0;
    overflow: hidden;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sales_list-tradar-item-item-price {
    font-size: 20px;
  }

  .sales_list-tradar-item-button-wrapper {
    display: flex;
    justify-content: center;
    padding: 30px 0 0;
  }

  .sales_list-tradar-item-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 40px;
    font-size: 18px;
    color: #fff;
    background: #ffc107;
    border: none;
    border-radius: 5px;
  }
}

.app_sales_list.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;

  .app_fish_card {
    display: flex;
    justify-content: center;
    width: 17.9vw;
    margin: 3px;
    transition: transform 0.5s;

    @include mq_down('sm') {
      width: 48%;
      height: 100%;
      margin: 2px;
    }

    .ui.card,
    .ui.cards > .card {
      width: 100%;

      @include mq_down('sm') {
        min-width: auto;
      }
    }
  }
}
