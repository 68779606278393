.image_edit_modal {
  &.app__modal {
    .b__body {
      padding: {
        top: 8px;
        bottom: 8px;
      }
    }
  }

  .image-edit-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image-edit-cropper {
      width: 300px;
      max-height: 400px;

      @include mq_down("md") {
        max-height: 320px;
      }

      @include mq_down("sm") {
        max-height: 250px;
      }
    }
    .image-edit-modal-title {
      padding-bottom: 24px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  img {
    max-width: 100%;
  }

  .cropper-view-box {
    outline: 2px solid $cropper-line-color;
    outline-color: rgba($cropper-line-color, 0.75);
  }

  .cropper-point.point-se:before {
    background-color: $point-color;
  }

  .cropper-point {
    background-color: $point-color;
  }

  .cropper-line {
    background-color: $cropper-line-color;
  }

  .w__edit-buttons {
    display: flex;
    margin-top: 24px;
    .cancel-button {
      width: 140px;
      height: 48px;
      color: $app-color-black;
      background: $app-white-button;
      &:hover {
        background: $app-white-button !important;
        opacity: 0.7;
      }
    }
    .crop-button {
      width: 140px;
      height: 48px;
      color: $app-color-white;
      background: $app-yellow-button;
      &:hover,
      &:focus {
        color: $app-color-white !important;
        background: $app-yellow-button !important;
        opacity: 0.7;
      }
    }
  }
}
