.error-modal {
  position: relative;
  h3 {
    position: relative;
    bottom: 16px;
    font-size: 1.71428571rem;
    text-align: center;
  }

  .error-message {
    font-size: 16px;
    text-align: center;
  }
}
