.a__bid_history {
  padding: 0 16px;

  @include mq_down() {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .a__bid_info_row {
    display: flex;
    align-items: center;
    padding-left: 24px;
    margin-bottom: 8px;
    color: $app-color-black;

    @include mq_up("lg") {
      font-size: 16px;
    }

    @include mq_down() {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding-left: 0;
      font-size: 13px;
      text-align: left;
    }

    .w__date {
      width: 170px;
      line-height: $app-line-height-main;

      @media only screen and (max-width: 632px) {
        display: flex;
        align-self: flex-start;
      }
    }

    .w__link {
      padding-left: 12px;
      line-height: $app-line-height-main;

      span {
        &:hover {
          text-decoration: underline;
          opacity: 0.7;

          @include mq_down("sm") {
            text-decoration: none;
            opacity: 1;
          }
        }

        .buyer {
          color: #3f98e2;
        }
      }

      @media only screen and (max-width: 632px) {
        padding-left: 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
