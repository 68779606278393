.a__edit_mypage {
  background-color: #fff;

  .introduction_container {
    .field-group {
      justify-content: center !important;
    }
  }

  .word_count {
    margin: {
      left: 10px;
    }
  }

  .form_item {
    .flex_required {
      color: $app-color-black;

      .align-center {
        display: flex;
        align-items: center;
      }
    }

    .prefecture {
      display: flex;
      align-items: center;
      height: 36px !important;
      margin: {
        bottom: 5px;
      }
      padding: {
        top: 11px !important;
      }

      i {
        margin: -0.3em !important;
      }
    }

    .is_company {
      .checkbox_label {
        margin-right: 10px;
        font-size: 14px;
      }

      .app_form_label_icon {
        margin-left: 0 !important;
      }
    }
  }

  .change_password {
    .ui.button {
      width: 220px;
      margin-left: 0;
      color: $app-color-black;
      background-color: $password-reset-button;
      margin: {
        top: 0;
        bottom: 0;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .change_email_caption {
    span {
      color: #ef6266;
    }
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .change_email {
    .ui.button {
      width: 220px;
      margin: {
        top: 32px;
        bottom: 16px;
        left: 0;
      }
      color: $app-color-black;
      background-color: $email-reset-button;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .small_font {
    color: $app-color-black;
    text-align: center;
  }

  .dropzone.flex {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 300px;
    padding: 0;
    margin: 32px 0 18px;
    overflow: hidden;
    cursor: pointer;
    border: solid 1px #ccc;
    border-radius: 5px;

    > span {
      text-align: center;
    }

    .ui.medium.image {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      max-width: 90%;
      height: auto;
      max-height: 90%;

      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .dropzone_mobile {
    display: none;
  }

  .field-group {
    @include center2center();

    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .flex_container > label > span {
    position: relative;
    bottom: 4px;
    left: 6px;
    cursor: pointer;
  }

  .field-group textarea {
    // width: 90%;
    width: 100%;
    height: 160px;
    padding: 11px 16px;
    resize: none;
    background-color: #fff;
    border: solid 1px #ccc;
    border-radius: 5px;
  }

  .mypage_contents {
    @include center2center();
  }

  .member_information_container {
    width: 90%;

    .flex_container {
      padding: 0;
      margin: 0 0 5px;
    }

    span {
      font-size: 14px;
    }

    .button {
      padding: 0.78571429em 1.5em 0.78571429em;
      font-weight: 700;
      color: #fff;
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;

      &__add {
        background: #ffc107;
      }

      &__delete {
        margin: 20px 0 8px;
        background: #ef6266;
      }
    }

    .b__form-card {
      padding: 20px;
      margin: 20px 0;
      border: 1px solid #dedce0;
      border-radius: 8px;
      box-shadow: 1px 2px 7px 1px #ccc;
    }
  }

  .sub_title {
    display: flex;
    align-items: center;
    padding-top: 32px;
  }

  .w__reco_credit {
    margin-top: 10px;

    span {
      font-weight: bold;
      color: #ef6266;
    }
  }

  .flex_required.optional {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding-top: 12px;
    padding-bottom: 4px;
    vertical-align: middle;

    .optional {
      display: inline-flex;
      margin-bottom: 3px;
    }

    .error {
      font-size: 12px;
    }
  }

  .flex_required {
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 4px;
    vertical-align: middle;

    &.align-center {
      align-items: center;
    }

    .optional {
      display: inline-flex;
      margin-bottom: 3px;
    }

    .error {
      font-size: 12px;
    }
  }

  .address_note {
    padding-top: 8px;
    font-size: 12px;
    line-height: 1.6em;
  }

  .ui.input {
    min-width: 100%;
    height: 36px;
  }

  .ui.form {
    width: 100%;
  }

  .ui.dropdown {
    &.prefecture {
      .visible.menu.transition {
        max-height: 228px !important;
      }
    }
  }

  h2.ui.header {
    font-size: 16px;
    line-height: $app-line-height-main;
    color: $app-color-black;
  }

  .ui.divider {
    margin-top: 0.6rem;
  }

  .required label::after {
    width: 28px;
    height: 19px;
    margin-top: 0 !important;
    margin-left: 15px !important;
    font-size: 11px;
    color: #fff !important;
    text-align: center;
    content: '必須' !important;
    background-color: #ef6266;
    border-radius: 3px;
  }

  .optional_mark label::after {
    padding: 3px;
    margin-left: 15px;
    font-size: 11px;
    content: '任意';
    background-color: #dcdcdc;
    border-radius: 3px;
  }

  .destination_information_title {
    padding-top: 56px;
    font-size: 20px;
  }

  .sale_fish_information_container {
    .ui.form .grouped.fields {
      // semantin-ui-reactのクラス
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  .input_area .ui.input {
    // semantin-ui-reactのクラス
    min-width: 100%;
  }

  .ui.form .field .ui.input input,
  .ui.form .fields .field .ui.input input {
    width: 100%;
  }

  .caution {
    padding-left: 25px;
    margin: 0;
  }

  .sale_fish_information_title {
    padding-top: 56px;

    label {
      padding-left: 25px;
      font-size: 20px;
    }
  }

  .name_form {
    padding-top: 23px;
  }

  .flex_container {
    display: flex;
    padding-top: 12px;

    input {
      margin-right: 20px;
    }
  }

  .requiritur::after {
    padding: 3px;
    margin-left: 15px;
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    content: '必須';
    background-color: #ef6266;
    border-radius: 3px;
  }

  .requiritur {
    font-weight: 600;
  }

  .women_radio_form {
    padding-left: 34px;
  }

  .ui.selection.dropdown {
    &.prefecture {
      min-width: 90px;
      height: 24px;
      min-height: 0;
      padding-top: 5px;

      .dropdown.icon {
        top: 2px;
      }
    }
  }

  .wrap {
    padding: 4px 0 14px;
  }

  .wrap_identification {
    padding: 4px 0 0;
  }

  .identification {
    width: 100%;
    height: 150px;
    border: solid 1px #ccc;
    border-radius: 5px;
  }

  .identification_text {
    align-self: center;
    justify-self: center;

    p {
      text-align: center;
    }
  }

  .bank_account_information {
    .ui.selection.dropdown {
      width: 100%;
    }
  }

  .bank_account_information_title {
    padding-top: 32px;
    font-size: 20px;
  }

  .divider_area {
    width: 120px;
  }

  .buy_fish_information_title {
    padding-top: 56px;

    label {
      padding-left: 25px;
      font-size: 20px;
    }
  }

  .credit_container {
    width: 240px;
    height: 24px;
    padding: 5px 0 0 15px;
    color: #888;
    background-color: #f0f0f0;
    border-radius: 5px;
  }

  .ui.button {
    width: 100%;
    height: 48px;
    margin: 32px auto;
    line-height: $app-line-height-main;
    color: #fff;
    background-color: #ffc107;
    border-radius: 5px;

    @include hover_button_opacity();

    &:hover {
      @include mq_down('sm') {
        opacity: 1;
      }
    }

    @include center2center();
  }

  .form_submit {
    padding: 64px 0 56px;
    // width: 100%;
    // height: 48px;
    border-radius: 5px;
  }

  .redirect_top {
    display: flex;
    width: 100%;
    padding: 64px 0 32px;
  }

  .dropzone.flex {
    width: 100%;
    height: 320px;
  }

  .company_explanation {
    margin-top: 4px;
    font-size: 14px;

    &_notice {
      margin: 8px 0 16px;
      font-size: 12px;
    }
  }

  .label-link-checkbox {
    display: inline-block;
    padding-left: 20px;
    font-size: 14px;
    cursor: pointer;

    a:hover {
      text-decoration: underline;
      opacity: 0.7;

      @include mq_down('sm') {
        text-decoration: none;
        opacity: 1;
      }
    }
  }

  .b__post-number {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media screen and (max-width: 350px) {
      display: block;
    }

    .b__post-number_inner {
      display: flex;
      align-items: center;
    }

    .ui.input input {
      width: 55px;
    }

    .unit_hyphen {
      margin: 0 8px;
      font-size: 20px;
    }

    .unit_hyphen + .field-group .ui.input {
      input {
        width: 65px;
      }
    }

    .ui.input {
      margin-bottom: 0;
    }

    .post-search-btn {
      padding: 3px 20px;
      margin-left: 16px;
      font-size: 16px;
      font-weight: 700;
      color: #ffc107;
      cursor: pointer;
      background: #fff;
      border: 2px solid #ffc107;
      border-radius: 30px;
      outline: none;

      &:hover {
        color: #fff;
        background: lighten(#ffc107, 20%);
      }

      @media screen and (max-width: 350px) {
        margin-top: 16px;
        margin-left: 0;
      }
    }
  }

  .hidden_form {
    .ui.input {
      display: none;
    }
  }

  .mypage-edit__skip-config {
    ul {
      padding-left: 20px;
    }

    li {
      margin: 8px 0;

      a {
        display: inline-block;
        margin: 5px 0;
      }
    }

    .flex_required {
      flex-wrap: nowrap;
    }
  }
}

/* 銀行口座・クレジットカード登録 */
.payments {
  .payments_container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    // 魚を売りたい人
    .sell_fish {
      .ui.header {
        margin-top: 0 !important;
        font-size: 18px;
        line-height: $app-line-height-main;
        color: $app-color-black;
        text-align: center;

        @include mq_down('sm') {
          font-size: 16px;
        }
      }
    }
    // 魚を買いたい人
    .buy_fish {
      .ui.header {
        font-size: 18px;
        line-height: $app-line-height-main;
        color: $app-color-black;
        text-align: center;

        @include mq_down('sm') {
          font-size: 16px;
        }
      }
    }
  }

  .ui.button.payment_button {
    width: 100%;
    height: 48px;
    line-height: $app-line-height-main;
    color: #fff;
    background-color: #ffc107;
    border-radius: 5px;
    font: {
      size: 15px;
    }

    &:hover {
      @include mq_down('sm') {
        background-color: #ffc107;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .app_mypage {
    .dropzone.flex {
      width: 100%;
      height: 320px;
    }
  }
}

@media screen and (max-width: 767px) {
  .app_mypage {
    .ui.form div.field {
      // semantic-ui-reactのCSS上書き
      margin-bottom: 14px;
    }

    .member_information_container {
      // width: 90%;
      width: 100%;
      padding: 0 16px;
    }

    .field-group textarea {
      // width: 90%;
      width: 100%;
    }

    .mobile_page {
      max-width: 150px;
    }

    .ui.input {
      min-width: 100%;
      height: 36px;
    }

    .ui.form {
      width: 100%;
    }

    .ui.button {
      // width: 335px;
      width: 100%;
    }

    .bank_account_information {
      .ui.selection.dropdown {
        width: 100%;
      }
    }

    .ui.selection.dropdown {
      min-width: 78px;
    }

    .ui.form .fields {
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
    }
  }

  .dropzone.flex {
    width: 100%;
    height: 300px;
  }
}

@media screen and (max-width: 414px) {
  .app_mypage {
    .ui.button {
      // width: 90%;
      width: 100%;
    }
  }

  .form_item {
    .dropzone.flex {
      height: 300px;
      margin: 0 auto !important;
    }
  }
}

.reset_password_modal {
  width: 500px !important;
  min-height: 150px;
  max-height: 80%;
  margin: 0 16px;
  border-radius: 4px;

  .b__body {
    .w__modal-body {
      .a__reset-password-modal {
        padding: 0 40px;

        @include mq_down('sm') {
          padding: 0;
        }

        h1 {
          margin-bottom: 20px;
          text-align: center;

          @include mq_down('xs') {
            font-size: 24px;
          }
        }

        .b__field {
          .field-group {
            .ui.fluid.input.input-field {
              padding-bottom: 8px;
              margin: 0;

              input {
                height: 46px;
                background-color: $password-reset-input;
                border: none;

                &:active {
                  background-color: $app-white-button;
                  box-shadow: 0 0 0 3px $app-yellow-button;
                }

                &:focus {
                  background-color: $app-white-button;
                  box-shadow: 0 0 0 3px $app-yellow-button;
                }
              }
            }
          }
        }

        .w__actions {
          margin-top: 8px;

          .ui.fluid.positive.button {
            height: 40px;

            &:hover {
              background-color: $app-yellow-button !important;
              opacity: 0.8;
              transition-duration: 0.4s;
            }
          }
        }
      }
    }
  }
}

.reset_email_modal {
  width: 500px !important;
  min-height: 150px;
  max-height: 80%;
  margin: 0 16px;
  border-radius: 4px;

  .b__body {
    .w__modal-body {
      .a__reset-email-modal {
        padding: 0 40px;

        @include mq_down('sm') {
          padding: 0;
        }

        h1 {
          margin-bottom: 20px;
          text-align: center;

          @include mq_down('xs') {
            font-size: 24px;
          }
        }

        .b__field {
          .field-group {
            .ui.fluid.input.input-field {
              padding-bottom: 8px;
              margin: 0;

              input {
                height: 46px;
                background-color: $email-reset-input;
                border: none;

                &:active {
                  background-color: $app-white-button;
                  box-shadow: 0 0 0 3px $app-yellow-button;
                }

                &:focus {
                  background-color: $app-white-button;
                  box-shadow: 0 0 0 3px $app-yellow-button;
                }
              }
            }
          }
        }

        .w__actions {
          margin-top: 8px;

          .ui.fluid.positive.button {
            height: 40px;

            &:hover {
              background-color: $app-yellow-button !important;
              opacity: 0.8;
              transition-duration: 0.4s;
            }
          }
        }
      }
    }
  }
}
