.a__fishes {
  .app_titlebar {
    .ui.header {
      margin: 0;
      font-size: 26px;
      line-height: $app-line-height-main;
      color: $app-color-black;
    }

    @include mq_down('sm') {
      padding: 32px 0;
    }
  }

  .app_fish_search_form {
    @include mq_up() {
      margin: 0 0 24px 0;
    }
    padding: 0 16px;

    margin: 50px 0;

    @include mq_down('sm') {
      margin-top: 0;
    }
  }

  .sales_list.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    min-height: 290px;

    .fished_loading {
      min-height: 260px;
      margin-top: 25px;
      margin-bottom: 5%;
    }

    .hidden_loading_now {
      display: none;
    }

    .app_pagination {
      margin: 32px 0 12px;
    }

    .w__pagination {
      .b__jump-button {
        font-size: 14px;
        color: black;
        background: none;
        border: none;
        &:hover {
          color: #ffc107;
          cursor: pointer;
        }
        &:focus {
          outline: none;
        }
        &.disabled {
          color: black;
          cursor: not-allowed;
          opacity: 0.4;
        }
      }
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 24px 0;

      .gc__pagination {
        width: auto;
        padding: 0;
        margin: {
          right: 8px;
        }
      }
      .b__dropdown {
        min-width: 64px;
        padding: 7px;
        .dropdown.icon {
          padding: {
            right: 7px;
          }
          display: flex;
          align-items: center;
          height: 100%;
        }
        .visible.menu.transition {
          @include mq_down('md') {
            min-height: 222px;
          }
        }
      }
    }
  }

  &__tab-wrapper {
    display: flex;
    justify-content: center;
    padding: 0 16px;
    margin-bottom: 24px;

    .ui.button {
      width: 280px;
      height: 48px;
      background: #fff;

      &.active {
        color: #fff;
        background: #ffc107;
      }
    }

    .ui.buttons {
      flex-wrap: wrap;

      @include mq_down('md') {
        width: 100%;

        .ui.button {
          width: 100%;
        }
      }
    }
  }

  &__tradar__card-wrapper {
    width: 100%;
    max-width: 1020px;
    padding: 24px 0;
    margin-bottom: 36px;

    @media screen and (max-width: 1067px) {
      max-width: 700px;
    }

    @media screen and (max-width: 767px) {
      max-width: 564px;
    }

    @media screen and (max-width: 575px) {
      max-width: 100%;
    }

    .b__tradar-headar {
      padding: 0 16px;
      margin-bottom: 24px;

      .name {
        margin-bottom: 5px;
        font-size: 20px;
        color: #1e70bf;
      }

      .count {
        margin-right: 16px;
      }

      &__evalution {
        .eva-count {
          margin-left: 8px;
          font-size: 14px;
          color: #1e70bf;
        }
      }
    }

    .b__tradar-body {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;

      @media screen and (max-width: 575px) {
        justify-content: center;
      }
    }

    .b__tradar-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 160px;
      font-size: 16px;
      color: #202124;
    }

    .b__tradar-card {
      width: 100%;
      max-width: 188px;
      padding-bottom: 4px;
      margin: 8px;
      border: 1px solid #dadce0;
      border-radius: 2px;

      .b__tradar-card__main {
        position: relative;

        .addition {
          position: absolute;
          top: 0px;
          right: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px !important;
          height: 24px !important;
          font-size: 0.7rem;
          color: #fff;
          background: #59b9c6;
        }
      }

      &:hover {
        box-shadow: 1px 2px 7px 1px rgba(128, 128, 128, 0.3);
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 30%;
        margin: 8px 9px;
      }

      @media screen and (max-width: 575px) {
        max-width: 256px;
      }

      &__label {
        z-index: 2;
        height: 22px;
        text-align: center;

        span {
          z-index: 1;
          display: block;
          width: 80px;
          font-size: 10px;
          font-weight: 600;
          line-height: 22px;
        }

        .onsale {
          color: #fff;
          background: #3477cb;
        }

        .soldout {
          color: #fff;
          background: #ef6266;
        }

        .expired,
        .cancel {
          color: #fff;
          background: #aaa;
        }

        .failed {
          color: #fff;
          background: #ffc107;
        }
      }

      &__image {
        width: 100%;
        height: 124px;
        margin-bottom: 12px;

        .ui.image {
          width: 100%;
          height: 100%;
          max-height: 100%;
          max-height: 124px;
        }

        @media screen and (max-width: 1067px) {
          height: 82px;

          .ui.image {
            max-height: 82px;
          }
        }

        @media screen and (max-width: 767px) {
          height: 100%;
          max-height: 142px;

          .ui.image {
            max-height: 142px;
          }
        }

        @media screen and (max-width: 565px) {
          height: 100%;
          max-height: 211px;

          .ui.image {
            max-height: 211px;
          }
        }
      }

      &__detail {
        padding: 0 16px;
        margin: 8px 0;

        @media screen and (max-width: 1067px) {
          padding: 0 8px;
        }
      }

      &__name {
        overflow: hidden;
        font-size: 12px;
        color: #222;
        text-overflow: ellipsis;

        @media screen and (max-width: 1067px) {
          font-size: 14px;
        }

        @media screen and (max-width: 565px) {
          font-size: 12px;
        }
      }

      &__exhibition-at {
        display: flex;
        align-items: center;
        margin: 4px 0;

        font-size: 14px;
        color: #222;

        .ui.image {
          width: 12px;
          height: 12px;
          margin-top: 2px;
          margin-right: 4px;
        }

        @media screen and (max-width: 1067px) {
          font-size: 12px;

          .ui.image {
            width: 10px;
            height: 10px;
          }
        }

        @media screen and (max-width: 565px) {
          font-size: 14px;

          .ui.image {
            width: 12px;
            height: 12px;
          }
        }
      }

      &__money {
        display: flex;
        justify-content: space-between;
        margin: 4px 0;

        font-size: 1.6rem;
        color: #222;

        @media screen and (max-width: 1067px) {
          font-size: 16px;
          font-weight: 600;
        }

        @media screen and (max-width: 565px) {
          font-size: 1.4rem;
        }
      }

      &__fixed-price {
        @media screen and (max-width: 1067px) {
          font-size: 12px;
        }

        @media screen and (max-width: 565px) {
          font-size: 14px;
        }
      }
    }

    .b__tradar-footer {
      display: flex;
      justify-content: center;
      margin-top: 36px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 320px;
        height: 40px;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        background: #ffc107;
        border: none;
        border-radius: 5px;

        @media screen and (max-width: 564px) {
          width: 100%;
        }
      }
    }
  }

  // .gc__pagination {
  //   display: flex;
  //   justify-content: flex-end;
  //   &.disabled {
  //     cursor: not-allowed;
  //   }
  //   .rc-pagination-item {
  //     &:hover {
  //       border-color: #ffc107;
  //       a {
  //         color: #ffc107;
  //         text-decoration: none;
  //       }
  //     }

  //     &:focus {
  //       outline: none;
  //     }
  //   }

  //   .rc-pagination-item-active {
  //     background-color: #ffc107;
  //     border-color: #ffc107;
  //     &:hover {
  //       a {
  //         color: white;
  //       }
  //     }
  //   }

  //   .rc-pagination-prev,
  //   .rc-pagination-next {
  //     border: none;
  //     outline: none;
  //   }

  //   .rc-pagination-item-link {
  //     text-decoration: none;
  //   }
  //   .rc-pagination-jump-prev,
  //   .rc-pagination-jump-next {
  //     pointer-events: none;
  //   }

  //   .rc-pagination.disabled {
  //     pointer-events: none;
  //     .rc-pagination-item {
  //       opacity: 0.6;
  //     }
  //     .rc-pagination-disabled.rc-pagination-prev {
  //       opacity: 0.6;
  //     }
  //     .rc-pagination-prev,
  //     .rc-pagination-next {
  //       opacity: 0.6;
  //     }
  //   }
  // }
}
