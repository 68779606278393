.a__introduction_change_sales_account {
  display: flex;
  justify-content: center;
  .w__introduction_change_sales_account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 640px;
    margin-bottom: 20px;

    header {
      padding: 56px 0;
      font-size: 26px;
      font-weight: bold;
      line-height: 1.5em;

      text-align: center;
      span {
        display: none;
      }

      @include mq_down("sm") {
        padding: 32px 0;
        font-size: 22px;
        span {
          display: inline;
          line-height: 1.5;
        }
      }
    }
    .change_sale_account_example {
      margin-top: 36px;

      img {
        width: 100%;
        margin-bottom: 80px;
      }
    }

    @include mq_down("sm") {
      width: 100%;
      padding: 0 16px;
    }
  }
}
