.app_sell {
  .wrap {
    .bidder_user_message_card {
      .app_titlebar {
        padding: 56px 0 32px 0;
        @include mq_down("sm") {
          padding: 32px 0 32px 0;
        }
      }

      .register-process {
        display: flex;
        flex-direction: column;
        align-items: center;

        .process-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          @include mq_down("sm") {
            text-align: start;
          }
          .process-content {
            padding: 4px 0;
            line-height: $app-line-height-main;
          }

          .process-remarks {
            padding: 12px 0;
            font-size: 12px;
            font-weight: normal;
          }
          @include mq_down("sm") {
            min-height: 265px;
          }
        }
      }
      .selling-requirements {
        padding: 56px 0;
        @include mq_down() {
          padding: 32px 0 32px 0;
          text-align: start;
        }
      }
    }
  }
}
