.b__nav_bar {
  width: 100%;
  padding-top: #{$nav_bar_top}px;

  .app_profile_header {
    display: block;
    @include mq_down('sm') {
      display: none;
    }

    .new-line {
      display: inline;
    }

    .profile_reputation {
      margin: 0;

      a {
        display: inline-block;
        height: 17px;
        padding: 0;
        color: $hover-blue-sm;

        &:hover {
          text-decoration: underline;
          background-color: #ffffff;
          opacity: 0.7;

          @include mq_down() {
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }
  }

  .navOption {
    padding-left: #{$ui-header-left}px;
    margin-top: #{$nav_option_top}px;
    margin-bottom: #{$nav_option_bottom}px;
    font-size: 16px;
    font-weight: bold;
  }

  a {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 16px;

    span {
      width: 90%;
    }

    i.icon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 10%;
      height: 100%;
      padding-right: 18px;
      font-size: 9px;
    }
  }

  a {
    color: $app-color-black;

    &:hover {
      background: {
        color: rgba(#ffc107, 0.1);
        transition: 0.4s;
      }

      @include mq_down('sm') {
        background-color: #fafafa;
      }
    }

    &:visited {
      color: $app-color-black;
    }
  }

  a:nth-child(-n + 7) {
    border-bottom: none;
  }

  a.active {
    font-weight: bold;
    background-color: #f0f0f0;
  }
  .only_pc {
    @include mq_down('sm') {
      display: none;
    }
  }

  .ui.header {
    color: $app-color-black;

    @include mq_down('sm') {
      font-size: 16px;
    }
  }

  a:nth-child(10),
  a:nth-child(11),
  a:nth-child(12) {
    border-bottom: none;
  }

  .ui.user-header {
    padding: 0 0 8px 16px;
    line-height: $app-line-height-main;
  }

  .a__newfish-sidemenu {
    z-index: 1;
    box-sizing: border-box;
    display: block;
    width: 20rem;
    min-height: 640px;
    overflow-y: auto;
    background: #fff;
    border-right: 1px solid #eaecef;

    @media screen and (max-width: 767px) {
      width: 100%;
      min-height: initial;
      border-right: none;
      a {
        margin-right: 10px;
      }
      .accordion-content {
        width: 360px;
        padding: 20px;
        margin-left: 15px !important;
      }
    }

    &__accordion {
      .title {
        display: block;
        margin: 1.5rem 1rem;
        font-size: 700;
        cursor: pointer;

        &:before {
          padding-right: 8px;
          font-family: 'FontAwesome';
          content: '\f054';
        }
      }

      .hidden-checkbox {
        display: none;
      }

      .accordion-content {
        display: none;
        margin: 0 2.5rem;
        overflow: hidden;
        opacity: 0;

        .list {
          margin-bottom: 8px;
        }
      }

      .hidden-checkbox:checked + .title + .accordion-content {
        display: block;
        opacity: 1;
      }

      .hidden-checkbox:checked + .title:before {
        content: '\f078';
      }
    }
  }
}
