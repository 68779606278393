.a__fishes-company {
  &__main {
    width: 100%;
  }

  .app_titlebar {
    .ui.header {
      margin: 0;
      font-size: 26px;
      line-height: $app-line-height-main;
      color: $app-color-black;
    }

    @include mq_down('sm') {
      padding: 32px 0;
    }
  }
}

@import './content';
@import './misc';
