.app_signup_confirm.flex {
  @include grid-column-span(1, 16);
  @include grid-row-span(1, 16);

  padding: {
    bottom: 150px;
  }

  .code-resend {
    width: 100%;
    padding-top: 20px;

    a {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    &.app_disabled {
      a {
        opacity: 0.39;
      }
    }
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;

  .app_titlebar {
    width: 60%;
    height: auto;
    padding: 56px 0;
    margin: 0;
    background-color: white;
  }

  form.flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    background-color: white;
    padding: {
      top: 20px;
      bottom: 36px;
    }
    @include mq_down("sm") {
      padding: 0 16px;
    }
  }

  .amplify-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 170px;

    .ui.input.input-field {
      width: 320px;
    }

    .field-group {
      height: 50px;
      .ui.input {
        @include mq_down("sm") {
          width: 100%;
        }
      }
      @include mq_down("sm") {
        width: 100%;
      }
    }

    .ui.button.btn_submit {
      width: 320px;
      height: 46px;
      color: white;
      background-color: #ffc107;
      margin: {
        top: 20px;
        right: auto;
        bottom: 0;
        left: auto;
      }

      &:hover {
        background-color: lighten(#ffc107, 15%);

        @include mq_down("sm") {
          opacity: 1;
        }
      }
      @include mq_down("sm") {
        width: 100%;
      }
    }
    @include mq_down("sm") {
      width: 100%;
    }
  }

  .confirm_message {
    margin-bottom: 20px;
    font: {
      size: 15px;
    }
  }

  @media screen and (max-width: 767px) {
    /**
       * Under iPad
       */
    background-color: #fff;
    background-image: none !important;

    .wrapper {
      width: 320px;
    }

    .confirm_message {
      margin: {
        right: 30px;
        left: 30px;
      }
    }

    .app_titlebar {
      width: 100%;
      height: 82px;

      .ui.header {
        font-size: 16px;
      }
    }

    form.flex {
      width: 100%;

      .sns_buttons.flex {
        width: 256px;
      }
    }

    .liner.flex {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding-bottom: 24px;

      .div_liner.flex {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        max-width: 103px;
        stroke: black;
      }

      span {
        display: flex;
        align-items: center;
        width: 50px;
        height: 12px;
        padding: 0 7px;
        font-size: 12px;
      }
    }

    footer.flex {
      width: 320px;

      a {
        width: 256px;

        .ui.button.basic.btn_signup {
          width: 256px;
        }
      }
    }
  }
}
