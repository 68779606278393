.app_signout {
  width: 100%;
  padding: 0 16px;
  margin-bottom: 52px;
  text-align: center;
  background-color: white;

  .signout_button {
    width: 320px;
    height: 48px;
    line-height: $app-line-height-main;
    color: #fff !important;
    background-color: #ffc107 !important;

    @include hover_button_opacity();

    &:hover {
      @include mq_down("sm") {
        opacity: 1;
      }
    }
  }

  @include mq_down("sm") {
    .signout_button {
      width: 256px;
      margin: 7em 0;
    }
  }
}
