$background-color: #fff;
$submit-color: #fbbd08;

.app_logout {
  display: grid;
  grid-template-areas:
    ". l l ."
    ". r r .";
  grid-template-rows: 329px 110px;
  grid-row: 1 / span 16;
  grid-column: 1 / span 16;
  padding: 30px 0;
  background-color: $background-color;

  .logout_button {
    display: grid;
    grid-area: l;
    grid-template-rows: 140px 50px 140px;
    grid-template-columns: 1fr minmax(max-content, 320px) 1fr;
    background-color: white;

    .btn_submit {
      display: flex;
      grid-area: 2 / 2 / 3 / 3;
      align-items: center;
      justify-content: center;
      height: 50px;
      font-family: HiraKakuPro-W6;
      color: #fff !important;
      background-color: $submit-color !important;
      border-radius: 5px;
    }

    .redirect_top_mobile {
      display: none;
    }
  }

  .redirect_top_pc {
    display: grid;
    grid-area: r;
    grid-template-columns: 1fr 2fr 1fr;

    a {
      display: flex;
      grid-area: 1 / 2 / 2 / 3;
      align-items: center;
      justify-content: center;
      font-family: HiraKakuPro-W3;
      font-size: 16px;
      color: #000;
    }
  }
}

@media only screen and (max-width: 768px) {
  // iPad iPhone 8  iPhone 7 Plus iPhone 6 iPhone 5, iPhone SE
  .app_logout {
    grid-template-areas: "l l l l";
    grid-template-rows: 458px 0;
    background-color: white;

    .logout_button {
      grid-template-rows: 140px 50px 200px auto;
      grid-template-columns: 1fr minmax(max-content, 256px) 1fr;

      .redirect_top_mobile {
        display: block;
        grid-area: 4 / 2 / 5 / 3;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: HiraKakuPro-W3;
          font-size: 16px;
          color: #000;
        }
      }
    }

    .redirect_top_pc {
      display: none;
    }
  }
}
