.a__fee {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin: 72px 0;
}

.b__sagawa {
  font-size: 32px;
}

.a__fee-heading {
  margin: 0 0 32px 0;
  font-size: 26px;
}

.a__fee-table {
  background: #999999;
}

.a__fee-thead {
  color: #fff;
}

.a__fee-region {
  background: #f9f9f9;
}

.a__fee-address,
.a__fee-fee {
  background: #fff;
}

.a__fee-fee {
  font-weight: 900;
  text-align: right;
}
