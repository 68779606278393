.alertText {
  width: 45%;
  margin-bottom: 20px;
  padding: 8px;
  background-color: #fff4e6;
  border: 1px solid #f76707;
  font-size: 13px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }

  span {
    font-weight: 700;
    color: #d9480f;
  }
}
