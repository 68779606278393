.a__fish__additional {
  position: relative;
  left: 50%;
  width: 100vw;
  padding: 56px 16px;
  margin-bottom: 50px;
  background: #fafafa;
  transform: translateX(-50%);

  &__container {
    display: flex;
    justify-content: center;
  }

  &__inner {
    width: 760px;
    padding: 16px 24px;
  }

  &__add-btn {
    margin-bottom: 24px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 40px;
      font-weight: 600;
      color: #fff;
      background: #ffc107;
      border: 1px solid #dadce0;
      border-radius: 4px;
      outline: none;
    }
  }

  &__images {
    width: 100%;
  }

  &__images-list {
    display: flex;
    flex-wrap: wrap;

    .ui.card {
      width: 221px;
      margin: 8px !important;
    }
  }

  &__images-card {
    position: relative;

    .image {
      padding: 15px !important;
      background: #fff !important;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      margin: 15px;
      font-size: 18px;
      font-weight: 600;
      color: #fff !important;
      background: #ffc107;
      border-radius: 4px;
    }
  }
}
