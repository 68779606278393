.a__fish__additional__inner {
  .a__fish__additional__images {
    .a__fish__additional__images-list {
      text-align: center;
      .a__fish__additional__images-card {
        margin-right: auto !important;
        margin-left: auto !important;
        text-align: center !important;
        .extra {
          margin: 0px;
          margin-top: auto;
          .comment {
            height: 100px;
            margin: 10px;
            font-size: 16px;
            text-align: left;
            .comment_detail {
              color: black;
            }
          }
        }
      }
    }
  }
  .a__fish__additional__images-empty {
    text-align: center;
  }
  .a__fish__additional__add-btn {
    width: 300px;
    padding: 0px;
    margin: auto;
    margin-top: 30px;
  }
}

textarea {
  width: 100%;
  height: 80px;
  padding: 17px 8px;
  resize: none;
  border: solid 1px #cccccc;
  border-radius: 5px;
}

.a__bid_history {
  position: relative;
  left: 50%;
  width: 100vw;
  padding: 56px 16px;
  background-color: #fafafa;
  transform: translateX(-50%);

  .bid_history_header {
    .app_titlebar {
      padding: 0 0 24px 0;
    }
  }

  .no_history {
    color: $app-color-black;

    @include mq_up('lg') {
      font-size: 16px;
    }
  }
}
