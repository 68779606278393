.app_sale {
  display: flex;
  justify-content: center;
  background-color: #fff;

  .error-paragraph {
    margin-top: 16px;
    font-size: 14px;
    color: $dark-sky-blue;
    text-align: center;
    cursor: pointer;
  }

  .a__sns_icon_row {
    margin-top: #{$sale-view}px;
  }

  .w__report-balme {
    display: flex;
    justify-content: center;

    .sale-report {
      width: 128px;
      padding-top: 16px;
      color: $dark-sky-blue;
      text-align: center;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        opacity: 0.7;
      }
    }

    @include mq_down() {
      display: none;
    }
  }

  .go_links {
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: 100%;
      padding-bottom: 20px;
      text-align: center;
    }
  }

  .go_fishes:hover {
    text-decoration: underline;
    opacity: 0.7;

    @include mq_down('sm') {
      text-decoration: none;
      opacity: 1;
    }
  }

  @mixin fish_button_action {
    color: white;
    // hover, active, focusの背景色
    background-color: #f9bb03;
  }
  $background-color: #fff;

  .forceGray {
    color: gray !important;
  }

  .ui.button.sale_withdrawal {
    width: 100%;
    background-color: #ef6266 !important;

    @include hover_button_opacity();

    &:hover {
      @include mq_down('sm') {
        text-decoration: none;
        opacity: 1;
      }
    }

    &.button_gray {
      cursor: default;
      background-color: #ccc !important;

      &:hover {
        opacity: 1;
      }
    }
  }

  .suggestion-price {
    padding-top: 16px;
    font-size: 14px;

    @include mq_down() {
      display: inline-block;
      width: 90%;
      text-align: start;
    }

    @include mq_down('sm') {
      width: 100%;
    }
  }

  .ui.button.buy_it_now {
    margin-top: 8px;
    background-color: #555 !important;
  }

  .display_hidden {
    display: none !important;
  }

  .solid_border {
    border-top: 1px solid #ccc;
  }

  .app_mounting {
    visibility: hidden !important;
  }

  .error_message {
    display: none;
    margin-bottom: 15px;
    font-size: 12px;
    color: #d62d2d;

    &.display_error {
      display: block !important;
    }
  }

  .turn_landing.flex {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 56px 0 24px;
    margin: 8px 1px;

    a {
      line-height: $app-line-height-main;
      color: $app-color-black;

      &:visited {
        line-height: $app-line-height-main;
        color: $app-color-black;
      }
    }
  }

  .sale_main_description {
    width: 100%;
    height: auto;
    word-wrap: break-word;
    white-space: pre-wrap;

    @include mq_up('lg') {
      font-size: 16px;
    }
  }

  .selling_form {
    padding: 24px;
    margin-top: 32px;
    border: $app-gray-border solid 1px;
    border-radius: 5px;
  }

  .bidder_user_message {
    margin-top: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #d62d2d;
    text-align: center;
  }

  .delivery_address {
    padding: 0;
    list-style: none;

    @media screen and (max-width: 991px) {
      max-width: 320px;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
    }
  }

  .bidder_user_message_card {
    margin-top: 12px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: $app-line-height-main;

    a {
      &:hover {
        text-decoration: underline;
        opacity: 0.7;

        @include mq_down('sm') {
          text-decoration: none;
          opacity: 1;
        }
      }
    }

    @include mq_down('sm') {
      line-height: 1.4;
    }
  }

  .grid_sale {
    width: 1040px;

    @include mq_down() {
      padding-top: 0;
    }
  }

  .grid_sale_detail {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    margin-bottom: 24px;
    background: $background-color;
  }

  .grid_sale_view.eHjUkg {
    display: flex;
    width: 527px;
  }

  .w__sale_image {
    position: relative;
    justify-content: center;
    width: 387px;
    margin: 0 auto !important;

    .ui.image {
      width: 100%;
    }

    .preview {
      width: 100%;
      height: auto;
      margin: {
        top: 8px;
      }
    }
  }

  .rakusatu {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 60px;
    font-family: HiraKakuPro-W6;
    font-size: 24px;
    line-height: 60px;
    color: white;
    text-align: center;
    background-color: #ef6266;

    @include mq_down() {
      top: 0;
    }
  }

  .expired {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 60px;
    font-family: HiraKakuPro-W6;
    font-size: 24px;
    line-height: 60px;
    color: white;
    text-align: center;
    background-color: #aaa;

    @include mq_down() {
      top: 0;
    }
  }

  .bidding,
  .failed-chaging {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 60px;
    font-family: HiraKakuPro-W6;
    font-size: 24px;
    line-height: 60px;
    color: white;
    text-align: center;
    background-color: #3477cb;

    @include mq_down() {
      top: 0;
    }
  }

  .sale_image {
    width: 387px;
    height: 387px;
    text-align: center;

    @include mq_down('md') {
      padding-top: 0;
    }
  }

  .failed-chaging {
    background: #ffc107;
  }

  .sale_view_notice {
    margin-right: 15px;
  }

  .w__sale_view {
    display: flex;
    justify-content: center;
    margin-top: #{$sale-view}px;
    margin-bottom: #{$sale-view}px;
  }

  .sale_posted_at {
    font-size: 25px;
    font-weight: bold;
    line-height: $app-line-height-main;
    color: #ef6266;
    text-align: center;
  }

  .sale_time_attention {
    margin-top: 5px;
    font-size: 11px;
  }

  .grid_facebook_container {
    margin-right: 15px;
  }

  .grid_twitter_container {
    margin-right: 15px;
  }

  .grid_line_container {
    margin-right: 15px;
  }

  .grid_sale_main {
    width: 417px;
    padding: 0 0 32px;

    .w__sale-balme-container {
      display: none;

      @include mq_down() {
        display: flex;
      }
    }
  }

  .sale_main_title {
    max-width: 720px;
    margin: 0 auto !important;
    line-height: $app-line-height-main;
    color: $app-color-black;

    @include mq_down('md') {
      padding-top: 0;
    }
  }

  .sale_main_description {
    max-width: 720px;
    margin: 0 auto;
    line-height: $app-line-height-main;
    color: $app-color-black;
  }

  .flex {
    display: flex;
  }

  .sale_present_information {
    display: flex;
    margin-bottom: 15px;
  }

  .grid_sale_main_option {
    padding-top: 6px;

    span {
      padding: 2px 8px;
      margin-right: 15px;
      line-height: $app-line-height-main;
      color: $app-color-black;
      border: 1px solid #979797;
    }
  }

  .button {
    width: 100%;
    height: 48px;
    color: #fff !important;
    background-color: #ffc107 !important;
    border-radius: 5px;

    @include hover_button_opacity();

    &:hover {
      @include mq_down('sm') {
        opacity: 1;
      }
    }
  }

  .sale_price_message {
    padding-bottom: 24px;
    color: $app-color-black;

    .field-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: $app-line-height-main;

      .w__error {
        width: 100%;

        .redux_form {
          &.error {
            text-align: initial;
          }
        }
      }

      @include mq_down('md') {
        align-items: center;
        justify-content: center;
      }

      .field-input {
        width: 100%;
        margin-bottom: 6px;

        h2 {
          margin: 0;
          font-size: 26px;
          text-align: center;

          @include mq_down('sm') {
            font-size: 22px;
          }
        }

        .max-price {
          padding: 19px 0 8px 0;
          font-size: #{$feature-list-heading-size-sm}px;

          @include mq_down() {
            display: inline-block;
            width: 100%;
            font-size: 14px;
            text-align: start;
          }

          @include mq_down('sm') {
            width: 100%;
          }
        }
      }

      .ui.input {
        width: 100%;

        input {
          flex: 1 0 auto;
          width: 100%;
          padding: 15px 16px;
          font-size: 20px;
          font-weight: bold;
          text-align: right;
          background-color: $app-gray-input;
          border: none;
          border-radius: 4px;
          outline: 0;

          @include mq_down('sm') {
            width: 100%;
            font-size: 18px;
          }
        }

        i.yen.icon {
          font-size: 22px;
          color: $app-color-black;
          opacity: 1;
        }
      }
    }

    .input-field {
      padding: 0 10px 0 0 !important;
      margin: 0 !important;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    h2 {
      margin-bottom: 32px;
      text-align: center;
    }
  }

  .grid_bid_history {
    padding: 24px 0 #{$body-bottom}px;
    background-color: $background-color;

    @include mq_down() {
      padding: 44px 0 #{$body-bottom}px;
    }
  }

  .bid_history_header {
    text-align: center;

    .app_titlebar {
      h3 {
        font-size: 26px;
        color: $app-color-black;
      }
    }
  }

  .fiex_chat_header {
    text-align: center;

    .app_titlebar {
      h3 {
        font-size: 26px;
        color: $app-color-black;

        @include mq_down('sm') {
          font-size: 16px;
        }
      }
    }
  }

  .grid_bid_history_divliner {
    width: 720px;
    margin: 0 auto;

    svg {
      overflow: inherit;
    }
  }

  .no-bids {
    text-align: center;
  }

  .b__history_area {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chat_input_area {
    display: flex;
    justify-content: center;
  }

  .form_wrap {
    width: 540px;
  }

  form.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .form_item {
    width: 540px;
  }

  .text_item {
    width: 100%;
    height: 120px;
    padding: 8px;
    background-color: #fff;
    border: solid 1px #ccc;
    border-radius: 5px;
  }

  textarea {
    resize: none;
  }

  .form_item_checkbox {
    width: 540px;
    padding: 19px 0;
  }

  .message_submit {
    width: 540px;
    height: 48px;
    margin-bottom: 8px;
    background-color: #ffc107;
    border-radius: 5px;
  }

  .message_submit:hover {
    opacity: 0.8;
    transition-duration: 0.4s;

    @include mq_down('sm') {
      opacity: 1;
    }
  }

  .link_for_review {
    width: 100%;
    margin: 0 auto 24px;
    text-align: center;

    .link_button {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
        transition-duration: 0.4s;

        @include mq_down('sm') {
          opacity: 1;
        }
      }
    }

    @include mq_down() {
      width: 90%;
    }

    @include mq_down('sm') {
      width: 100%;
    }
  }

  .return-area {
    display: flex;
    justify-content: center;
    padding: 64px 0 32px;
  }

  .return_title {
    align-self: center;
    justify-self: center;
    font-size: 14px;

    a:hover {
      text-decoration: underline;
      opacity: 0.7;
    }
  }

  .userThumbnail.circle.grid {
    @include display-grid;
    @include grid-columns-16fr;
    @include grid-rows-16fr;
    width: 63px;
    height: 63px;

    overflow: hidden;
    background-color: #fff;
    border-radius: 50%;

    .ui.image {
      @include grid-column-span(1, 16);
      @include grid-row-span(1, 16);

      z-index: 1;
    }

    a {
      display: none;
    }

    a.edit {
      @include grid-column-span(1, 16);
      @include grid-row-span(12, 4);

      z-index: 2;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 9px;
      font-weight: 600;
      color: white;
      background-color: #888;
    }
  }

  .chat-list-area {
    width: 100%;
    padding: 40px 32px 32px 32px;
  }

  .chat-content-block.flex {
    display: flex;
    max-width: 540px;
    margin: 0 auto 27px;

    .chat-user {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: -12px;

      .user-name {
        width: 100px;
        margin-top: 8px;
        font-family: HiraKakuPro-W6;
        font-size: 14px;
        text-align: center;
        word-break: break-all;
      }
    }

    .triangle {
      position: relative;
      top: 5px;
      width: 0;
      height: 0;
      margin-left: 15px;
      border-color: transparent #fffbed transparent transparent;
      border-style: solid;
      border-width: 8px 15px 6px 0;
    }

    .chat-content {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 7px;
      white-space: pre-wrap;
      content: '\A';
      background-color: #fffbed;
      border-radius: 5px;

      span {
        margin: 0 16px 0 8px;
      }

      i {
        font-size: 18px;
      }

      .icon_container {
        position: absolute;
        right: -27px;
        bottom: 4px;
      }

      .date {
        margin: 0 14px;
        text-align: left;
      }

      .content {
        margin: 0 14px;
        font-family: HiraKakuPro-W3;
        text-align: left;
        word-break: break-all;

        @include mq_up('lg') {
          font-size: 16px;
        }
      }
    }
  }

  .grid_sale_view {
    margin-right: 32px;

    .sale_present_price-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }

    .sale_present_price {
      margin-right: 24px;
      font-size: 36px;
      line-height: $app-line-height-main;
      color: #ef6266;
    }

    .sale_shipping_cost {
      width: 220px;
      height: 40px;
    }

    @include mq_down() {
      margin: 0;
    }
  }

  .sale_prepayment {
    margin: 0 0 12px 0;
  }
}

@include mq_up('lg') {
  .app_sale {
    .grid_sale {
      .sale_main_like,
      .sale_main_notice,
      .main_icon_facebook,
      .main_icon_twitter,
      .main_icon_line {
        display: none;
      }
    }
  }
}

.w__sale-report-container {
  display: none;
}

@include mq_down() {
  .app_sale {
    background-color: #fff;

    .w__sale_view {
      margin-top: #{$sale-view}px;
      margin-bottom: #{$sale-view}px;
    }

    .grid_sale {
      width: 720px;
    }

    .bidder_user_message_card {
      display: flex;
      justify-content: center;
    }

    .rakusatu {
      width: 100%;
      height: 42px;
      padding-left: 0;
      font-size: 20px;
      line-height: 42px;
    }

    .expired {
      width: 100%;
      height: 42px;
      padding-left: 0;
      font-size: 20px;
      line-height: 42px;
    }

    .bidding {
      width: 100%;
      height: 42px;
      padding-left: 0;
      font-size: 20px;
      line-height: 42px;
    }

    .failed-chaging {
      width: 100%;
      height: 42px;
      padding-left: 0;
      font-size: 20px;
      line-height: 42px;
    }

    .breadcrumbs_list {
      display: none;
    }

    .grid_sale_detail {
      display: block;
      margin: 0;

      .grid_sale_view {
        display: inline;
        text-align: center;
      }

      .sale_view_notice,
      .view_icon_facebook,
      .view_icon_twitter,
      .view_icon_line {
        display: none;
      }
    }

    .grid_sale_main {
      display: inline;
      max-width: 50%;
      padding-right: 0;
      margin: 0 auto;
    }

    .sale_main_title {
      margin-bottom: 12px;
      font-size: 25px !important;
      text-align: center;
    }

    .button {
      display: inline;
      text-align: center;
    }

    .sale_present_information {
      justify-content: center;
      padding: 20px 10px 0;
    }

    .sale_present_price {
      text-align: center;
    }

    form {
      text-align: center;
    }

    .icon_container {
      margin-top: 10px;
      text-align: center;

      img {
        display: inline;
        padding: 12px;
      }
    }

    .w__sale-report-container {
      display: flex;
      justify-content: center;

      .sale-report-container {
        width: 128px;
        padding-top: 16px;
        color: $dark-sky-blue;
        text-align: center;
        cursor: pointer;
      }

      @include mq_up('lg') {
        display: none;
      }
    }

    .sale_main_like {
      padding: 20px 0 14px;
      text-align: center;
    }

    .sale_main_notice {
      padding-bottom: 14px;
      text-align: center;
    }

    .grid_bid_history {
      background-color: #fff;
    }

    .form_wrap {
      width: 100%;
    }

    form.flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .form_item {
      width: 100%;
      margin-bottom: 12px;
    }

    .text_item {
      width: 648px;
    }

    .ui.fitted.checkbox {
      min-height: 14px !important;
    }

    .form_item_checkbox {
      display: flex;
      justify-content: flex-start;
      width: 648px;
    }

    .message_submit {
      width: 90%;
    }

    .return-area {
      background-color: #fff;
    }

    .grid_bid_history_divliner {
      width: 540px;
    }

    .chat-content-block.flex {
      max-width: 720px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .app_sale {
    .grid_sale {
      width: 100%;
      padding: 0 16px;
    }

    .button {
      width: 100%;
    }

    .sale_main_title {
      font-size: 18px;
    }

    .grid_sale_main {
      max-width: 100%;
    }

    .sale_present_price {
      margin-bottom: 0;
      font-size: 26px;
    }

    .grid_sale_main_option {
      padding-top: 2px;
      margin-top: 0;
      font-size: 12px;
    }

    .form_wrap {
      width: 100%;
    }

    form.flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .text_item {
      width: 100%;
    }

    .form_item_checkbox {
      width: 90%;
      padding: 19px 0;
    }

    .message_submit {
      width: 100%;
      height: 48px;
      background-color: #ffc107;
      border-radius: 5px;
    }

    .bid_history_header {
      font-size: 18px;
    }

    .grid_bid_history_divliner {
      width: 415px;
    }

    .chat-list-area {
      padding: 40px 0 32px;
    }

    .sale_posted_at {
      font-size: 20px;
    }

    .preview {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 414px) {
  .app_sale {
    .grid_sale .w__sale_image {
      display: block;
      width: 98%;
      margin: 1% !important;
    }

    .grid_bid_history {
      .grid_bid_history_divliner {
        width: 280px;
      }
    }

    .grid_bid_history_action {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 448px) {
    .app_sale .b__history_area {
      width: 100%;
    }
  }
}

.sale_modal_container {
  @media screen and (min-width: 448px) {
    width: 410px !important;
  }

  .b__body {
    padding-bottom: 0 !important;
  }
}

#sale_bid_modal,
#sale_fix_modal,
#sale_close_modal,
#sale_buy_it_now_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a.bidder_name {
    &:hover {
      color: $hover-blue-sm;
      text-decoration: underline;
      opacity: 0.7;

      @include mq_down('sm') {
        text-decoration: none;
        opacity: 1;
      }
    }
  }

  .bid_message,
  .close_message {
    font-size: 18px;
    text-align: center;
  }

  .bid_message {
    white-space: nowrap;

    @include mq_down('sm') {
      white-space: normal;
    }
  }

  .w__fix_message {
    text-align: center;

    p {
      font-size: 18px;
      line-height: 1;
    }

    .caution_text {
      font-size: 14px;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    background: #fff;
    border-top: none;
  }

  .negative_button {
    width: 120px;
    height: 48px;
    margin-top: 32px;
    color: #ffc107;
    background-color: #fff;
    border: 2px solid #ffc107;

    &:hover {
      background-color: #f8f8f8 !important;
      transition-duration: 0.4s;
    }

    &:first-child {
      margin-right: 14px;
    }
  }

  .positive_button {
    @include hover_button_opacity;

    &:hover {
      @include mq_down('sm') {
        opacity: 1;
      }
    }

    width: 120px;
    height: 48px;
    margin-top: 32px;
    background-color: #ffc107 !important;
  }

  .compliance_wrap {
    margin-top: 24px;
  }

  .compliance_red_list_wrap {
    display: flex;
    margin-bottom: 10px;
  }

  .remote-island-list-check {
    display: flex;
    margin-top: 10px;
  }

  .compliance_rare_species_wrap {
    display: flex;
    margin-bottom: 10px;
  }

  .compliance-parasite-wrap {
    display: flex;
  }

  .compliance_checkbox {
    position: relative;
    top: 3px;
    height: 100%;
  }

  .compliance_label {
    display: inline-block;
    padding-left: 20px;
    font-size: 14px;
    cursor: pointer;

    a:hover {
      text-decoration: underline;
      opacity: 0.7;

      @include mq_down('sm') {
        text-decoration: none;
        opacity: 1;
      }
    }
  }

  .sale_bid_modal_button {
    margin-bottom: 33px;

    @include mq_down('xs') {
      display: flex;
      justify-content: space-between;
    }
  }

  .sale_prices {
    margin: 12px 0 0;
    text-align: right;
    border-collapse: collapse;

    thead tr {
      text-align: center;
    }

    th,
    td {
      padding: 6px 12px;
      border: 1px solid #000;

      &.emphasis {
        background-color: #fee8a3;
      }

      @media screen and (max-width: 456px) {
        min-width: 36px;
        padding: 2.5px;
        font-size: 14px;
      }
    }

    .sale_total_prices {
      font-size: large;
    }
  }
}

#sale_close_modal {
  text-align: center;

  h3 {
    margin-bottom: 0;
  }

  p {
    font-size: 12px;
  }
}

#report-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .report-modal-title {
    padding-bottom: 19px;
  }

  .w__report-modal-content {
    width: 100%;

    .reporter-name,
    .reporter-email,
    .report-content {
      padding-top: 16px;

      .app_form_label_icon.flex {
        margin-left: 0 !important;
      }

      .field-group {
        .ui.input {
          width: 100%;
        }
      }

      .reporter-name-label,
      .reporter-email-label {
        margin-right: 10px;
        font-size: 16px;
        font-weight: bold;
      }

      .w__report-item {
        display: flex;
        align-items: center;
        padding-bottom: 4px;

        .app_form_label_icon {
          margin-top: 2px;
        }
      }
    }

    .government {
      padding-top: 16px;

      .app_form_label_icon.flex {
        margin-left: 0 !important;
      }

      .field-group {
        .ui.dropdown {
          &:hover {
            border-color: $app-gray-border;
          }

          &:focus {
            border-color: $dropdown-focus;
          }
        }
      }

      .government-label {
        margin-right: 10px;
        font-size: 16px;
        font-weight: bold;
      }

      .w__report-item {
        display: flex;
        align-items: center;
        padding-bottom: 4px;

        .app_form_label_icon {
          margin-top: 2px;
        }
      }
    }

    .government_report_link {
      margin-top: 10px;
    }

    .report-content {
      .field-group {
        textarea {
          width: 100%;
          height: 118px;
          padding: 11px 16px;
          font-size: $app-font-size-main;
          color: $app-color-black;
          resize: none;
          border: 1px solid $app-gray-border;
          border-radius: 5px;
          outline: none;
        }
      }
      padding-bottom: 16px;

      .report-content-label {
        margin-right: 10px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .w__report-modal-content {
      padding-top: 16px;
    }

    .w__buttons {
      display: flex;
      justify-content: center;

      .cancel-button {
        width: 120px;
        height: 48px;
        margin-right: 10px;
        color: $app-yellow-button;
        background: $app-color-white;
        border: 2px solid $app-yellow-button;

        &:hover {
          opacity: 0.7;
          transition-duration: 0.4s;
        }
      }

      .report-button {
        width: 120px;
        height: 48px;
        color: $app-color-white;
        background: $app-yellow-button;

        &:hover {
          opacity: 0.7;
          transition-duration: 0.4s;
        }
      }
    }
  }
}

.w__fare-table {
  width: 770px !important;
}

.a_fare-table {
  position: relative;
  height: 368px;

  .table-wrapper {
    position: absolute;
    padding: 0 24px 0 0;
  }

  .table {
    border-collapse: collapse;
  }

  .header {
    color: #fff;
    background: #817e77;
  }

  .sub-header {
    background: #f2f1ed;
  }

  .th {
    font-weight: 400;
    white-space: nowrap;
    vertical-align: top;
  }

  .list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .th,
  .td {
    padding: 4px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #d3cec2;
  }

  .sub-header .th:first-child,
  .td:first-child {
    font-weight: 900;
    background: #fdedad;
  }
}
