.app_mypage {
  .b__main {
    .app_contact {
      .wrapper {
        width: 90%;

        .amplify-container {
          justify-content: left;
          width: 100%;
        }

        .report_container {
          margin-bottom: 0;
        }

        @include mq_down("sm") {
          width: 100%;

          .report_container {
            margin: 0 16px #{$contact-report-mypage}px;
          }
        }
      }
      footer {
        display: none;
      }
    }
  }
}
